<template>
  <fragment>
    <Base :style="[styles, baseBackground]">
    <div
      :ref="'cover'"
      v-vpshow
      class="cover"
      :class="[{ 'text-right': !textLeft }, overlap]"
      :data-effect="data.cover_v2.effect"
      :style="[height, coverBackground]"
    >
      <div
        class="cover-content-wrapper"
        :style="[textlayout, 'height:100%;']"
      >
        <div class="wrapper" :style="'height:100%;'">
          <div
            ref="text"
            class="cover-content"
            :style="'height:100%;'"
            :data-align="data.cover_v2.text_align"
          >
            <div v-vpshow class="text" :class="padding">
              <div class="content" v-html="data.cover_v2.text" />
            </div>
          </div>
        </div>
      </div>
      <div
        :ref="'cover-media'"
        class="cover-media"
        :style="[height, medialayout]"
      >
        <div :ref="'overlay'" class="cover-overlay" :style="height"></div>
        <div ref="media" class="media" :style="height">
          <template v-if="data.cover_v2.select_media === 'img'">
            <div
              v-if="data.cover_v2.select_media === 'img'"
              v-vpshow="{ animation: 'fadein' }"
              v-lazy:background-image="getCDN(backgroundImage)"
              :style="[height, {paddingBottom: aspectRatio * 100 + '%'}]"
              class="image"
            />
          </template>
          <div
            v-if="data.cover_v2.select_media === 'vid'"
            class="cover-video"
            :style="height"
          >
            <video
              :ref="'video'"
              autoplay
              muted
              loop
              playsinline
              :style="height"
            >
              <source
                :src="data.cover_v2.file.url"
                :type="data.cover_v2.file.mime_type"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import { TimelineLite, Linear } from 'gsap'
import Base from './Base'
import utils from '@/mixins/utils'
let ScrollMagic
if (typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic')
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap')
}
export default {
  name: 'Cover',

  components: {
    Base
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      baseBackground: {
        'background-color': this.$store.state.styles.backgroundColor
      }
    }
  },

  computed: {
    coverBackground: function() {
      return {
        'background-color': this.data.cover_v2.background_color
      }
    },
    textLeft() {
      if (this.data.cover_v2.text_layout && this.data.cover_v2.media_layout) {
        return (
          parseInt(this.data.cover_v2.text_layout.split(',')[0]) <
          parseInt(this.data.cover_v2.media_layout.split(',')[0])
        )
      } else {
        return true
      }
    },
    height() {
      if (this.data.cover_v2.height === 'auto') {
        return { minHeight: 'none' }
      } else if (this.data.cover_v2.height === 'browser') {
        const iOS =
          !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        if (iOS) {
          return { minHeight: '500px' }
        } else {
          return { height: '100vh' }
        }
      } else {
        return { height: this.data.cover_v2.custom }
      }
    },
    padding() {
      const classes = []
      if (this.data.cover_v2.text_padding && this.data.cover_v2.text_layout) {
        if (this.data.cover_v2.text_align === 'top') {
          classes.push('padding-top')
        }
        if (this.data.cover_v2.text_align === 'bottom') {
          classes.push('padding-bottom')
        }
        if (parseInt(this.data.cover_v2.text_layout.split(',')[0]) === 0) {
          classes.push('padding-left')
        }
        if (parseInt(this.data.cover_v2.text_layout.split(',')[1]) === 13) {
          classes.push('padding-right')
        }
        // if (!this.fullWidth && parseInt(this.data.cover_v2.text_layout.split(',')[0]) === 1) {
        //   classes.push('padding-left')
        // }
        // if (!this.fullWidth && parseInt(this.data.cover_v2.text_layout.split(',')[1]) === 12) {
        //   classes.push('padding-right')
        // }
      }
      return classes
    },
    medialayout() {
      if (this.data.cover_v2.custom_layout) {
        return this.grid(
          this.data.cover_v2.media_layout,
          this.data.cover_v2.media_layout,
          this.overlap && this.overlap === 'overlap text-left'
            ? '3,6'
            : this.overlap && this.overlap === 'overlap text-right'
              ? '1,4'
              : (parseInt(this.data.cover_v2.media_layout.split(',')[0]) === 0
                  ? 0
                  : 1) +
                ',' +
                (parseInt(this.data.cover_v2.media_layout.split(',')[1]) === 13
                  ? 7
                  : 6)
        )
      } else {
        return { gridColumn: 'full' }
      }
    },
    textlayout() {
      if (this.data.cover_v2.custom_layout) {
        return this.grid(
          this.data.cover_v2.text_layout,
          this.data.cover_v2.text_layout,
          this.overlap && this.overlap === 'overlap text-left'
            ? '1,4'
            : this.overlap && this.overlap === 'overlap text-right'
              ? '3,6'
              : '1,6'
        )
      } else {
        return { gridColumn: 'full' }
      }
    },
    headlineStyle() {
      return this.$store.getters.headlineStyle
    },
    coverHeight: function() {
      return this.$refs['cover-media'].clientHeight
    },
    backgroundImage: function() {
      if (this.data.cover_v2.image) {
        return this.data.cover_v2.image.sizes.fullwidth
      } else {
        return false
      }
    },
    aspectRatio: function() {
      if (this.data.cover_v2.image) {
        return (
          this.data.cover_v2.image.sizes['fullwidth-height'] /
          this.data.cover_v2.image.sizes['fullwidth-width']
        )
      } else {
        return false
      }
    },
    overlap() {
      if (this.data.cover_v2.custom_layout) {
        const text = this.data.cover_v2.text_layout.split(',')
        const media = this.data.cover_v2.media_layout.split(',')
        const textLeft = parseInt(text[0]) < parseInt(media[0])
        if (
          parseInt(text[0]) >= parseInt(media[0]) &&
          parseInt(text[1]) <= parseInt(media[1])
        ) {
          return 'overlap full'
        }
        if (textLeft) {
          if (parseInt(text[1] - 3) < parseInt(media[0])) {
            return null
          } else {
            return 'overlap text-left'
          }
        } else {
          if (parseInt(text[0]) < parseInt(media[1]) - 3) {
            return 'overlap text-right'
          } else {
            return null
          }
        }
      } else {
        return null
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      const controller = new ScrollMagic.Controller({
        globalSceneOptions: {
          triggerHook: 'onLeave'
        }
      })

      if (this.data.cover_v2.effect === 'slideover') {
        if (this.data.cover_v2.darken) {
          const timeline = new TimelineLite()
          timeline.to(this.$refs.overlay, 1, { opacity: 1 })
          new ScrollMagic.Scene({
            duration: '100%',
            triggerElement: this.$refs.cover
          })
            .setTween(timeline)
            .addTo(controller)
        }

        new ScrollMagic.Scene({
          triggerElement: this.$refs.cover
        })
          .setPin(this.$refs.cover, { pushFollowers: false })
          .addTo(controller)
      }

      if (this.data.cover_v2.effect === 'reveal') {
        this.$parent.$el.style.position = 'relative'
        this.$parent.$el.style.zIndex = '2'

        const timeline = new TimelineLite()
        timeline.fromTo(
          this.$refs['cover-media'],
          1,
          { y: '0%' },
          { y: '-100%', ease: Linear.easeNone }
        )
        new ScrollMagic.Scene({
          triggerElement: this.$refs.cover,
          duration: '100%',
          triggerHook: 'onLeave'
        })
          .setPin(this.$refs.cover)
          .setTween(timeline)
        // .addTo(controller)
      }
      if (this.$refs.video) {
        this.$refs.video.play()
      }
    })

    const self = this
    if (
      this.data.cover_v2.media_parallax_speed ||
      this.data.cover_v2.text_parallax_speed
    ) {
      const offset = 0
      const controller = new ScrollMagic.Controller()
      const timeline = new TimelineLite()
      timeline.fromTo(
        self.$refs.media,
        1,
        {
          y:
            offset > 0
              ? '0%'
              : self.data.cover_v2.media_parallax_speed / 2 + '%',
          ease: Linear.easeNone
        },
        {
          y:
            offset > 0
              ? -self.data.cover_v2.media_parallax_speed + '%'
              : -(self.data.cover_v2.media_parallax_speed / 2) + '%',
          ease: Linear.easeNone
        },
        0
      )
      timeline.fromTo(
        self.$refs.text,
        1,
        {
          y:
            offset > 0
              ? '0%'
              : self.data.cover_v2.text_parallax_speed / 2 + '%',
          ease: Linear.easeNone
        },
        {
          y:
            offset > 0
              ? -self.data.cover_v2.text_parallax_speed + '%'
              : -(self.data.cover_v2.text_parallax_speed / 2) + '%',
          ease: Linear.easeNone
        },
        0
      )
      new ScrollMagic.Scene({
        triggerElement: self.$el,
        triggerHook: 'onEnter',
        duration: '200%'
      })
        .setTween(timeline)
        .addTo(controller)
    }
  }
}
</script>

<style scoped lang="scss">
.cover {
  @include wrapper;
  @include grid-container-responsive();
  width: 100% !important;
  transform: translate3D(0, 0, 0) !important;
  > * {
    grid-row-start: 1;

    @include media($mobile) {
      grid-row-start: auto;
      &.cover-content-wrapper {
        margin-bottom: 15px;
      }
    }
  }
  &.text-right {
    @include media($mobile) {
      .cover-media {
        grid-row-start: 1;
      }
      .cover-content-wrapper {
        grid-row-start: 2;
        margin-bottom: 0;
        margin-top: 15px;
      }
    }
  }
  &.overlap {
    > * {
      grid-row-start: 1 !important;
    }
  }
}
.text {
  width: 100%;

  &.padding-top {
    @include fluid-prop(padding-top, 15px, 30px);
  }
  &.padding-bottom {
    @include fluid-prop(padding-bottom, 15px, 30px);
  }
  &.padding-left {
    @include fluid-prop(padding-left, 15px, 30px);
  }
  &.padding-right {
    @include fluid-prop(padding-right, 15px, 30px);
  }
  @include media($mobile) {
    padding: 0 !important;
    .overlap & {
      margin-top: 0 !important;
      padding: 15px !important;
    }
  }
}
.cover-media {
  overflow: hidden;
}
.image {
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 500ms;

  &[lazy='loaded'] {
    opacity: 1;
  }
}
.cover-video {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.cover-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: $black;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  height: 100%;
}
h2 {
  @include fontsize(100);
}
.label {
  @include font('label');
}
.content {
  width: 100%;
  ::v-deep {
    h1 {
      @include fluid-prop(margin-bottom, 48px, 60px);
    }
    h2 {
      @include fluid-prop(margin-top, 48px, 60px);
      @include fluid-prop(margin-bottom, 20px, 30px);
    }
    h3 {
      @include fluid-prop(margin-bottom, 48px, 60px);
    }

    h4 {
      @include fluid-prop(margin-bottom, 20px, 30px);
    }
    h5 {
      @include fluid-prop(margin-bottom, 20px, 30px);
    }
    h6 {
      @include fluid-prop(margin-bottom, 20px, 30px);
    }
  }
}
.cover-content {
  display: flex;
  z-index: 3;
  position: relative;
  align-items: flex-start;

  &[data-align='center'] {
    align-items: center;
  }
  &[data-align='bottom'] {
    align-items: flex-end;
  }
}
</style>
