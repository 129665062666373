<template>
  <div class="wrapper">
    <div class="stories-filter">
      <ul>
        <li @click="click('all')" :class="{active: !$route.query.category}">All {{ tagsType }}</li>
        <li v-for="(category, index) in filteredCategories" v-html="category.name" :key="index" @click="click(category.slug)" :class="{active: $route.query.category === category.slug}" />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => {
        return []
      }
    },
    tagsType: {
      type: String,
      default: 'cases'
    }
  },
  data() {
    return {
      selectedFilter: 0
    }
  },
  methods: {
    click(slug) {
      if (slug === 'all') {
        this.$router.push({
          path: this.$route.path,
          query: null
        })
      } else {
        this.$router.push({
          path: this.$route.path,
          query: { category: slug }
        })
      }
    }
  },
  computed: {
    filteredCategories() {
      if (this.categories) {
        return this.categories.filter(category => category.term_id !== 1176 && category.term_id !== 1857)
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @include grid-container-responsive;
}
.stories-filter {
  @include fluid-prop(margin-bottom, 20px, 50px);
  @include grid-item(12, 1);
  @include media($mobile) {
    @include grid-item(6, 1);
  }
  text-align: center;

  ul {
    display: inline-block;

    @include media($mobile) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 0.5rem;
    }



    li {
      display: inline-block;
      cursor: pointer;
      @include fluid-prop(margin-right, 20px, 30px);
      opacity: 0.5;

      &.active {
        opacity: 1;
      }

      &:last-child {
        margin-right: 0;
      }
      @include media($mobile) {
        margin-right: 0;
        display: block;
        text-align: center;
      }
    }
  }
}
</style>
