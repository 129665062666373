<template>
  <fragment>
    <Base :style="[styles]">
    <div class="wrapper">
      <div v-if="data.list.description" v-vpshow class="text" >
        <div
          class="description"
          @click="handleClicks($event, '.text .description')"
          v-html="data.list.description"
        />
      </div>
    </div>
    <div ref="listWrapper" class="list-wrapper" v-if="data.list.enable_filter && this.data.list.filter_by_type === 'product'">
      <Transition name="fade" mode="out-in" @before-leave="leave" @after-enter="enter">
        <div :key="currentTag ? currentTag.desc : 1" class="wrapper">
          <div
            v-if="data.list.enable_filter && currentTag"
            class="list-top"
          >
            <img v-if="currentTag.icon" class="category-icon" :src="currentTag.icon.sizes.fullwidth">
            <Headline
              v-if="data.list.enable_filter && currentTag.desc"
              :data="{acf_fc_layout: 'Headline', headline: {text: currentTag.desc, columns: '4,9', custom_layout: true}}"
            />
          </div>
          <div v-if="data.list.enable_filter" class="quick-menu">
            <ul>
              <li v-for="item in quickMenu" :key="item.slug">
                <a
                  @click="quickMenuClick(item.slug)"
                  v-html="item.name"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </Transition>
    </div>
    <ListFilterStories
      v-if="data.list.enable_filter && this.data.list.filter_by_type === 'story'" 
      class="wrapper"
      :categories="categories" 
      :tagsType="this.data.list.article_filter"
    />
    <ListFilter
      v-show="init"
      v-if="data.list.enable_filter && this.data.list.filter_by_type === 'product'"
      ref="filter"
      :filtered-tags="filteredTags"
      :tags="tags"
      :designers="filteredDesigners"
      :families="filteredFamilies"
      :materials="filteredMaterials"
      :collections="filteredCollections"
      :count="posts.length"
      @active="filterActive"
    />
    <template v-if="data.list.layout === 'carousel'">
      <Slideshow :full="true" :ref="'list'" class="teasers">
        <Teaser
          v-for="(post) in posts"
          :key="post.ID"
          :layout="'list'"
          :data="post"
          :fields="data.list.elements"
          :color="color"
          :clickable="true"
        />
      </Slideshow>
    </template>
    <template v-else>
      <div :class="data.list.layout === 'blocks' ? 'grid-wrapper' : null">
        <div
          :ref="'list'"
          class="teasers"
          :class="[{'filter-open': filterOpen}, {'wrapper': (data.list.layout !== 'chaos')}, {'block-grid': (data.list.layout === 'blocks')}, data.list.layout === 'blocks' ? data.list.blocks_variant : null, {'chaos-grid': (data.list.layout === 'chaos')}, {'list': (data.list.layout === 'list')},
                  data.list.layout === 'chaos'?data.list.chaos_style:'']"
        >
          <Teaser
            v-for="(post, index) in posts"
            :key="post.ID"
            :class="(data.list.layout === 'blocks' && (!data.list.blocks_variant || data.list.blocks_variant === 'auto'))? getSize(index, posts.length):''"
            :layout="data.list.layout"
            :data="post"
            :fields="data.list.elements"
            :color="color"
          />
        </div>
      </div>
    </template>
    </Base>
  </fragment>
</template>

<script>
import VueScrollTo from 'vue-scrollto'
import Base from './Base'
import ajax from '@/mixins/ajax'
import utils from '@/mixins/utils'
import ListFilter from '@/components/ListFilter'
import ListFilterStories from '@/components/ListFilterStories'
import Teaser from '@/components/Teaser'
import Headline from '@/components/modules/Headline'
import Slideshow from '@/components/Slideshow'

export default {
  name: 'List',

  components: {
    Base,
    ListFilter,
    ListFilterStories,
    Teaser,
    Headline,
    Slideshow
  },
  extends: Base,
  mixins: [ajax, utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      posts: {},
      tags: [],
      families: [],
      materials: [],
      designers: [],
      categories: [],
      collections: [],
      currentTag: null,
      filter: this.data.list.filter_tags,
      designer: null,
      color: this.$store.state.styles.textColor,
      filterOpen: false,
      init: false
    }
  },

  computed: {
    quickMenu() {
      if (this.currentTag) {
        return this.tags.filter(tag => tag.parent === this.currentTag.term_id)
      } else {
        return null
      }
    },
    filteredTags() {
      if (this.posts.length > 0 && this.tags) {
        return this.tags
          .filter(tag =>
            this.posts.some(
              post => post.tags && post.tags.includes(tag.term_id) === true
            )
          )
          .filter(tag => tag.landing !== true)
      } else {
        return []
      }
    },
    filteredFamilies() {
      if (this.posts.length > 0 && this.families) {
        return this.families.filter(tag =>
          this.posts.some(
            post => post.tags && post.tags.includes(tag.term_id) === true
          )
        )
      } else {
        return []
      }
    },
    filteredMaterials() {
      if (this.posts.length > 0 && this.materials) {
        return this.materials.filter(tag =>
          this.posts.some(
            post =>
              post.tags &&
              post.tags.includes(tag.term_id) === true &&
              tag.term_id !== 87
          )
        )
      } else {
        return []
      }
    },
    filteredCollections() {
      if (this.posts.length > 0 && this.collections) {
        return this.collections.filter(tag =>
          this.posts.some(
            post =>
              post.tags &&
              post.tags.includes(tag.term_id) === true &&
              tag.term_id !== 1447
          )
        )
      } else {
        return []
      }
    },
    filteredDesigners() {
      if (this.posts.length > 0 && this.designers) {
        return this.designers.filter(designer =>
          this.posts.some(
            post => post.person && post.person[0].ID === designer.ID
          )
        )
      } else {
        return []
      }
    }
  },

  watch: {
    $route(to, from) {
      this.checkQuery()
    }
  },

  created: async function() {
    if (
      this.data.list.enable_filter &&
      this.data.list.filter_by_type === 'product'
    ) {
      this.tags = await this.getTags()
      this.families = await this.getFamilies()
      this.designers = await this.getDesigners()
      this.materials = await this.getMaterials()
      this.collections = await this.getCollections()
    } else if (
      this.data.list.enable_filter &&
      this.data.list.filter_by_type === 'story'
    ) {
      this.categories = await this.getCategories()
    }
    this.checkQuery()
  },

  mounted() {
    if(this.$refs.list && this.$refs.list.style) {
      this.$refs.list.style.setProperty('--underline-color', this.styles.color)
    }
  },

  methods: {
    quickMenuClick(slug) {
      this.$refs.filter.currentFilters = []
      this.$router.push({
        path: this.$route.path,
        query: { type: slug }
      })
    },
    filterActive(isActive) {
      this.filterOpen = isActive
    },
    leave(el) {
      if (this.$refs.listWrapper) {
        this.$refs.listWrapper.style.minHeight =
          this.$refs.listWrapper.offsetHeight + 'px'
      }
    },
    enter(el) {
      if (!this.init) {
        this.init = true
        this.getPosts()
      }
    },
    checkQuery() {
      if (this.data.list.enable_filter) {
        this.filter = this.data.list.filter_tags
          ? [...this.data.list.filter_tags]
          : []
        if (this.$route.query.type) {
          const result = this.tags.find(
            tag => tag.slug === this.$route.query.type
          )
          if (result) {
            if (result.landing) {
              this.currentTag = result
            } else {
              if (!this.currentTag) {
                this.currentTag = this.tags.find(tag => tag.slug === 'products')
              }
              VueScrollTo.scrollTo(this.$refs.list, 1000, {
                offset: -45
              })
            }
            this.filter.push(result.term_id)
          } else {
            this.currentTag = this.tags.find(tag => tag.slug === 'products')
            this.filter.push(this.currentTag.term_id)
          }
        }
        if (this.$route.query.family) {
          const result = this.families.find(
            tag => tag.slug === this.$route.query.family
          )
          if (result) {
            this.filter.push(result.term_id)
            VueScrollTo.scrollTo(this.$refs.list, 1000, {
              offset: -45
            })
          } else {
            this.filter.push(this.currentTag.term_id)
          }
        }
        if (this.$route.query.material) {
          const result = this.materials.find(
            tag => tag.slug === this.$route.query.material
          )
          if (result) {
            this.filter.push(result.term_id)
            VueScrollTo.scrollTo(this.$refs.list, 1000, {
              offset: -45
            })
          } else {
            this.filter.push(this.currentTag.term_id)
          }
        }
        if (this.$route.query.category) {
          const result = this.categories.find(
            tag => tag.slug === this.$route.query.category
          )
          this.filter = this.data.list.filter_tags
            ? [...this.data.list.filter_tags]
            : []
          if (result) {
            this.filter.push(result.term_id)
          } else {
            this.filter.push(this.currentTag.term_id)
          }
        }
        if (this.$route.query.designer) {
          const result = this.designers.find(
            designer => designer.post_name === this.$route.query.designer
          )
          if (result) {
            this.designer = result
            VueScrollTo.scrollTo(this.$refs.list, 1000, {
              offset: -45
            })
          }
        } else {
          this.designer = null
        }
        if (
          this.data.list.filter_by_type === 'product' &&
          !this.$route.query.type
        ) {
          this.currentTag = this.tags.find(tag => tag.slug === 'products')
          this.filter.push(this.currentTag.term_id)
        }
        if (this.init || this.data.list.filter_by_type === 'story') {
          this.getPosts()
        }
      } else {
        this.getPosts()
      }
    },
    getTags: async function() {
      const response = await this.get('/trouble/taxonomy', {
        id: 29
      })
      return Object.values(response.data)
    },
    getMaterials: async function() {
      const response = await this.get('/trouble/taxonomy', {
        id: 87
      })
      return Object.values(response.data)
    },
    getCollections: async function() {
      const response = await this.get('/trouble/taxonomy', {
        id: 1447
      })
      return Object.values(response.data)
    },
    getFamilies: async function() {
      const response = await this.get('/trouble/taxonomy', {
        id: 44
      })
      return Object.values(response.data)
    },
    getCategories: async function() {
      const response = await this.get('/trouble/taxonomy', {
        id: this.data.list.article_filter ? (this.data.list.article_filter === 'articles' ? 1176 : 1857 ) : 1176
      })
      return Object.values(response.data)
    },
    getDesigners: async function() {
      const response = await this.get('/trouble/designers')
      return Object.values(response.data)
    },
    getPosts: async function() {
      // Close filter
      if (
        this.data.list.enable_filter &&
        this.data.list.filter_by_type === 'product'
      ) {
        this.$refs.filter.hide()
      }

      if (
        this.$nuxt &&
        this.data.list.enable_filter &&
        this.data.list.filter_by_type === 'product'
      ) {
        this.$nuxt.$loading.start()
      }

      if (this.data.list.list_type === 'auto') {
        const params = {}
        if (this.$parent.page.type === 'topic') {
          params.type = 'any'
        } else {
          params.type = this.data.list.filter_by_type
        }

        if (this.$parent.page.type === 'topic') {
          params.topic = this.$parent.topic
        } else if (this.data.list.filter_topic) {
          params.topic = this.data.list.filter_topic
        }

        params.id = this.$parent.id

        if (this.data) {
          if (this.data.list.amount) {
            params.amount = this.data.list.amount
          }

          if (this.filter) {
            params.tag = this.filter
          }

          if (this.data.list.filter_people) {
            params.people = this.data.list.filter_people
          }

          if (this.designer) {
            params.people = [this.designer.ID]
          }

          if (this.data.list.sort) {
            params.sort = this.data.list.sort
          }
          if (this.data.list.show_featured) {
            params.show_featured = 1
          }
          this.data.list.elements.forEach(element => {
            if (element.acf_fc_layout === 'image') {
              params.crop = element.image_crop
            }
          })
        }
        if (this.$nuxt && this.$nuxt.$loading) {
          await this.get('/trouble/list', params).then(response => {
            this.$nuxt.$loading.finish()
            this.posts = response.data
            // If list is realted products and manual related products is chosen
            if (
              this.data.list.description.includes('Related Products') &&
              this.$parent.page.post_type === 'product' &&
              this.$parent.page.acf.related_products &&
              this.$parent.page.acf.related_products.length > 0
            ) {
              this.posts = [
                ...this.$parent.page.acf.related_products,
                ...this.posts
              ]
              this.posts = [
                ...new Map(this.posts.map(item => [item.ID, item])).values()
              ].slice(0, 4)
            }
          })
        }
      } else {
        if (this.data.list.filter_by_type === 'person') {
          this.posts = this.data.list.data_foundation_person
        }
        if (this.data.list.filter_by_type === 'product') {
          this.posts = this.data.list.data_foundation_product
        }
        if (this.data.list.filter_by_type === 'story') {
          this.posts = this.data.list.data_foundation_story
        }
        if (this.data.list.filter_by_type === 'publication') {
          this.posts = this.data.list.data_foundation_publication
        }
        if (this.data.list.filter_by_type === 'page') {
          this.posts = this.data.list.data_foundation_page
        }
      }
      if (process.client) {
        window.scrollTo(window.scrollX, window.scrollY - 1)
        window.scrollTo(window.scrollX, window.scrollY + 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  @include grid-container-responsive;

  > * {
    @include grid-item(12, 1);
    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }
  @include fluid-prop(margin-bottom, 20px, 30px);
}
.list-top {
  text-align: center;

  ::v-deep {
    .module {
      padding-bottom: 0 !important;
    }
  }

  .category-icon {
    max-width: 200px;
  }
}
.list-wrapper {
  @include fluid-prop(margin-bottom, 20px, 50px);
}
.quick-menu {
  @include grid-container-responsive;
  @include fluid-prop(margin-top, 20px, 40px);

  ul {
    @include grid-item(12, 1);
    text-align: center;

    @include media($mobile) {
      @include grid-item(6, 1);
    }

    li {
      display: inline-block;
      @include fluid-prop(margin-right, 20px, 30px);

      &:last-child {
        margin-right: 0;
      }

      a {
        opacity: 0.5;
        transition: all 500ms;

        &:hover,
        &.nuxt-link-exact-active {
          opacity: 1;
        }
      }
    }
  }
}
.teasers {
  transition: opacity 200ms;

  &.filter-open {
    opacity: 0.1;
    pointer-events: none;
  }
}
.label {
  @include font('label');
}
.title {
  @include fontsize(50);
  @include grid-item(6, 1);
  margin-top: 20px;
}
.description {
  margin-top: 20px;
  --underline-color: $black;

  ::v-deep {
    a {
      @include underline-link(true);
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.filter-wrapper {
  display: inherit !important;
  transition: opacity 0.5s;
}

.filter-wrapper[style*='display: none;'] {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
</style>
