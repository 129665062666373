<template>
  <div class="slideshow">
    <no-ssr>
      <div class="slideshow-wrapper" v-if="data">
        <tiny-slider
          ref="gallery"
          :auto-height="false"
          :auto-width="full ? false : true"
          :mouse-drag="true"
          :loop="loop"
          :mode="full ? 'carousel' : data.effect"
          :nav="full ? false : (data.pagination === 'dots' || data.pagination === 'numbers')"
          :arrow-keys="!isMobile"
          :autoplay-button-output="false"
          :autoplay-button="false"
          :autoplay-hover-pause="true"
          :speed="data.effect === 'gallery' || full ? 600 : 400"
          :autoplay-timeout="data.duration * 1000"
          :autoplay="data.autoplay"
          :items="full ? 1 : 1"
          :controls="!isMobile"
          :lazyload="full"
          :preventScrollOnTouch="'auto'"
          :responsive="full ? {0:{fixedWidth:300, edgePadding: 30, gutter: 30},768:{fixedWidth:500, edgePadding: 50, gutter: 80},992:{fixedWidth:600, edgePadding: 50, gutter: 100},1200:{fixedWidth:700, edgePadding: 50, gutter: 120},2300:{fixedWidth:800, edgePadding: 50, gutter: 150}} : null"
          :nav-container="
            full ? false : (data.pagination === 'dots' || data.pagination === 'numbers' ? '.gallery-' + _uid + '-nav' : null)
          "
        >
        <template v-if="data.images">
          <Img
            v-for="(img, index) in data.images"
            :key="index"
            :data-index="index + 1"
            :show-caption="full && data.show_caption"
            :crop="data.image_crop"
            :caption-align="data.caption_align"
            :preview="false"
            :data="img"
          />
        </template>

        <template v-else>
          <slot></slot>
        </template>

        </tiny-slider>
        <div
          ref="arrow"
          :class="[hover ? 'active' : '', hoverSide]"
          class="nav-arrow"
        />
        <div
          v-if="data.pagination === 'numbers' && !full"
          class="nav-wrapper inside"
          :style="[{ textAlign: data.caption_align }, imageCaptionStyle]"
        >
          <div :class="'gallery-' + _uid + '-nav'">
            <span
              v-for="(img, index) in data.images"
              :key="'numbers-' + index"
              class="numbers"
            >{{ index + 1 }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="data && data.pagination === 'dots' && !full"
        class="nav-wrapper"
        :style="[
          { bottom: navPos + 'px' },
          { textAlign: data.show_caption ? data.caption_align : 'left' }
        ]"
      >
        <div :class="'gallery-' + _uid + '-nav'">
          <span
            v-for="(img, index) in data.images"
            :key="'dot-' + index"
            class="dot"
          >
            <span :style="iconStyle">
            </span></span>
        </div>
      </div>
      <div
        v-if="data && data.pagination === 'number'"
        class="nav-wrapper number"
        :style="[{ textAlign: data.caption_align }, imageCaptionStyle]"
      >
        <b>{{ current }}</b><span style="opacity:0.3;"> / {{ total }}</span>
      </div>
      <tiny-slider
        v-if="data && data.show_caption && !full"
        ref="captions"
        :auto-height="false"
        :fixed-width="false"
        :auto-width="true"
        :mouse-drag="false"
        :loop="false"
        :mode="'gallery'"
        :autoplay-button-output="false"
        :autoplay-button="false"
        :autoplay-hover-pause="true"
        :speed="300"
        :autoplay="false"
        :items="1"
        :controls="false"
        :gutter="0"
      >
        <template v-for="(img, index) in data.images">
          <figcaption v-if="data.show_caption" :key="index">
            <span :style="{ textAlign: data.caption_align }">
              <span
                :style="imageCaptionStyle"
                class="caption"
                v-text="img.caption"
              />
              <span
                :style="imageCreditsStyle"
                class="credits"
                v-text="img.credits"
              />
            </span>
          </figcaption>
        </template>
      </tiny-slider>
    </no-ssr>
  </div>
</template>

<script>
import { TweenMax } from 'gsap'
import Img from '@/components/Img'
export default {
  name: 'Slideshow',

  components: {
    Img
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    full: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      slider: '',
      current: 1,
      total: 1,
      currentZ: 1,
      navPos: 0,
      iconStyle: {
        backgroundColor: this.$store.state.styles.baseTextColor
      },
      triggered: false,
      hover: false,
      hoverSide: 'left'
    }
  },

  computed: {
    isMobile() {
      const MobileDetect = require('mobile-detect')
      const md = new MobileDetect(window.navigator.userAgent)

      if (md.mobile() || md.tablet()) {
        return true
      } else {
        return false
      }
    },
    imageCaptionStyle() {
      return this.$store.getters.imageCaptionStyle
    },
    imageCreditsStyle() {
      return this.$store.getters.imageCreditsStyle
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.gallery) {
        this.slider = this.$refs.gallery.slider
        const _this = this
        _this.total = _this.slider.getInfo().slideCount
        this.slider.events.on('indexChanged', e => {
          const currentSlide = _this.$refs.gallery.$el.getElementsByClassName(
            'tns-slide-active'
          )
          if (currentSlide) {
            _this.current = currentSlide[0].getAttribute('data-index')

            if (this.data.show_caption && !_this.full) {
              _this.$refs.captions.slider.goTo(_this.current - 1)
            }
          }
        })
        // setTimeout(() => {
        //   if (this.$refs.gallery.slider.getInfo().controlsContainer) {
        //     this.$refs.gallery.slider
        //       .getInfo()
        //       .controlsContainer.children[0].addEventListener(
        //         'mouseenter',
        //         this.mouseoverLeft
        //       )
        //     this.$refs.gallery.slider
        //       .getInfo()
        //       .controlsContainer.children[1].addEventListener(
        //         'mouseenter',
        //         this.mouseoverRight
        //       )
        //     this.$refs.gallery.slider
        //       .getInfo()
        //       .controlsContainer.addEventListener('mouseenter', this.mouseover)
        //     this.$refs.gallery.slider
        //       .getInfo()
        //       .controlsContainer.addEventListener('mouseout', this.mouseout)
        //   }
        // }, 500)
      }
    })
  },

  methods: {
    getImage(crop) {
      switch (crop) {
        case '1to1':
          return this.data.image_full_1_1[0]
        case '3to2':
          return this.data.image_full_3_2[0]
        case '4to3':
          return this.data.image_full_4_3[0]
        case '3to4':
          return this.data.image_full_3_4[0]
        case '16to9':
          return this.data.image_full_16_9[0]
        default:
          return this.data.image_fullwidth[0]
      }
    },
    mouseoverLeft(e) {
      this.$refs.gallery.slider
        .getInfo()
        .controlsContainer.children[1].removeEventListener(
          'mousemove',
          this.mousemove
        )
      this.$refs.gallery.slider
        .getInfo()
        .controlsContainer.children[0].addEventListener(
          'mousemove',
          this.mousemove
        )
      this.hover = true
      this.hoverSide = 'left'
    },
    mouseover(e) {
      const rect = e.target.parentElement.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      TweenMax.set(this.$refs.arrow, {
        css: {
          left: x - 30,
          top: y - 30
        }
      })
    },
    mouseoverRight(e) {
      this.$refs.gallery.slider
        .getInfo()
        .controlsContainer.children[0].removeEventListener(
          'mousemove',
          this.mousemove
        )
      this.$refs.gallery.slider
        .getInfo()
        .controlsContainer.children[1].addEventListener(
          'mousemove',
          this.mousemove
        )
      this.hover = true
      this.hoverSide = 'right'
    },
    mouseout(e) {
      this.$refs.gallery.slider
        .getInfo()
        .controlsContainer.children[0].removeEventListener(
          'mousemove',
          this.mousemove
        )
      this.$refs.gallery.slider
        .getInfo()
        .controlsContainer.children[1].removeEventListener(
          'mousemove',
          this.mousemove
        )
      this.hover = false
    },
    mousemove(e) {
      const rect = e.target.parentElement.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      TweenMax.set(this.$refs.arrow, {
        css: {
          left: x - 30,
          top: y - 30
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-wrapper,
figcaption .caption,
figcaption .credits {
  .gallery.full-left & {
    @include fluid-prop(padding-left, 20px, 50px);
  }
  .gallery.full-right & {
    @include fluid-prop(padding-right, 20px, 50px);
  }
}
.nav-wrapper.inside {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  z-index: 10;
}
.number {
  margin-top: 15px;
  margin-bottom: 5px;
  @include fontsize(15);
}
.slideshow {
  position: relative;

  &.teasers {
    ::v-deep {
      .tns-inner {
          // margin-right: 1000px;
          @include media($mobile) {
            margin-left: 20px !important;

            // .teaser {
            //   max-width: 300px;
            //   margin-right: 30px;
            // }
          }
      }
      .tns-ovh {
        z-index: 2;
        position: relative; 
        pointer-events: none;

        * {
          pointer-events: none;

          @include media($mobile) {
            pointer-events: initial;
          }
        }
        a {
          pointer-events: all;
        }

      }
      .tns-controls {
        z-index: 1;
      }
    }
  }
  ::v-deep {
    img {
      @include fluid-prop(height, 300px, 740px);

      &.cropped {
        height: auto !important;
        img {
          height: auto !important;
          width: 100% !important;
        }
      }
    }
    figcaption {
      .caption {
        display: block;
      }
      .credits {
        opacity: 0.5;
        display: block;
      }
    }

    .tns-outer {
      position: relative;
      @include grid-item(12, 1);
    }
    .tns-ovh {
      &:active,
      &:focus {
        outline: none;
      }
    }

    // .tns-inner {
    //   margin: 0 !important;
    // }

    .tns-slider {
      transition-timing-function: $easeInOutQuart;
      [data-layout="full"] & {
        display: flex;
        align-items: center;
        padding-right: 20vw;
      }
    }

    .tns-controls {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &:active,
      &:focus {
        outline: none;
      }

      button {
        position: absolute;
        top: 0;
        height: 100%;
        width: 50%;
        opacity: 0;
      }

      button[data-controls='prev'] {
        left: 0;
        // cursor: none;
        // cursor: w-resize;
        // cursor: w-resize;
        cursor: url("~/assets/img/arrow-left.png") 10 15, auto;
      }
      button[data-controls='next'] {
        right: 0;
        //cursor: e-resize;
        // cursor: none;
        // cursor: e-resize;
        cursor: url("~/assets/img/arrow-right.png") 25 15, auto;
      }
    }

    .tns-nav {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 100;
    }
  }
}
.nav-arrow {
  width: 44px;
  height: 44px;
  background-color: $white;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  left: 50px;
  opacity: 0;
  z-index: 100;
  transition: opacity 100ms;
  pointer-events: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);

  &:after {
    content: '';
    display: block;
    background: url(#{$icons-path}arrow.svg) 0 0 no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.left {
    &:after {
      transform-origin: 0 0;
      transform: rotate(180deg) translate(-50%, -50%);
    }
  }

  &.active {
    opacity: 1;
  }
}
.dot {
  display: inline-block;
  padding: 5px 6px 5px 0;

  &:last-child {
    padding-right: 0;
  }
  cursor: pointer;
  span {
    width: 20px;
    height: 2px;
    background-color: black;
    display: block;
    opacity: 0.3;
    transition: background-color 150ms;
  }

  &:hover,
  &.tns-nav-active {
    span {
      opacity: 1;
    }
  }
}
.numbers {
  display: inline-block;
  padding: 5px 12px 20px 0;
  opacity: 0.2;
  cursor: pointer;

  &:hover,
  &.tns-nav-active {
    opacity: 1;
  }
  @include media($mobile) {
    padding: 5px 12px 5px 0;
  }
}
.slideshow-wrapper {
  position: relative;
}
::v-deep .teaser a {
  max-width: 65%;

  @include media($mobile) {
    max-width: none;
  }
}
.image.tns-item {
  [data-layout]:not([data-layout="full"]) & {
    padding-right: 0 !important;
  }
  vertical-align: bottom;

  [data-layout="full"] & {
    transform: scale(0.8);
    transition: transform 0.5s ease-out;
  }

  &.tns-fadeOut {
    transition: initial;
    z-index: 2;
  }

  &.tns-fadeIn {
    z-index: 3;
  }

  ::v-deep {
    figure {
      display: flex;
      justify-content: center; /* align horizontal */
      flex-direction: column;
      img {
        object-fit: contain;
        //height: 100%;
        width: auto;

        &.cropped {
          object-fit: initial;
          height: auto;
          width: 100%;
        }
      }
    }

    figcaption {
      opacity: 0;
      transition: opacity 0;

      .gallery.full & {
        margin-left: 50px;
        margin-right: 50px;
      }
    }
  }
  &.tns-slide-active {
    &:first-of-type {
      [data-layout="full"] & {
        transform: none;
      }
    }
    ::v-deep {
      figcaption {
        opacity: 1;
        transition: opacity 150ms;
        transition-delay: 400ms;
      }
    }
  }
}

* {
  &:focus {
    outline: none;
  }
}
</style>