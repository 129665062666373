<template>
  <fragment>
    <Base v-if="!data.gallery_v2.layout_clone.wrap" :style="[styles]">
    <div
      :class="[
        data.gallery_v2.layout_clone.custom_layout &&
          data.gallery_v2.layout_clone.columns.split(',')[1] === '13'
          ? 'full-right'
          : null,
        data.gallery_v2.layout_clone.custom_layout &&
          data.gallery_v2.layout_clone.columns.split(',')[0] === '0'
          ? 'full-left'
          : null
      ]"
      class="gallery"
      :data-layout="data.gallery_v2.layout"
      :data-hide-captions="!data.gallery_v2.show_caption"
    >
      <div v-if="data.gallery_v2.layout === 'slideshow'" class="wrapper">
        <div :style="[layout, { position: 'relative' }]">
          <Slideshow :data="data.gallery_v2" />
        </div>
      </div>
      <div v-if="data.gallery_v2.layout === 'full'">
          <Slideshow :data="data.gallery_v2" :full="true" :loop="true" />
      </div>
      <div v-if="data.gallery_v2.layout === 'blocks'" class="grid-wrapper">
        <div :class="data.gallery_v2.blocks_variant" class="block-grid">
          <Img
            v-for="(img, index) in data.gallery_v2.images"
            :key="index"
            @click.native="onClick($event, index)"
            v-vpshow
            :enable="data.gallery_v2.zoom"
            :data="img"
            :crop="data.gallery_v2.image_crop"
            :caption-align="data.gallery_v2.caption_align"
            :class="[
              data.gallery_v2.layout === 'blocks' &&
                (!data.gallery_v2.blocks_variant ||
                data.gallery_v2.blocks_variant === 'auto')
                ? getSize(index, data.gallery_v2.images.length)
                : '',
                {'use-as-index': data.gallery_v2.use_as_index}
            ]"
          />
        </div>
      </div>

      <div
        v-if="data.gallery_v2.layout === 'chaos'"
        :data-count="data.gallery_v2.images.length"
        :class="data.gallery_v2.chaos_style"
        class="chaos-grid"
      >
        <Img
          v-for="(img, index) in data.gallery_v2.images"
          :key="index"
          v-vpshow
          :enable="data.gallery_v2.zoom"
          :crop="data.gallery_v2.image_crop"
          :caption-align="data.gallery_v2.caption_align"
          :data="img"
        />
      </div>

      <div
        v-if="data.gallery_v2.layout === 'moodboard'"
        :id="'moodboard_' + _uid"
        v-waypoint="{ active: true, callback: onWaypoint }"
        class="moodboard"
      >
        <Img
          v-for="(img, index) in data.gallery_v2.images"
          :ref="'moodboardImage'"
          :key="index"
          :enable="false"
          :style="moodboardStyle(img, index)"
          :crop="data.gallery_v2.image_crop"
          :caption-align="data.gallery_v2.caption_align"
          :data="img"
        />
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import { TweenMax, Expo } from 'gsap'
import Img from '@/components/Img'
import Base from '@/components/modules/Base'
import Slideshow from '@/components/Slideshow'
import utils from '@/mixins/utils'

export default {
  name: 'Gallery',
  components: {
    Img,
    Base,
    Slideshow
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      slider: '',
      current: 1,
      total: 1,
      currentZ: 1,
      navPos: 0,
      iconStyle: {
        backgroundColor: this.$store.state.styles.baseTextColor
      },
      triggered: false
    }
  },

  computed: {
    layout() {
      if (this.data.gallery_v2.layout_clone.custom_layout) {
        return this.grid(this.data.gallery_v2.layout_clone.columns)
      } else if (
        this.$store.state.moduleOptions.layout_gallery &&
        this.$store.state.moduleOptions.layout_gallery.custom_layout
      ) {
        return this.grid(this.$store.state.moduleOptions.layout_gallery.columns)
      } else {
        return this.grid('3,10')
      }
    },
  },
  mounted() {
    if (this.data.gallery_v2.layout === 'moodboard') {
      const Draggable = require('gsap/Draggable').Draggable
      /* eslint-disable-next-line no-unused-vars */

      Draggable.create('#moodboard_' + this._uid + ' .image', {
        type: 'x,y',
        bounds: '#moodboard_' + this._uid,
        throwProps: true,
        edgeResistance: 0.4
      })
    }
  },

  methods: {
    getImage(crop) {
      switch (crop) {
        case '1to1':
          return this.data.image_full_1_1[0]
        case '3to2':
          return this.data.image_full_3_2[0]
        case '4to3':
          return this.data.image_full_4_3[0]
        case '3to4':
          return this.data.image_full_3_4[0]
        case '16to9':
          return this.data.image_full_16_9[0]
        default:
          return this.data.image_fullwidth[0]
      }
    },
    onClick(e, index) {
      if(this.data.gallery_v2.use_as_index) {
        e.stopPropagation()
        this.$parent.setActiveArticle(index + 1)
        return false
      }
    },
    randomNumber: function(min, max) {
      return Math.random() * (max - min) + min
    },
    moodboardStyle(img, index) {
      return {
        width: this.randomNumber(20, 35) + '%',
        top: this.randomNumber(0, 40) + '%',
        left: this.randomNumber(5, 60) + '%',
        'z-index': index + 1
      }
    },
    onWaypoint({ going, direction }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (going === this.$waypointMap.GOING_IN && !this.triggered) {
        this.triggered = true
        TweenMax.staggerFrom(
          '#moodboard_' + this._uid + ' .image',
          Math.random() + 0.5,
          {
            autoAlpha: 0,
            scale: 1.1,
            ease: Expo.easeOut
          },
          (Math.random() + 0.5) / 5
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">
.use-as-index {
  cursor: pointer;
}
.controls-wrapper {
  position: relative;
  @include grid-container-responsive();
}
.wrapper {
  @include grid-container-responsive();
}
.moodboard {
  position: relative;
  min-height: 80vw;

  @include media($mobile) {
    min-height: 70vh;
  }
  .image {
    position: absolute;
    //box-shadow: 0px 0px 25px rgba(0,0,0,0.10);

    @include media($mobile) {
      width: 40% !important;
      //box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
    }

    &:first-child {
      position: relative;
    }
  }
}
</style>
