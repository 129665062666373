<template>
  <fragment>
    <Base :style="[styles]">
    <div class="linklist grid-wrapper">
      <div class="block-grid" :class="data.linklist.blocks_variant || 'four_col'"> 
        <div
          v-for="(link, index) in data.linklist.links"
          :key="index"
          :class="{'no-link': link.link.length < 2}"
        >
          <router-link
            :is="noLink(link.link.url, 'router-link')"
            v-if="link.link.target !== '_blank'"
            :to="{ path: getUrl(link.link.url), params: {} }"
          >
            <div class="image-container" :style="{paddingBottom: getAspectRatio(data.linklist.image_crop) * 100 + '%'}">
              <img
                alt="Image"
                v-lazy="getImage(getCDN(link.icon.sizes), data.linklist.image_crop || '1to1')"
              />
            </div>

            <div class="font-5dcbc4b84a205">{{ link.title }}</div>
            <div class="font-5d6e50b4bca21">{{ link.description }}</div>
          </router-link>
          <a
            :is="noLink(link.link.url, 'a')"
            v-else
            :href="link.link.url"
            :target="link.link.target"
          >
            <div class="image-container" :class="{'no-margin': data.linklist.title_on_image}" :style="{paddingBottom: getAspectRatio(data.linklist.image_crop) * 100 + '%'}">
              <img
                alt="Image"
                v-lazy="getImage(getCDN(link.icon.sizes), data.linklist.image_crop || '1to1')"
              />
            </div>
            <div class="text" :class="{'on-top': data.linklist.title_on_image}">
              <div>
                <div :class="data.linklist.title_on_image ? 'font-5d6e50952190c' : 'font-5dcbc4b84a205'">{{ link.title }}</div>
                <div class="font-5d6e50b4bca21">{{ link.description }}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from '@/components/modules/Base'
import utils from '@/mixins/utils'

export default {
  name: 'Linklist',
  components: {
    Base
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      fields: [
        {
          acf_fc_layout: 'image',
          image_crop: '1to1',
          show_element: true
        },
        {
          acf_fc_layout: 'title',
          color: '',
          font_style: '5dcbc4b84a205',
          show_element: true,
          text_align: 'left'
        }
      ]
    }
  },

  methods: {
    noLink(url, component) {
      if (!url) {
        return 'div'
      } else {
        return component
      }
    },
    getImage(image, crop) {
      switch (crop) {
        case '1to1':
          return image['teaser-1-1']
        case '3to2':
          return image['teaser-3-2']
        case '4to3':
          return image['teaser-4-3']
        case '3to4':
          return image['teaser-3-4']
        case '16to9':
          return image['teaser-16-9']
        default:
          return image['teaser']
      }
    },
    getAspectRatio(crop) {
      switch (crop) {
        case '1to1':
          return 1
        case '3to2':
          return 0.666
        case '4to3':
          return 0.75
        case '3to4':
          return 1.333
        case '16to9':
          return 0.5625
        default:
          return 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-container {
  position: relative;
  @include fluid-prop(margin-bottom, 10px, 20px);
  overflow: hidden;

  &.no-margin {
    @include fluid-prop(margin-bottom, 0px, 0px);
  }
}
img {
  position: absolute;
  width: 100%;
  height: auto;
  padding-bottom: 100%;
  opacity: 0;
  transition: opacity 500ms;

  &[lazy='loaded'] {
    opacity: 1;
  }
}
.no-link a {
  cursor: default;
}
.text.on-top {
  padding: 10%;
  text-align: center;
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
