<template>
  <div class="wrapper filter-wrapper">
    <div
      :class="open ? 'active' : ''"
      class="filter"
    >
      <div v-click-outside="hide" class="filter-bar">
        <div class="filter-nav" @click="toggle">
          <template v-if="currentFilters.length > 0">
            <div>
              <span class="label">Filters Selected: </span>
              <template v-for="filter in currentFilters">
                <span :key="filter.name ? filter.name : filter.post_title" class="filter-item"><span v-html="filter.name ? filter.name : filter.post_title"></span><a class="clear" @click="removeQuery(filter.type, filter.slug ? filter.slug : filter.post_name)"></a></span>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="expand-button">
              <span class="label">Apply Filter</span>
              <span class="arrow-down"></span>
            </div>
          </template>
          <div class="count">{{ count }} {{ count === 1 ? 'Product' : 'Products' }}</div>
        </div>
        <div class="expanded">
          <div ref="expanded" class="expanded-inner desktop">
            <ul class="filter-selector">
              <li
                :class="{
                  active: currentSelection === 'designers'
                }"
                @click="selectFilter('designers')"
              >
                Designers
              </li>
              <li
                :class="{
                  active: currentSelection === 'filteredTags'
                }"
                @click="selectFilter('filteredTags')"
              >
                Product Type
              </li>
              <li
                :class="{
                  active: currentSelection === 'families'
                }"
                @click="selectFilter('families')"
              >
                Product Family
              </li>
              <li
                :class="{
                  active: currentSelection === 'materials'
                }"
                @click="selectFilter('materials')"
              >
                Materials
              </li>
              <!-- <li
                :class="{
                  active: currentSelection === 'collections'
                }"
                @click="selectFilter('collections')"
              >
                Collections
              </li> -->
            </ul>
            <transition name="fade" mode="out-in">
              <ul :key="currentSelection" class="filters">
                <li v-for="(item, index) in designers" v-show="currentSelection === 'designers'" :key="'designers' + index">
                  <a
                    @click="addQuery('designer', item.post_name)"
                    v-html="item.post_title"
                  ></a>
                </li>
                <li v-for="(item, index) in filteredTags" v-show="currentSelection === 'filteredTags'" :key="'tags' + index">
                  <a
                    @click="addQuery('type', item.slug)"
                    v-html="item.name"
                  ></a>
                </li>
                <li v-for="(item, index) in families" v-show="currentSelection === 'families'" :key="'families' + index">
                  <a
                    @click="addQuery('family', item.slug)"
                    v-html="item.name"
                  ></a>
                </li>
                <li v-for="(item, index) in materials" v-show="currentSelection === 'materials'" :key="'materials' + index">
                  <a
                    @click="addQuery('material', item.slug)"
                    v-html="item.name"
                  ></a>
                </li>
                <!-- <li v-for="(item, index) in collections" v-show="currentSelection === 'collections'" :key="'collections' + index">
                  <a
                    @click="addQuery('collection', item.slug)"
                    v-html="item.name"
                  ></a>
                </li> -->
              </ul>
            </transition>
          </div>
          <div class="expanded-inner mobile">
            <ul>
              <li @click="expand">
                <a>Designer</a>
                <ul class="submenu">
                  <li v-for="(item, index) in designers" :key="index">
                    <a
                      @click="addQuery('designer', item.post_name)"
                    >
                      {{ item.post_title }}</a>
                  </li>
                </ul>
              </li>
              <li @click="expand">
                <a>Type</a>
                <ul class="submenu">
                  <li v-for="(item, index) in filteredTags" :key="index">
                    <a
                      @click="addQuery('type', item.slug)"
                    >
                      {{ item.name }}</a>
                  </li>
                </ul>
              </li>
              <li @click="expand">
                <a>Family</a>
                <ul class="submenu">
                  <li v-for="(item, index) in families" :key="index">
                    <a
                      @click="addQuery('family', item.slug)"
                    >
                      {{ item.name }}</a>
                  </li>
                </ul>
              </li>
              <li @click="expand">
                <a>Material</a>
                <ul class="submenu">
                  <li v-for="(item, index) in materials" :key="index">
                    <a
                      @click="addQuery('material', item.slug)"
                    >
                      {{ item.name }}</a>
                  </li>
                </ul>
              </li>
              <!-- <li @click="expand">
                <a>Collection</a>
                <ul class="submenu">
                  <li v-for="(item, index) in collections" :key="index">
                    <a
                      @click="addQuery('collection', item.slug)"
                    >
                      {{ item.name }}</a>
                  </li>
                </ul>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import smoothReflow from 'vue-smooth-reflow'
export default {
  name: 'ListFilter',

  directives: {
    ClickOutside
  },

  mixins: [smoothReflow],

  props: {
    count: {
      type: Number,
      default: 0
    },
    tags: {
      type: Array,
      default: () => {
        return []
      }
    },
    filteredTags: {
      type: Array,
      default: () => {
        return []
      }
    },
    families: {
      type: Array,
      default: () => {
        return []
      }
    },
    designers: {
      type: Array,
      default: () => {
        return []
      }
    },
    materials: {
      type: Array,
      default: () => {
        return []
      }
    },
    collections: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      open: false,
      currentSelection: null,
      currentFilters: []
    }
  },

  watch: {
    families(newVal, oldVal) {
      this.checkQuery()
    },
    filteredTags(newVal, oldVal) {
      this.checkQuery()
    },
    designers(newVal, oldVal) {
      this.checkQuery()
    },
    materials(newVal, oldVal) {
      this.checkQuery()
    },
    collections(newVal, oldVal) {
      this.checkQuery()
    },
    $route(to, from) {
      this.checkQuery()
    }
  },

  mounted() {
    if (this.$refs.expanded) {
      this.$smoothReflow({
        el: this.$refs.expanded,
        transition: 'height .2s ease-in-out',
        transitionEvent: {
          selector: '.filters',
          propertyName: 'opacity'
        }
      })
    }
    this.checkQuery()
  },

  methods: {
    removeQuery(type, name) {
      this.currentFilters = this.currentFilters.filter(
        item => (item.slug ? item.slug !== name : item.post_name !== name)
      )
      const newQuery = Object.assign({}, this.$route.query)
      delete newQuery[type]
      this.$router.push({
        path: this.$route.path,
        query: newQuery
      })
      // switch (type) {
      //   case 'designer':
      //     delete newQuery.designer
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'type':
      //     delete newQuery.type
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'family':
      //     delete newQuery.family
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'material':
      //     delete newQuery.material
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'collection':
      //     delete newQuery.collection
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      // }
    },
    addQuery(type, name) {
      this.currentFilters = this.currentFilters.filter(
        item => item.type !== type
      )
      const newQuery = Object.assign({}, this.$route.query)
      newQuery[type] = name
      this.$router.push({
        path: this.$route.path,
        query: newQuery
      })
      // switch (type) {
      //   case 'designer':
      //     newQuery.designer = name
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'type':
      //     newQuery.type = name
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'family':
      //     newQuery.family = name
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'material':
      //     newQuery.material = name
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      //   case 'collection':
      //     newQuery.collection = name
      //     this.$router.push({
      //       path: this.$route.path,
      //       query: newQuery
      //     })
      //     break
      // }
    },
    expand(e) {
      const submenu = e.target.lastChild
      if (submenu.style) {
        if (submenu.style.height !== submenu.scrollHeight + 'px') {
          submenu.style.height = submenu.scrollHeight + 'px'
        } else {
          submenu.style.height = '0px'
        }
      }
    },
    selectFilter(filter) {
      this.currentSelection = filter
    },
    toggle() {
      if (!this.open && !this.currentSelection) {
        this.currentSelection = 'filteredTags'
      }
      this.open = !this.open
      this.$emit('active', this.open)
    },
    hide() {
      this.open = false
      this.$emit('active', false)
    },
    checkQuery() {
      if (this.$route.query.type) {
        const result = this.tags.find(
          tag => tag.slug === this.$route.query.type
        )
        if (result) {
          this.currentFilters.forEach((element, index, object) => {
            if (element.type === 'type') {
              object.splice(index, 1)
            }
          })
          result.type = 'type'
          if (!this.currentFilters.includes(result)) {
            this.currentFilters.push(result)
          }
        }
      }
      if (this.$route.query.family) {
        const result = this.families.find(
          tag => tag.slug === this.$route.query.family
        )
        if (result) {
          result.type = 'family'
          if (!this.currentFilters.includes(result)) {
            this.currentFilters.push(result)
          }
        }
      } else {
        this.currentFilters.forEach((element, index, object) => {
          if (element.type === 'family') {
            object.splice(index, 1)
          }
        })
      }
      if (this.$route.query.designer) {
        const result = this.designers.find(
          designer => designer.post_name === this.$route.query.designer
        )
        if (result) {
          result.type = 'designer'
          if (!this.currentFilters.includes(result)) {
            this.currentFilters.push(result)
          }
        }
      } else {
        this.currentFilters.forEach((element, index, object) => {
          if (element.type === 'designer') {
            object.splice(index, 1)
          }
        })
      }
      if (this.$route.query.material) {
        const result = this.materials.find(
          material => material.slug === this.$route.query.material
        )
        if (result) {
          result.type = 'material'
          if (!this.currentFilters.includes(result)) {
            this.currentFilters.push(result)
          }
        }
      } else {
        this.currentFilters.forEach((element, index, object) => {
          if (element.type === 'material') {
            object.splice(index, 1)
          }
        })
      }
      if (this.$route.query.collection) {
        const result = this.collections.find(
          collection => collection.slug === this.$route.query.collection
        )
        if (result) {
          result.type = 'collection'
          if (!this.currentFilters.includes(result)) {
            this.currentFilters.push(result)
          }
        }
      } else {
        this.currentFilters.forEach((element, index, object) => {
          if (element.type === 'collection') {
            object.splice(index, 1)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.filter-wrapper {
  position: sticky;
  top: 110px;
  z-index: 1000;
  transition: all 300ms;
  @include media($mobile) {
    top: 70px;
  }

  .header-hidden & {
    top: 20px;
    // transform: translateY(-90px);

    @include media($mobile) {
      top: 10px;
      // transform: translateY(-60px);
    }
  }
}
.filter {
  @include grid-container-responsive;
  @include fluid-prop(margin-bottom, 20px, 40px);

  > * {
    @include grid-item(12, 1);

    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }

  .filter-bar {
    position: relative;
    @include media($mobile) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
.filter-selector {
  padding: 40px 0;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  border-top: 1px solid $gray--light;
  border-bottom: 1px solid $gray--light;
  li {
    display: inline-block;
    opacity: 0.5;
    transition: all 100ms;
    cursor: pointer;

    &.active,
    &:hover {
      opacity: 1;
    }
  }
}
.filters {
  columns: 4;
  padding: 40px 20px;

  @include media($mobile) {
    padding: 40px 10px;
  }

  li {
    transition: opacity 1s;
  }

  @include media($medium) {
    columns: 3;
  }
  @include media($mobile) {
    columns: 2;
  }

  li {
    margin-bottom: 10px;
  }
}
.filter-nav {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 20px;
  background-color: $white;
  cursor: pointer;

  @include media($mobile) {
    // background-color: #d6d7d2;
  }

  .label {
    display: inline-block;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 5px;
    letter-spacing: 1px;
  }

  .count {
    opacity: 0.5;
    white-space: nowrap;
  }

  .filter-item {
    margin-right: 15px;
    position: relative;
    padding-right: 22px;
    @include media($mobile) {
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .arrow-down {
    width: 17px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-left: 5px;
    position: relative;
    transform: translateY(3px);

    &:after,
    &:before {
      content: '';
      width: 1px;
      height: 6px;
      background-color: $gray;
      position: absolute;
      top: 0px;
    }
    &:after {
      transform: rotate(-45deg);
      left: 0px;
    }
    &:before {
      transform: rotate(45deg);
      left: 4px;
    }
    .active & {
      transform: scaleY(-1);
      top: -1px;
    }
  }

  .clear {
    border: 1px solid $gray--light;
    width: 17px;
    height: 17px;
    display: inline-block;
    border-radius: 50%;
    margin-left: 5px;
    position: relative;
    position: absolute;
    right: 0;
    top: 2px;

    @include media($mobile) {
      background-color: $main-color;
      border-color: transparent;
    }

    &:hover {
      border-color: $black;
      &:after,
      &:before {
        background-color: $black;
      }
    }

    &:after,
    &:before {
      content: '';
      width: 1px;
      height: 9px;
      background-color: $gray;
      position: absolute;
      top: 3px;
      left: 7px;
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
}
.expanded {
  overflow: hidden;
  position: absolute;
  width: 100%;
  pointer-events: none;

  .expanded-inner {
    background-color: $white;
    transform: translateY(-100%);
    transition: transform 500ms $easeOutExpo;

    &.desktop {
      @include media($mobile) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      // background-color: #d6d7d2;
      height: calc(100vh - 120px);
      overflow-y: scroll;
      transition: height 200ms;

      .header-hidden & {
        height: calc(100vh - 60px);
      }
      @include media($mobile) {
        display: block;
      }
      > ul {
        text-align: left;
        > li {
          display: block;
          border-bottom: 1px solid $line-color;

          > a {
            pointer-events: none;
            position: relative;
            &:after {
              content: '';
              display: block;
              width: 10px;
              height: 2px;
              background-color: $black;
              transform: rotate(45deg);
              position: absolute;
              right: 27px;
              top: 27px;
            }
            &:before {
              content: '';
              display: block;
              width: 10px;
              height: 2px;
              background-color: $black;
              transform: rotate(-45deg);
              position: absolute;
              right: 20px;
              top: 27px;
            }
          }

          > a {
            padding: 20px;
            display: block;
          }
        }
      }
      .submenu {
        height: 0;
        overflow: hidden;
        transition: height 500ms $easeInOutExpo;
        li {
          a {
            opacity: 0.5;
            padding: 10px 20px;
            display: block;
          }
          &:first-child {
            a {
              padding-top: 0;
            }
          }

          &:last-child {
            a {
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    .active & {
      transform: translateY(0);
      transition-duration: 800ms;
      pointer-events: all;
    }
  }
}
</style>
