<template>
  <fragment>
    <Base :style="[styles]">
    <section v-vpshow class="wrapper">
      <div
        :ref="'bodyText'"
        :data-features="data.wysiwyg.type_features"
        :data-layout="data.wysiwyg.layout"
        :data-position="!layout ? data.wysiwyg.position : null"
        class="body-text"
      >
        <div :style="[bodyStyle, layout]" class="content">
          <template v-if="prev">
            <template v-if="prev.singleimage">
              <template
                v-if="
                  prev.singleimage.size === 'small' || prev.singleimage.wrap
                "
              >
                <Img
                  :class="prev.singleimage.wrapped_position"
                  :style="[
                    wrapped,
                    prev.singleimage.wrap ? columns : false,
                    padding
                  ]"
                  :data="prev.singleimage.image"
                  :no-scale="prev.singleimage.no_scale"
                  :enable="prev.singleimage.zoom"
                  :data-size="prev.singleimage.size"
                  :data-placement="prev.singleimage.caption_placement"
                ></Img>
              </template>
            </template>

            <template v-if="prev.gallery_v2">
              <span
                v-if="prev.gallery_v2.layout_clone.wrap"
                :style="[wrapped, padding]"
                :class="prev.gallery_v2.layout_clone.wrapped_position"
                class="embedded-module"
              >
                <Slideshow
                  :data="prev.gallery_v2"
                />
              </span>
            </template>

            <template
              v-else-if="prev.video && prev.video.wrap"
              :style="[wrapped, padding]"
              :class="prev.video.wrapped_position"
              class="embedded-module"
            >
              <Vid
                :data="prev.video"
              />
            </template>

            <span
              v-else-if="prev.audio && prev.audio.wrap"
              :style="[wrapped, padding]"
              :class="prev.audio.wrapped_position"
              class="embedded-module"
            >
              <Audio
                :data="prev"
              />
            </span>

            <span
              v-else-if="prev.quote && prev.quote.wrap"
              :class="prev.quote.wrapped_position"
              :style="[wrapped, padding]"
              class="embedded-module"
            >
              <Quote
                :data="prev"
              />
            </span>
          </template>
          <p
            class="content-text"
            @click="handleClicks($event, '.body-text')"
            v-html="content"
          />
        </div>
      </div>
    </section>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import Img from '@/components/Img'
import Vid from '@/components/Vid'
import Audio from '@/components/modules/Audio'
import Quote from '@/components/modules/Quote'
import Slideshow from '@/components/Slideshow'
import utils from '@/mixins/utils'

export default {
  name: 'Wysiwyg',

  components: {
    Base,
    Img,
    Vid,
    Audio,
    Quote,
    Slideshow
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    prev: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      end: false
    }
  },

  computed: {
    layout() {
      if (this.data.wysiwyg.custom_layout) {
        return this.percentageGrid(this.data.wysiwyg.columns, null, '1,12')
      } else if (
        this.$store.state.moduleOptions.layout_wysiwyg &&
        this.$store.state.moduleOptions.layout_wysiwyg.custom_layout
      ) {
        return this.percentageGrid(
          this.$store.state.moduleOptions.layout_wysiwyg.columns
        )
      } else {
        return this.percentageGrid('3,10', null, '1,12')
      }
    },
    columns() {
      if (this.prev && this.prev.singleimage) {
        if (this.prev.singleimage.custom_layout) {
          return this.grid(this.prev.singleimage.columns)
        } else if (
          this.$store.state.moduleOptions.layout_image &&
          this.$store.state.moduleOptions.layout_image.custom_layout
        ) {
          return this.grid(this.$store.state.moduleOptions.layout_image.columns)
        } else {
          return false
        }
      } else {
        return false
      }
    },
    wrapped() {
      let columns = '1,4'
      if (this.prev && this.prev.singleimage) {
        columns = this.prev.singleimage.columns
      }
      if (this.prev && this.prev.quote) {
        columns = this.prev.quote.columns
      }
      if (this.prev && this.prev.video) {
        columns = this.prev.video.columns
      }
      if (this.prev && this.prev.audio) {
        columns = this.prev.audio.columns
      }
      if (this.prev && this.prev.gallery_v2) {
        columns = this.prev.gallery_v2.layout_clone.columns
      }
      return this.wrappedGrid(columns)
    },
    content() {
      let string = this.data.wysiwyg.text
      if (this.data.wysiwyg.type_features === 'smallcap') {
        // Wrap first word for smallcap styling
        string = string.replace(/>(\w+)/, '><span class="small">$1</span>')
      }
      string = string.replace('[END]', '<span class="end-icon">&nbsp;</span>')
      return string
    },
    bodyStyle() {
      return this.$store.getters.bodyStyle
    },
    padding() {
      let padding = '0 20px 20px 20px'
      if (
        this.prev &&
        this.prev.singleimage &&
        this.prev.singleimage.padding.length > 0
      ) {
        padding = this.prev.singleimage.padding
      }
      if (this.prev && this.prev.quote && this.prev.quote.padding.length > 0) {
        padding = this.prev.quote.padding
      }
      if (this.prev && this.prev.video && this.prev.video.padding.length > 0) {
        padding = this.prev.video.padding
      }
      if (this.prev && this.prev.audio && this.prev.audio.padding.length > 0) {
        padding = this.prev.audio.padding
      }
      return {
        padding: padding
      }
    }
  },

  mounted() {
    this.$refs.bodyText.style.setProperty(
      '--underline-color',
      this.styles.color
    )
    const end = this.$refs.bodyText.getElementsByClassName('end-icon')
    if (end.length > 0) {
      this.end = true
    }
  }
}
</script>

<style scoped lang="scss">
.body-text {
  @include grid-container-responsive;
  --underline-color: $black;

  ::v-deep {
    .content {
      > .embedded-module,
      > .module,
      > .image,
      > .video,
      > .slideshow,
      > .motion {
        grid-column-start: 1 !important;
        margin-right: 40px;
        margin-left: 0;
        display: inline-block;

        &.left {
          float: left;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        &.right {
          float: right;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }

        // @include media($medium) {
        //   margin-right: 30px;
        //   margin-bottom: 30px;
        //   width: 34%;
        // }

        @include media($mobile) {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    .content {
      @include grid-item(12);

      > *:not(.image):not(.module):not(.video):not(.slideshow):not(.motion) {
        margin-left: var(--margin-left);
        margin-right: var(--margin-right);
      }

      // @include media($medium) {
      //   @include grid-item(9, 1);
      // }

      @include media($mobile) {
        @include grid-item(6, 1);
      }
    }
    a {
      text-decoration: underline;

      &:after {
        bottom: 2px;
      }
      transition: all 150ms;

      &:hover {
        opacity: 0.5;
      }
    }
    p {
      margin: 0;
      // @include fluid-prop(margin-bottom, 20px, 30px);

      > p {
        break-inside: avoid;
      }

      > *:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin: 0;
      }
    }
    ul,
    ol {
      li {
        padding: 13px 0;
        position: relative;
        border-bottom: 2px solid #efefef;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    ul {
      li {
        padding-left: 30px;
        overflow: hidden;
        &:before {
          content: '–';
          position: absolute;
          left: 0;
        }
      }
    }
    .smallcaps {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 80%;
      line-height: inherit;
    }
    .dropcap {
      float: left;
      font-size: 500%;
      line-height: 80%;
      @include fluid-prop(padding-top, 8px, 8px);
      padding-right: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
    ol {
      list-style: decimal;
      list-style-position: inside;
    }

    // h1 {
    //   @include fluid-prop(margin-bottom, 48px, 60px);
    //   break-after: avoid !important;
    // }
    // h2 {
    //   @include fluid-prop(margin-top, 48px, 60px);
    //   @include fluid-prop(margin-bottom, 20px, 30px);
    //   break-after: avoid !important;
    // }
    // h3 {
    //   @include fluid-prop(margin-bottom, 48px, 60px);
    //   break-after: avoid !important;
    // }

    // h4 {
    //   @include fluid-prop(margin-bottom, 20px, 30px);
    // }
    // h5 {
    //   @include fluid-prop(margin-bottom, 20px, 30px);
    // }
    // h6 {
    //   @include fluid-prop(margin-bottom, 20px, 30px);
    // }

    .text-wrap {
      break-inside: avoid;
      @include fluid-prop(margin-top, 30px, 60px);
      @include fluid-prop(margin-bottom, 30px, 60px);
    }

    > *:first-child {
      margin-top: 0 !important;
    }
  }

  &[data-features='dropcap'] {
    ::v-deep {
      p {
        &:first-of-type {
          &:first-letter {
            float: left;
            @include fluid-type(80px, 100px);
            @include fluid-prop(line-height, 55px, 75px);
            @include fluid-prop(padding-top, 10px, 14px);
            padding-right: 8px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &[data-features='smallcap'] {
    ::v-deep {
      p {
        .small {
          text-transform: uppercase;
          font-weight: bold;
          @include fluid-prop(line-height, 20px, 25px);
        }
      }
    }
  }

  &[data-layout='four_col'] {
    ::v-deep {
      .content {
        > p {
          columns: 4;
          column-gap: $grid-gap;

          @include media($large) {
            columns: 3;
          }

          @include media($medium) {
            columns: 2;
          }

          @include media($mobile) {
            columns: 1;
          }
        }
      }
    }
  }

  &[data-layout='three_col'] {
    ::v-deep {
      .content {
        > p {
          columns: 3;
          column-gap: $grid-gap;

          @include media($large) {
            columns: 3;
          }

          @include media($medium) {
            columns: 2;
          }

          @include media($mobile) {
            columns: 1;
          }
        }
      }
    }
  }

  &[data-layout='two_col'] {
    ::v-deep {
      .content {
        > p {
          columns: 2;
          column-gap: $grid-gap;
          @include media($mobile) {
            @include fontsize(20);
            columns: 1;
          }
        }
      }
    }
  }
}
</style>
