<template>
  <div>
    <template v-for="(bit, index) in accordion">
      <AccordionItem v-if="bit.includes('<h3')" :key="index" :drawing="drawing" :headline="bit" :text="accordion[index + 1] || null">
      </AccordionItem>
    </template>
  </div>
</template>

<script>
import AccordionItem from './AccordionItem'
export default {
  components: {
    AccordionItem
  },
  props: {
    data: {
      type: String,
      default: ''
    },
    drawing: {
      type: [Object, Boolean],
      default: () => {
        return {}
      }
    }
  },
  computed: {
    accordion() {
      const example = this.data
      return example.split(/(<h3.+?(?=h3>)...)/g)
    }
  }
}
</script>
