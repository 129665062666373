<template>
  <fragment>
    <Base :style="[styles, headlineStyle]">
    <div
      v-vpshow
      :data-size="data.headline.size || 'small'"
      :class="styles.fontFamily"
      :data-align="data.headline.align || 'center'"
      :data-position="data.headline.position || 'center'"
      :data-fit="data.headline.fit"
      :data-color="data.headline.text_color"
      class="wrapper display-text"
    >
      <div
        ref="title"
        :style="[headlineFontWeight, headlineFontFamily, layout]"
        :class="dasherize(fontFamily)"
        @click="handleClicks($event, '.display-text')"
        v-html="data.headline.text"
      />
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import utils from '@/mixins/utils'

export default {
  name: 'Headline',

  components: {
    Base
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      splitElements: null,
      originalSizes: [],
      parentFontSize: null
    }
  },

  computed: {
    layout() {
      if (this.data.headline.custom_layout) {
        return this.grid(this.data.headline.columns)
      } else if (
        this.$store.state.moduleOptions.layout_headline &&
        this.$store.state.moduleOptions.layout_headline.custom_layout
      ) {
        return this.grid(
          this.$store.state.moduleOptions.layout_headline.columns
        )
      } else {
        return this.grid('3,10')
      }
    },
    headlineFontWeight() {
      return {
        fontWeight: this.fontWeight
      }
    },
    headlineFontFamily() {
      return {
        fontFamily: this.fontFamily
      }
    }
  },

  created() {
    if (this.settings.font) {
      this.fontFamily = this.settings.font
    } else if (this.$store.state.styles.baseFontFamily) {
      this.fontFamily = this.$store.state.styles.baseFontFamily
    } else if (
      this.$store.state.fontOptions.font_headline &&
      this.$store.state.fontOptions.font_headline.font
    ) {
      this.fontFamily = this.$store.state.fontOptions.font_headline.font
    }

    if (this.$store.state.fontOptions.font_headline) {
      if (this.$store.state.fontOptions.font_headline.weight) {
        this.fontWeight = this.$store.state.fontOptions.font_headline.weight
      }
    }
  },

  mounted() {
    if (this.data.headline.fit === 'fit') {
      setTimeout(() => {
        const self = this
        this.parentFontSize = parseFloat(
          window
            .getComputedStyle(this.$refs.title, null)
            .getPropertyValue('font-size')
        )
        // eslint-disable-next-line
        const mySplitText = new SplitText(this.$refs.title, { type: 'lines' })
        this.splitElements = [].slice.call(mySplitText.elements[0].children)
        this.splitElements.forEach(function(value, index) {
          self.originalSizes[index] = value.offsetWidth
        })
        window.addEventListener('resize', this.resize)
        this.resize()
      }, 200)
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    resize() {
      const self = this
      if (this.$refs.title) {
        const parentWidth = this.$refs.title.offsetWidth
        this.splitElements.forEach(function(value, index) {
          const offset = self.originalSizes[index]
          value.style.fontSize =
            (self.parentFontSize * parentWidth) / offset + 'px'
          value.style.lineHeight =
            (self.parentFontSize * parentWidth) / offset + 'px'
        })
      }
    },
    longer(champ, contender) {
      return contender.length > champ.length ? contender : champ
    },

    longestWord(str) {
      const words = str.replace(/<(?:.|\n)*?>/gm, '').split(/-| /)
      return words.reduce(this.longer)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container-responsive;
}
.display-text > div {
  > :last-child {
    margin-bottom: 0 !important;
  }
}
.display-text[data-fit='fit'] {
  ::v-deep {
    div {
      display: table !important;
      white-space: nowrap;
    }
  }
}
</style>
