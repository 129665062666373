<template>
  <fragment>
    <Base :style="[styles]" class="space"
    :class="[data.space.hide_on_mobile ? 'mobile-hide' : '',
            data.space.hide_on_desktop ? 'desktop-hide' : '']">
    <div
      :data-distance="data.space.distance"
      :style="customHeight"
      :data-negative="(data.space.negative ? 'true': '')"
    />
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'

export default {
  name: 'Space',

  components: {
    Base
  },
  extends: Base,

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  computed: {
    customHeight() {
      if (this.data.space.distance === 'custom') {
        return 'height:' + this.data.space.custom + ';'
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.space {
  &.mobile-hide {
    @include media($mobile) {
      display: none;
    }
  }
  &.desktop-hide {
    display: none;
    @include media($mobile) {
      display: block;
    }
  }
}
[data-distance='small'] {
  @include fluid-prop(height, 15px, 30px);

  &[data-negative='true'] {
    height: 0;
    @include fluid-prop(margin-top, -15px, -30px);
  }
}
[data-distance='medium'] {
  @include fluid-prop(height, 30px, 80px);
  &[data-negative='true'] {
    height: 0;
    @include fluid-prop(margin-top, -30px, -80px);
  }
}
[data-distance='large'] {
  @include fluid-prop(height, 60px, 120px);
  &[data-negative='true'] {
    height: 0;
    @include fluid-prop(margin-top, -60px, -120px);
  }
}
[data-distance='xlarge'] {
  @include fluid-prop(height, 60px, 240px);
  &[data-negative='true'] {
    height: 0;
    @include fluid-prop(margin-top, -60px, -240px);
  }
}
</style>
