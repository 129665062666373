var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"vpshow",rawName:"v-vpshow"}],staticClass:"video",class:[
    { 'no-scale': _vm.data.no_scale }
  ],style:([_vm.videoStyle, _vm.layout, {paddingBottom: _vm.aspectRatio * 100 + '%'}]),attrs:{"data-size":_vm.data.size}},[_c('no-ssr',[_c('div',{ref:"video"},[(_vm.provider() === 'youtube')?_c('vue-plyr',[_c('div',_vm._b({ref:'player',staticClass:"plyr__video-embed",attrs:{"data-plyr-config":_vm.playerConfig},on:{"play":_vm.play,"ready":_vm.playerReady}},'div',_vm.playerOptions,false),[_c('iframe',{attrs:{"src":_vm.playerSrc() +
                '?origin=https://plyr.io&iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1',"allowfullscreen":"","allowtransparency":"","allow":"autoplay"}})])]):(_vm.provider() === 'vimeo' && !_vm.data.loop && !_vm.vimeoLoaded && !_vm.isiOS)?_c('div',{staticClass:"plyr vimeo-placeholder"},[_c('img',{attrs:{"src":_vm.vimeoThumbnail}}),_vm._v(" "),_c('button',{staticClass:"plyr__control plyr__control--overlaid",attrs:{"type":"button","data-plyr":"play"},on:{"click":function($event){return _vm.loadVimeo(true)}}},[_c('svg',{attrs:{"role":"presentation","focusable":"false"}},[_c('use',{attrs:{"xlink:href":"#plyr-play"}})]),_c('span',{staticClass:"plyr__sr-only"},[_vm._v("Play")])])]):(_vm.provider() === 'vimeo' && !_vm.data.loop && _vm.vimeoLoaded && !_vm.isiOS)?_c('vue-plyr',[_c('div',_vm._b({ref:'player',staticClass:"plyr__video-embed",attrs:{"data-plyr-config":_vm.playerConfig},on:{"play":_vm.play,"ready":_vm.playerReady}},'div',_vm.playerOptions,false),[_c('iframe',{attrs:{"src":_vm.playerSrc() +
                '?amp;byline=false&portrait=false&autoplay=' +
                _vm.playerOptions.autoplay +
                '&autopause=0&loop=' +
                _vm.data.loop +
                '&title=false&speed=true&transparent=0&gesture=media&gesture=media',"allowfullscreen":"","allowtransparency":"","allow":"autoplay"}})])]):(_vm.provider() === 'vimeo' && _vm.isiOS)?_c('div',{staticClass:"embed-container"},[_c('iframe',{attrs:{"src":_vm.playerSrc(),"width":"100%","frameborder":"0","webkitallowfullscreen":"","mozallowfullscreen":"","allowfullscreen":""}})]):(_vm.provider() === 'file' && !_vm.data.autoplay)?_c('vue-plyr',[_c('video',_vm._b({ref:'player',attrs:{"data-plyr-config":_vm.playerConfig,"poster":_vm.playerPoster(),"playsinline":""},on:{"play":_vm.play,"ready":_vm.playerReady,"loadedmetadata":_vm.loadedmetadata}},'video',_vm.playerOptions,false),[_c('source',{attrs:{"src":_vm.playerSrc(),"type":_vm.playerType()}})])]):_c('video',{attrs:{"muted":"","autoplay":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.playerSrc(),"type":_vm.playerType()}})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }