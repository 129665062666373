<template>
  <article
    :class="['id-'+id, { 'image-top': imageTop }, paddingTop, { 'cover-top': coverTop }, { 'sticky-header': this.$store.getters.siteOptions.header_sticky }]"
    :style="[styles, moduleSpacing]"
    class="article"
  >
    <!-- Product -->
    <ProductTop
      v-if="page.post_type === 'product'"
      :data="page.acf"
      :title="page.title.rendered"
    />

    <!-- Upholstery -->
    <UpholsteryTop
      v-if="page.post_type === 'upholstery'"
      :data="page.acf"
      :title="pageMeta.title"
    />

    <!-- Material -->
    <MaterialTop
      v-if="page.post_type === 'material'"
      :data="page.acf"
      :title="pageMeta.title"
    />

    <!-- Publication -->
    <Publication
      v-if="page.post_type === 'publication'"
      :data="page.acf"
      :title="page.post_title"
    />

    <!-- Modules -->
    <Component
      :is="module.acf_fc_layout"
      v-for="(module, index) in modules"
      :key="index"
      :data="module"
      :parent="page.post_type === 'product' ? page.acf : null"
      :prev="modules[index-1]"
      :next="modules[index+1]"
      :module-type="module.acf_fc_layout.toLowerCase()"
    />
  </article>
</template>

<script>
import Audio from '@/components/modules/Audio'
import Button from '@/components/modules/Button'
import bus from '@/components/bus'
import Coverv2 from '@/components/modules/Coverv2'
import Custom from '@/components/modules/Custom'
import Embed from '@/components/modules/Embed'
import Galleryv2 from '@/components/modules/Galleryv2'
import Headline from '@/components/modules/Headline'
import Issuu from '@/components/modules/Issuu'
import Layout from '@/components/modules/Layout'
import Linklist from '@/components/modules/Linklist'
import List from '@/components/modules/List'
import Motion from '@/components/modules/Motion'
import Quote from '@/components/modules/Quote'
import Promov2 from '@/components/modules/Promov2'
import Section from '@/components/modules/Section'
import Showroom from '@/components/modules/Showroom'
import Singleimage from '@/components/modules/Singleimage'
import Space from '@/components/modules/Space'
import Storelocator from '@/components/modules/Storelocator'
import Contacts from '@/components/modules/Contacts'
import Subnavigation from '@/components/modules/Subnavigation'
import Video from '@/components/modules/Video'
import Wysiwyg from '@/components/modules/Wysiwyg'
import ajax from '@/mixins/ajax'
import utils from '@/mixins/utils'
import Img from '@/components/Img'
import ProductTop from '@/components/ProductTop'
import UpholsteryTop from '@/components/UpholsteryTop'
import MaterialTop from '@/components/MaterialTop'
import Upholsterylist from '@/components/modules/Upholsterylist'
import Materiallist from '@/components/modules/Materiallist'
import Publication from '@/components/Publication'
import Productviewer from '@/components/modules/Productviewer'
import axiosRetry from 'axios-retry';

export default {
  name: 'Page',

  components: {
    Img,
    Audio,
    Button,
    Contacts,
    Coverv2,
    Custom,
    Embed,
    Galleryv2,
    Headline,
    Issuu,
    Layout,
    Linklist,
    List,
    MaterialTop,
    Motion,
    Quote,
    Promov2,
    Section,
    Showroom,
    Singleimage,
    Space,
    Storelocator,
    Subnavigation,
    Video,
    Wysiwyg,
    ProductTop,
    UpholsteryTop,
    Upholsterylist,
    Materiallist,
    Publication,
    Productviewer,
  },

  mixins: [ajax, utils],

  data() {
    return {
      page: {},
      modules: [],
      options: {},
      sections: {},
      id: '',
      backgroundColor: '',
      textColor: ''
    }
  },
  head() {
    const title = '&Tradition'
    return this.pageMeta && this.pageMeta.title ? {
      title:
        this.pageMeta.title && this.$route.path !== '/'
          ? title + ' — ' + this.pageMeta.title
          : title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content:
            this.pageMeta.title && this.$route.path !== '/'
              ? title + ' — ' + this.pageMeta.title
              : title
        },
        {
          hid: 'description',
          name: 'description',
          content: this.pageMeta.description
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageMeta.description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.pageMeta.url
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageMeta.title ? this.pageMeta.title + title : title
        },
        { hid: 'og:type', property: 'og:type', content: 'article' },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.pageMeta.image ? this.pageMeta.image[0] : null
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: this.pageMeta.image ? this.pageMeta.image[1] : null
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: this.pageMeta.image ? this.pageMeta.image[2] : null
        }
      ]
    } : null
  },

  computed: {
    personBackground() {
      return {
        'background-color': this.$store.getters.styles.baseTextColor
      }
    },

    productBackground() {
      return {
        'background-color': this.hexToRgbA(
          this.$store.getters.styles.baseTextColor,
          0.1
        )
      }
    },
    paddingTop() {
      if (this.page.acf && this.page.acf.top_padding) {
        return 'padding-' + this.page.acf.top_padding
      } else {
        return null
      }
    },
    image() {
      // Set og:image if present - otherwise default
      if (this.page.better_featured_image) {
        const image = {}
        image.url = this.page.better_featured_image.media_details.sizes.social.source_url
        image.width = this.page.better_featured_image.media_details.sizes.social.width
        image.height = this.page.better_featured_image.media_details.sizes.social.height
        return image
      } else if (this.$store.getters.siteOptions.image) {
        const image = {}
        image.url = this.$store.getters.siteOptions.image.sizes.social
        // prettier-ignore
        image.width = this.$store.getters.siteOptions.image.sizes['social-width']
        // prettier-ignore
        image.height = this.$store.getters.siteOptions.image.sizes['social-height']
        return image
      } else {
        return {}
      }
    },
    imageTop() {
      return this.$store.getters.imageTop
    },

    coverTop() {
      return this.$store.getters.coverTop
    },

    styles() {
      return {
        'background-color': this.backgroundColor,
        color: this.textColor
      }
    },
    moduleSpacing() {
      let max = 60
      let min = 30
      if (
        this.page.acf &&
        this.page.acf.options &&
        this.page.acf.options.custom_spacing
      ) {
        max = this.page.acf.options.maximum_space
        min = this.page.acf.options.minimum_space
      } else if (this.$store.getters.siteOptions.custom_spacing) {
        max = this.$store.getters.siteOptions.maximum_space
        min = this.$store.getters.siteOptions.minimum_space
      }
      let space = max
      if (this.$mq === 'medium') {
        space = max - (max - min) / 2
      }
      if (
        this.$mq === 'small' ||
        this.$mq === 'mobile' ||
        this.$mq === 'tiny'
      ) {
        space = min
      }
      return {
        '--module-spacing': space + 'px'
      }
    }
  },

  async asyncData({ params, $axios, route }) {
    // try {
    //   let url = 'https://wp.andtradition.com/wp-json/trouble/post'
    //   if (!params.slug || params.slug.length < 2) {
    //     url = 'https://wp.andtradition.com/wp-json/trouble/frontpage'
    //   }

    //   const p = {
    //     meta: true,
    //     slug: params.slug
    //   }
      

    //   if (route.name === 'preview') {
    //     p._wpnonce = route.params.wpnonce
    //     p.status = 'draft'
    //   }

    //   axiosRetry($axios, { retries: 6, retryDelay: (retryCount) => {
    //     return retryCount * 10;
    //   }});

    //   const response = await $axios.get(url, { params: p, headers: { 'Content-Type': 'application/json',
    //     'Accept': 'application/json' } }).catch(function(error) {
    //     if (error.response) {
    //       console.error(error.response.data);
    //       console.error(error.response.status);
    //       console.error(error.response.headers);
    //     } else if (error.request) {
    //       console.error(error);
    //       console.error('Request error');
    //       console.error("Error", error.message);
    //     } else {
    //       console.error("Error", error.message);
    //     }
    //   })
    //   console.log(response.data)
    //   // if(response) {
    //   //   return { pageMeta: response.data }
    //   // } else {
    //   //   return { pageMeta: {}}
    //   // }
    // } catch(error) {
    //   this.errorLog(error)
    // }

    let p = {
      meta: 'true',
    }

    let url = 'https://wp.andtradition.com/wp-json/trouble/post'
    if (!params.slug || params.slug.length < 2) {
      url = 'https://wp.andtradition.com/wp-json/trouble/frontpage'
    } else {
      p.slug = params.slug
    }

    
    if (route.name === 'preview') {
      p._wpnonce = route.params.wpnonce
      p.status = 'draft'
    }

    p = Object
      .keys(p)
      .map(k => {
          if (Array.isArray(params[k])) {
              return params[k]
                  .map(val => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
                  .join('&')
          }
  
          return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
      })
      .join('&').replace('undefined', 'true')


    const response = await fetch(url + (p ? '?'+p : ''),{
      headers: {
       'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .catch(error => console.error('Error:', error));
    return { pageMeta: response }


  },


  created: function() {
    this.$store.commit('imageTop', false)
    this.page = this.getPage().then(page => {
      if (process.client) {
        setTimeout(() => {
          // window.scrollTo(0, 0)
        }, 10)
        this.renderPage(page.data)
      }
    })
  },

  mounted() {
    if (process.client) {
      setTimeout(() => {
        // window.scrollTo(0, 0)
      }, 10)
    }
  },

  methods: {
    renderPage(page) {
      bus.$emit('toggleLoading', false)
      if (this.$nuxt && this.$nuxt.$loading) {
        this.$nuxt.$loading.finish()
      }
  
      if (page) {
        this.page = page
        if (!this.page.acf.background_color) {
          this.page.acf.background_color = '#EDECE8'
        }
        if (!this.page.acf.text_color) {
          this.page.acf.text_color = '#282828'
        }
        if (this.page.post_type === 'person') {
          this.page.acf.background_color = '#d6d7d1'
        }
        this.id = this.page.ID

        for (let i = 0; i < this.page.acf.modules.length; i++) {
          const string = this.page.acf.modules[i].acf_fc_layout
          if (this.page.acf.modules[i].promo_v2) {
            this.page.acf.modules[i].promov2 = this.page.acf.modules[i].promo_v2
          }
          if (this.page.acf.modules[i].cover_v2) {
            this.page.acf.modules[i].coverv2 = this.page.acf.modules[i].cover_v2
          }
          if (this.page.acf.modules[i].gallery_v2) {
            // prettier-ignore
            this.page.acf.modules[i].galleryv2 = this.page.acf.modules[i].gallery_v2
          }
          this.page.acf.modules[i].acf_fc_layout =
            string.charAt(0).toUpperCase() + string.slice(1)
        }
        this.modules = JSON.parse(JSON.stringify(this.page.acf.modules))
        this.backgroundColor = this.page.acf.background_color
        this.textColor = this.page.acf.text_color

        // Set page colors
        this.$store.commit('setBaseBackgroundColor', this.backgroundColor)
        this.$store.commit('setBaseTextColor', this.textColor)

        document.body.style.setProperty('--main-color', this.backgroundColor)
        document.body.style.setProperty('--main-text-color', this.textColor)

        // Set header colors open/closed
        this.$store.commit(
          'setBackgroundMenu',
          this.page.acf.header_background_color_menu
        )
        this.$store.commit(
          'setBackgroundOpen',
          this.page.acf.header_background_color_open
        )
        this.$store.commit(
          'setBackgroundClosed',
          this.page.acf.header_background_color_closed
        )
        this.$store.commit('setTextMenu', this.page.acf.header_text_color_menu)
        this.$store.commit('setTextOpen', this.page.acf.header_text_color_open)
        this.$store.commit(
          'setTextClosed',
          this.page.acf.header_text_color_closed
        )

        // Set base font family
        this.$store.commit(
          'setBaseFontFamily',
          this.page.acf.font ? this.page.acf.font : null
        )
        // Set header/footer visible/hidden
        if (this.page.post_type === 'publication') {
          this.$store.commit('hideHeader', true)
          this.$store.commit('hideFooter', true)
        } else {
          this.$store.commit(
            'hideHeader',
            this.page.acf.hide_header ||
              this.$store.getters.siteOptions.header_defaults.hide_header ||
              false
          )
          this.$store.commit(
            'hideFooter',
            this.page.acf.hide_footer ||
              this.$store.getters.siteOptions.header_defaults.hide_footer ||
              false
          )
        }

        // Set scroll progress on/off
        this.$store.commit(
          'showProgress',
          this.page.acf.scroll_progress ||
            this.$store.getters.siteOptions.header_defaults.scroll_progress ||
            false
        )

        if (this.modules && this.modules.length > 0) {
          // Set class if first module is full width
          if (this.modules[0].acf_fc_layout === 'singleimage') {
            if (this.modules[0].singleimage.size === 'full') {
              this.$store.commit('imageTop', true)
            }
          } else if (this.modules[0].acf_fc_layout === 'video') {
            if (this.modules[0].video.size === 'full_bleed') {
              this.$store.commit('imageTop', true)
            }
          } else if (this.modules[0].acf_fc_layout === 'promo') {
            if (this.modules[0].promo.full_bleed) {
              this.$store.commit('imageTop', true)
            }
          } else if (this.modules[0].acf_fc_layout === 'gallery') {
            if (this.modules[0].gallery.layout === 'full_bleed') {
              this.$store.commit('imageTop', true)
            }
          } else if (this.modules[0].acf_fc_layout === 'button') {
            if (this.modules[0].button.size === 'full') {
              this.$store.commit('imageTop', true)
            }
          } else if (this.modules[0].acf_fc_layout === 'cover') {
            this.$store.commit('setCoverTop', true)
          } else {
            this.$store.commit('setCoverTop', false)
          }

          // Filter sections (for Subnavigation)
          this.sections = this.modules.filter(
            module => module.acf_fc_layout === 'Section'
          )
          this.$store.commit('setSections', this.sections)
        }

        if (
          this.page.post_type === 'product' ||
          this.page.post_type === 'person' ||
          this.page.post_type === 'upholstery' ||
          this.page.post_type === 'material'
        ) {
          this.$store.commit('imageTop', true)
        }

        this.$store.commit('setPageType', this.page.post_type)
        this.$store.commit('setPageID', this.page.ID)
        this.$store.commit('page', this.page)
        this.$store.commit('setBackgroundColor', this.page.acf.background_color)
        this.$store.commit('setTextColor', this.page.acf.text_color)
        // this.getContextualModules()

        bus.$emit('reloadHeader', true)
      }
    },

    getPage: async function() {
      let response

      axiosRetry(this.$axios, { retries: 6, retryDelay: (retryCount) => {
        return retryCount * 10;
      }});

      try {
        if (this.$route.name === 'preview') {
          const params = {}
          params._wpnonce = this.$route.params.wpnonce
          params.status = 'draft'
          response = await this.$axios.get(
            bus.REST_ENDPOINT +
              `/trouble/post?slug=${this.$route.params.slug}&nonce=${
                this.$route.params.nonce
              }`,
            {
              params: params
            }
          )

          return response
          
        } else if (this.$route.path === '/404') {
          response = await this.$axios.get(
            `${bus.REST_ENDPOINT}/acf/v3/options/site_options/fourofour`
          ).then(response => {
            if (response.data.acf.fourofour) {
              return this.get(
                `/trouble/post?slug=${response.data.acf.fourofour.post_name}`
              )
            }
          })

          return response
        } else if (this.$route.path === '/') {
          response = await this.$axios.get(`${bus.REST_ENDPOINT}/trouble/frontpage`)
          return response
        } else {
          response = await this.$axios.get(
            `${bus.REST_ENDPOINT}/trouble/post?slug=${this.$route.params.slug}`
          )

          return response

        }
      } catch (error) {
        this.$router.push('/404')
        return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.article {
  min-height: 100vh;
  // padding-top: 60px;
  padding-top: var(--module-spacing);
  flex: 1;

  &.sticky-header {
    // padding-top: 180px;
    padding-top: calc(120px + var(--module-spacing));

    @include media($mobile) {
      // padding-top: 90px;
      padding-top: calc(60px + var(--module-spacing));
    }
  }

  &.image-top {
    padding-top: 90px;

    @include media($mobile) {
      padding-top: 80px;
    }
  }

  &.cover-top {
    padding-top: 0;
  }

  &.padding-top {
    padding-top: 0;
  }
  &.padding-after {
    padding-top: 90px;

    @include media($mobile) {
      padding-top: 80px;
    }
  }
}
</style>
