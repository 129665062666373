<template>
  <div
    v-vpshow
    class="product-top"
  >
    <div ref="container" class="wrapper">
      <div class="product-info">
        <div class="row">
          <div
            v-if="$mq === 'tiny' || $mq === 'mobile'"
            ref="slideshowContainer"
            class="slideshow-container"
            :style="{'transform': 'translateX(' + currentVariant * -100 + '%)'}"
          >
            <template v-for="(variant, index) in data.product_variants">
              <Slideshow
                v-if="slideshowData(index)"
                ref="slideshow"
                :key="index + '-mobile'"
                class="mobile-slideshow"
                :style="{left: index * 100 + '%'}"
                :data="slideshowData(index)"
             />
            </template>
          </div>
          <div class="product-title">
            <h1 class="title">{{ data.title }} <sup>{{ data.sub_script }}</sup></h1>
            <div v-if="data.byline">{{ data.byline[0].post_title }}</div>
            <div v-html="data.year"></div>
          </div>
          <div class="label">Variants</div>
          <div class="variant-name">
            <transition name="fade-text" mode="out-in">
              <div :key="hoverVariant" class="name" v-html="data.product_variants[hoverVariant].variant_title"></div>
            </transition>
          </div>
          <ul>
            <li v-for="(variant, index) in data.product_variants" :key="'variant-'+index" @click="selectVariant(index)" @mouseenter="variantEnter(index)" @mouseleave="variantLeave(index)">
              <div class="thumb" :class="{'active': currentVariant === index}">
                <img v-if="variant.variant_thumb" :alt="variant.variant_title" :src="getCDN(variant.variant_thumb.sizes.thumbnail)" />
                <div v-else class="placeholder"/>
              </div>
            </li>
          </ul>
          <div class="variant-description">
            <transition name="fade-text" mode="out-in">
              <div>
                <div :key="data.product_variants[hoverVariant].description" v-html="data.product_variants[hoverVariant].description"></div>
                <div class="name" v-html="data.product_variants[hoverVariant].variant_notice"></div>
              </div>
              
            </transition>
          </div>
          <div v-if="data.product_family && data.product_family.products">
            <div v-if="data.product_family.family" class="label"><!--{{data.product_family.label || 'Variant'}}--><router-link v-if="data.product_family.family.post_status === 'publish'" :to="{ path: getUrl(data.product_family.family.url), params: {} }">Explore The {{data.product_family.family.post_title}} Collection</router-link></div>
            <div class="product-family">
              <div v-for="(product, index) in data.product_family.products" :key="'family_product'+index">
                <router-link class="product" :to="{ path: getUrl(product.url), params: {} }">
                  <div class="drawing-container" :style="{paddingBottom: '100%'}">
                    <img v-if="product.drawing" :alt="product.drawing.title" v-lazy="getCDN(product.drawing.sizes['teaser-1-1'])" />
                  </div>
                  <span>{{product.title}}<sup>{{ product.superscript }}</sup></span>
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <nuxt-link :to="'/stores'" class="button dealer" v-if="!data.hide_button">Find Dealer</nuxt-link>
            <a v-if="hasProductViewer" class="button productview" @click="gotoProductViewer">Customise</a>
          </div>
         
          <div v-if="$store.state.moduleOptions.product && $store.state.moduleOptions.product.short_info" class="delivery">
            <div class="short-info" v-html="$store.state.moduleOptions.product.short_info"></div>
            <div v-if="$store.state.moduleOptions.product.short_info" class="long-info">
              <div class="expander" @click="toggleExtendedInfo">Delivery and returns</div>
              <div v-if="showExtendedInfo" v-html="$store.state.moduleOptions.product.long_info"/>
            </div>
          </div>

        </div>
      </div>

      <div v-if="$mq !== 'tiny' && $mq !== 'mobile'" class="images">
        <transition-group name="fade" v-on:after-enter="triggerResize" style="position:relative;">
          <div v-for="(variant, index) in data.product_variants" :key="'desktop-' + index" v-show="index === currentVariant" class="row">
            <Slideshow
              :data="slideshowData(index)"
            />
          </div>
        </transition-group>
      </div>

      <div v-if="data.product_specifications" class="specifications">
        <div class="row">
          <!-- <Img v-if="data.product_icon" class="product-icon" :data="data.product_icon" /> -->
          <div v-if="data.og_description" class="label first">Description</div>
          <div v-if="data.og_description" class="description" v-html="data.og_description"></div>
          <template v-if="data.product_variants[currentVariant].certifications">
            <div class="label">Certifications</div>
            <div v-for="(certification, index) in data.product_variants[currentVariant].certifications" :key="'certification'+index" :href="certification.link" target="_blank" class="certification">
              <div v-html="certification.description"></div>
            </div>
            <div class="certifications">
              <a v-for="(certification, index) in data.product_variants[currentVariant].certifications" :key="'certification'+index" :href="certification.link" target="_blank" class="certification">
                <Img
                  :data="certification.image"
                />
              </a>
            </div>
          </template>
          <div class="label">Product Details</div>
          <Accordion v-if="data.product_specifications.includes('<h3')" class="specs" :data="data.product_specifications" :drawing="data.product_drawing" />
          <div v-else v-html="data.product_specifications"></div>
          <img v-if="data.product_drawing && !data.product_specifications.includes('[DRAWING]')" v-lazy="getCDN(data.product_drawing.sizes.fullwidth)" class="product-drawing" alt="Product Drawing" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'
import Img from '@/components/Img'
import utils from '@/mixins/utils'
import Slideshow from '@/components/Slideshow'
import Accordion from '@/components/Accordion'
import bus from '@/components/bus'

export default {
  name: 'ProductTop',

  components: {
    Img,
    Slideshow,
    Accordion
  },

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      currentVariant: 0,
      hoverVariant: 0,
      showExtendedInfo: false
    }
  },

  watch: {
    $route(to, from) {
      this.checkQuery()
    }
  },
  created() {
    this.checkQuery()
  },

  computed: {
    hasProductViewer() {
      return this.data.modules.find(
        module => module.acf_fc_layout === 'Productviewer'
      )
    }
  },

  methods: {
    triggerResize() {
      if (window) {
        window.dispatchEvent(new Event('resize'))
      }
    },
    gotoProductViewer() {
      VueScrollTo.scrollTo('.product-viewer', 500)
    },
    slideshowData(index) {
      if (this.data.product_variants[index].variant_images) {
        const data = {
          images: []
        }
        if (
          this.data.product_variants[index].cylindo_id &&
          this.data.product_variants[index].frames
        ) {
          const frames = this.data.product_variants[index].frames.split(',')
          let features = ''
          this.data.product_variants[index].cylindo_features.forEach(
            feature => {
              features =
                '&feature=' +
                encodeURI(feature.name) +
                ':' +
                encodeURI(feature.value)
            }
          )
          frames.forEach(value => {
            const image = {
              sizes: {
                'full-3-4':
                  'https://content.cylindo.com/api/v2/4976/products/' +
                  this.data.product_variants[index].cylindo_id +
                  '/frames/' +
                  value +
                  '/?size=(750,1000)' +
                  features,
                'full-3-4-width': 750,
                'full-3-4-height': 1000
              }
            }
            data.images.push(image)
          })
        }
        this.data.product_variants[index].variant_images.forEach(value => {
          data.images.push(value)
        })
        data.effect = 'carousel'
        data.image_crop = '3to4'
        data.pagination = 'numbers'
        return data
      } else {
        return null
      }
    },
    variantImages(index) {
      if (
        this.data.product_variants[index].cylindo_id &&
        this.data.product_variants[index].frames
      ) {
        const images = []
        const frames = this.data.product_variants[index].frames.split(',')
        let features = ''
        this.data.product_variants[index].cylindo_features.forEach(feature => {
          features =
            '&feature=' +
            encodeURI(feature.name) +
            ':' +
            encodeURI(feature.value)
        })
        frames.forEach(value => {
          const image = {
            sizes: {
              fullwidth:
                'https://content.cylindo.com/api/v2/4976/products/' +
                this.data.product_variants[index].cylindo_id +
                '/frames/' +
                value +
                '/?size=(700,1000)' +
                features,
              'fullwidth-width': 700,
              'fullwidth-height': 1000
            }
          }
          images.push(image)
        })
        return [...images, ...this.data.product_variants[index].variant_images]
      } else {
        return this.data.product_variants[index].variant_images
      }
    },
    checkQuery() {
      if (this.$route.query.variant) {
        const self = this
        this.data.product_variants.forEach(function(value, index) {
          if (index + 1 === parseInt(self.$route.query.variant)) {
            self.currentVariant = index
            self.hoverVariant = index
          }
        })
        VueScrollTo.scrollTo('#app', 1000)
      }
    },
    toggleExtendedInfo() {
      this.showExtendedInfo = !this.showExtendedInfo
    },
    selectVariant(index) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          variant: index + 1
        }
      })
      bus.$emit('toggleLoading', false)
    },
    variantEnter(index) {
      this.hoverVariant = index
    },
    variantLeave(index) {
      this.hoverVariant = this.currentVariant
    }
  }
}
</script>

<style lang="scss" scoped>
.product-top {
  // @include module-spacing(padding-bottom);
  margin-bottom: var(--module-spacing);
  @include fluid-prop(margin-top, 0px, 40px);
}
.product-title {
  @include fontsize(20);
  font-weight: 500;

  @include media($mobile) {
    font-size: 24px;
    line-height: 34px;
  }
}
.row {
  transition: top 500ms, opacity 0.4s;
  position: sticky;
  top: 90px;

  > *:first-child {
    margin-top: 0;
  }

  .header-hidden & {
    top: 20px;
  }
  .safari & {
    top: 0 !important;
    position: relative;
  }
  @include media($mobile) {
    overflow: hidden;
  }
}
.mobile-image,
.mobile-slideshow {
  overflow: hidden;
  display: none;

  @include media($mobile) {
    display: block;
    margin-bottom: 30px;
  }
}
.drawing-container {
  position: relative;
  @include fluid-prop(margin-bottom, 5px, 10px);
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 500ms;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &[lazy='loaded'] {
      opacity: 1;
    }
  }
}
.delivery {
  @include fontsize(15);
  margin-top: 10px;
  .short-info {
    opacity: 0.5;
  }

  .expander {
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.5;
    margin-bottom: 10px;
  }

  .long-info {
    white-space: pre-line;
  }
}
.variant-name {
  @include fontsize(15);
}
.product-family {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  .product {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 100%;
    .drawing-container {
      transition: color 100ms;
      width: 100%;
    }
    &:hover,
    &.nuxt-link-active {
      .drawing-container {
        background-color: #D6D7D1;
      }
    }
  }
}
.variant-description {
  margin-top: 5px;
  min-height: 30px;
}
.label {
  @include fluid-prop(margin-top, 20px, 40px);
  @include fluid-prop(margin-bottom, 10px, 15px);
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid $line-color;
  display: flex;
  justify-content: space-between;

  a {
    color: $black;
  }

  &.first {
    @include fluid-prop(margin-top, 20px, 10px);
  }
}
.button {
  margin-top: 15px;
  border: 1px solid $black;
  background-color: transparent;

  &:hover {
    background-color: $black;
    color: $white;
    text-decoration: none;
  }
  &.dealer {
    margin-top: 40px;
    margin-right: 10px;
  }
}
.name {
  font-weight: 500;
}
.specs {
  @include fluid-prop(margin-top, -10px, -15px);
  ::v-deep {
    strong,
    b {
      font-weight: 500;
    }
  }
}
.certifications {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.certification {
  flex: 0 0 20%;
  margin-right: 25px;
}

.wrapper {
  @include grid-container-responsive;
}
.images {
  @include grid-item(4, 5);
  line-height: 0;

  @include media($mobile) {
    display: none;
  }
}
.row .image {
  margin-bottom: 20px;

  ::v-deep {
    figcaption {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.specifications {
  @include grid-item(3, 10);
  position: relative;

  .product-icon {
    padding: 20px 60px;
  }
  .product-drawing {
    padding: 20px 10px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: $line-color;
    left: -$grid-gap;
    top: 0;
    @include media($mobile) {
      display: none;
    }
  }

  @include media($mobile) {
    @include grid-item(6, 1);
  }

  ::v-deep {
    p {
      margin-bottom: 10px;
    }
  }
}
.product-info {
  @include grid-item(3, 1);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: $line-color;
    right: -$grid-gap;
    top: 0;
    @include media($mobile) {
      display: none;
    }
  }

  @include media($mobile) {
    @include grid-item(6, 1);
  }
}
li {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 10px;
  @include media($mobile) {
    margin-right: 2.85%;
    width: 10%;
    height: 10%;

    &:nth-child(8n) {
      margin-right: 0;
    }
  }

  .thumb {
    height: 100%;
    width: 100%;
    line-height: 0;
    transition: all 100ms;
    cursor: pointer;
    position: relative;

    &:after {
      position: absolute;
      bottom: -5px;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: transparent;
    }

    .placeholder {
      background-color: rgba(0, 0, 0, 0.2);
      height: 100%;
    }

    &.active {
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.slideshow-container {
  position: relative;
  transition: transform 1s $easeInOutExpo;
  > div:first-child {
    position: relative;
  }
  > div:not(:first-child) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter-to,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  position: absolute;
  width: 100%;
}

.fade-text-enter-active,
.fade-text-leave-active {
  transition: all 0.1s;
}
.fade-text-enter,
.fade-text-leave-to {
  opacity: 0;
}
</style>
