<template>
  <fragment>
    <Base v-if="!textIsNext" :style="[styles]">
    <div v-vpshow :class="{ wrapper: !quoteInText }">
      <blockquote
        class="quote"
        :style="layout"
        :data-size="data.quote.size || 'medium'"
        :data-align="data.quote.align || 'center'"
        :data-position="data.quote.position || 'center'"
        :data-color="data.quote.text_color"
        v-html="data.quote.text"
      ></blockquote>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import utils from '@/mixins/utils'

export default {
  name: 'Quote',

  components: {
    Base
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    layout() {
      if (this.data.quote.custom_layout) {
        return this.grid(
          this.data.quote.columns,
          this.data.quote.columns,
          '1,6'
        )
      } else if (
        this.$store.state.moduleOptions.layout_quote &&
        this.$store.state.moduleOptions.layout_quote.custom_layout
      ) {
        return this.grid(this.$store.state.moduleOptions.layout_quote.columns)
      } else {
        return this.grid('3,10')
      }
    },
    textIsNext() {
      if (this.next && this.data.quote.wrap) {
        if (this.next.acf_fc_layout === 'Wysiwyg') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    quoteInText() {
      if (!this.next && this.data.quote.wrap) {
        return true
      } else {
        return false
      }
    },
    baseFontStyle() {
      return {
        fontFamily: this.$store.state.styles.baseFontFamily
      }
    },
    quoteStyle() {
      return this.$store.getters.quoteStyle
    },
    citeStyle() {
      return this.$store.getters.citeStyle
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container-responsive;
  position: relative;
}

blockquote {
  margin: 0;
}
</style>
