<template>
  <fragment>
    <Base :style="[styles]">
    <div
      :data-size="data.audio.size"
      :style="[data.audio.background_image ? { backgroundImage: 'url(' + data.audio.background_image.sizes.fullwidth + ')' }:'', imageFallbackStyle]"
      class="audio"
      preload="metadata"
    >
      <div :class="{'wrapper' : (data.audio.size !== 'small')}">
        <div v-if="data.audio.size === 'small'" class="title" v-html="data.audio.title" />
        <div v-if="data.audio.background_image" class="image">
          <img :src="data.audio.background_image.sizes.square">
        </div>
        <div
          v-if="!data.audio.background_image"
          :style="imageFallbackStyle"
          class="image-fallback"
        />
        <div class="player">
          <h2
            v-if="data.audio.size !== 'small'"
            :style="baseFontStyle"
            :class="baseFontStyle.fontFamily"
            class="title"
            v-html="data.audio.title"
          />
          <no-ssr>
            <vue-plyr class="plyr-wrapper">
              <audio :id="'player'+_uid" :data-plyr-config="playerConfig" controls @ready="ready">
                <source
                  v-if="data.audio.file"
                  :src="data.audio.file.url"
                  :type="data.audio.file.mime_type"
                >
                <source
                  v-if="data.audio.id"
                  :src="'https://api.soundcloud.com/tracks/'+data.audio.id+'/stream?client_id=95f22ed54a5c297b1c41f72d713623ef'"
                  type="soundcloud"
                >
              </audio>
            </vue-plyr>
          </no-ssr>
        </div>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'

export default {
  name: 'Audio',

  components: {
    Base
  },
  extends: Base,

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      imageFallbackStyle: {
        backgroundColor: this.$store.state.styles.textColor
      },
      playerConfig:
        this.data.audio.size === 'small'
          ? '{"controls": ["play", "progress"]}'
          : '{"controls": ["play", "current-time", "duration", "progress"]}'
    }
  },

  mounted() {
    if (
      this.$parent.$el.classList[0] !== 'module' &&
      this.data.audio.size === 'small'
    ) {
      this.$el.remove()
    }
  },

  methods: {
    ready(event) {
      // const path = event.path || (event.composedPath && event.composedPath())
    }
  }
}
</script>

<style scoped lang="scss">
audio {
  width: 100%;
}

.image-fallback {
  padding-bottom: 100%;
}

.title {
  //@include grid-item(7, 3);
  @include fontsize(30);
  align-self: flex-start;
}

.player {
  padding: 10px 0;
}

.plyr-wrapper {
  align-self: flex-end;
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.audio {
  ::v-deep {
    .plyr__tooltip,
    .plyr__control {
      box-shadow: none !important;

      &[data-plyr='play'] {
        .icon--not-pressed {
          background-color: $black;
          -webkit-mask-position: -3px;
          mask-position: -3px;
          -webkit-mask-image: url(#{$icons-path}play.svg);
          mask-image: url(#{$icons-path}play.svg);
        }
        .icon--pressed {
          background-color: $black;
          -webkit-mask-position: -3px;
          mask-position: -3px;
          -webkit-mask-image: url(#{$icons-path}pause.svg);
          mask-image: url(#{$icons-path}pause.svg);
        }
      }
    }
    .plyr__time--current,
    .plyr__time--duration {
      @include fontsize(20);
      line-height: 30px !important;
      font-weight: inherit;
    }
    .plyr__time--duration {
      opacity: 0.5;
      &:before {
        display: none;
      }
    }
    .plyr {
      align-self: flex-end;
      width: 100%;
    }
    .plyr--audio .plyr__controls {
      background: transparent;
      padding: 0;
    }
    button[data-plyr='play'] {
      margin-left: -7px;
    }
  }

  &[data-size='full_bleed'] {
    background-position: center;
    background-size: cover;
    @include fluid-prop(padding-top, 20px, 80px);
    @include fluid-prop(padding-bottom, 20px, 80px);

    .wrapper {
      @include grid-container;
      min-height: 200px;
      height: 100%;

      @include media($medium) {
        @include grid-container(9);
        min-height: 150px;
      }

      @include media($mobile) {
        @include grid-container(6);
        min-height: 100px;
      }
    }

    .image {
      display: none;
    }
    .title {
      @include fontsize(40);
    }
    .player {
      @include grid-item(8, 3);
      display: flex;
      height: 100%;
      flex-direction: column;

      @include media($medium) {
        @include grid-item(7, 2);
      }
      @include media($mobile) {
        @include grid-item(6, 1);
      }
    }
    ::v-deep {
      .mejs__audio {
        //@include grid-item(8, 3);
        align-self: flex-end;
      }
    }
  }

  &[data-size='medium'] {
    .wrapper {
      @include grid-container;

      @include media($medium) {
        @include grid-container(9);
      }

      @include media($mobile) {
        @include grid-container(6);
      }
    }
    background-image: none !important;
    background-color: transparent !important;

    .image-fallback,
    .image {
      @include grid-item(2, 3);

      @include media($medium) {
        @include grid-item(2, 2);
      }

      @include media($mobile) {
        @include grid-item(2, 1);
      }
    }
    .player {
      @include grid-item(6, 5);
      display: flex;
      flex-direction: column;

      @include media($medium) {
        @include grid-item(5, 4);
      }

      @include media($mobile) {
        @include grid-item(4, 3);

        .title {
          @include fontsize(20);
        }
      }
    }
  }
  &[data-size='small'] {
    background-image: none !important;
    background-color: transparent !important;
    width: calc(50%);
    margin-left: calc(25% + 10px);
    margin-right: auto;
    @include media($medium) {
      width: 78%;
      margin-left: calc(10% + 10px);
    }
    @include media($mobile) {
      width: 100%;
      margin-left: 0;
    }
    .image {
      width: 100%;
    }
    .title {
      @include fontsize(15);
      margin-bottom: 9px;
    }
    .player {
      padding: 0;
    }

    ::v-deep {
      .mejs__time {
        display: none;
      }
      .mejs__controls {
        padding: 0;
      }
      .mejs__container {
        min-width: 0 !important;
      }
      .mejs__time-rail {
        margin-right: 0;
        margin-left: 0;
      }
      .mejs__playpause-button button {
        margin-left: 0;
      }
    }
  }
}
</style>
