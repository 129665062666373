<template>
  <fragment>
    <Base class="modifier" :class="{'color-change': colorChange}">
    <div v-if="data.section.text" :id="dasherize(data.section.text)" />
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import utils from '@/mixins/utils'

export default {
  name: 'Section',

  components: {
    Base
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      colorChange: false
    }
  },

  created() {
    if (this.data.section.background_color) {
      this.colorChange = true
      this.$store.commit(
        'setBackgroundColor',
        this.data.section.background_color
      )
    } else {
      this.colorChange = true
      this.$store.commit('setBackgroundColor', '#EDECE8')
    }

    if (this.data.section.text_color) {
      this.$store.commit('setTextColor', this.data.section.text_color)
    } else {
      this.$store.commit('setTextColor', this.$store.state.styles.baseTextColor)
    }
  }
}
</script>

<style scoped lang="scss">
.modifier {
  //@include fluid-prop(margin-top, -25px, -60px);
}
</style>
