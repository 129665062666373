<template>
  <fragment>
    <Base :style="[styles]">
    <div v-html="data.custom.html" />
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'

export default {
  name: 'Custom',

  components: {
    Base
  },
  extends: Base,

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  mounted() {
    // Add custom CSS
    if (this.data.custom.css) {
      const style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = this.data.custom.css
      this.$el.appendChild(style)
    }

    // Add custom HTML
    if (this.data.custom.javascript) {
      const script = document.createElement('script')
      script.innerHTML = this.data.custom.javascript
      this.$el.appendChild(script)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
