<template>
  <div class="Storelocator">
    <div ref="filters" class="filters">
      <div class="button-wrapper">
        <a class="button" @click="getLocation" :class="{ active: currentLocationOn }"><span class="marker-icon"></span>Show stores closest to me</a>
        <div class="location-error" v-if="permissionDenied">Please allow access to your current location</div>
      </div>
      <div class="filter-label">
        Filter:
      </div>
      <v-select
        v-model="type"
        :options="filteredTypes"
        class="type"
        placeholder="Type"
        :searchable="false"
        @input="changeType"
      />
      <v-select
        v-if="locations"
        v-model="country"
        class="country"
        :options="sortArray(filteredLocations)"
        placeholder="Country"
        index="term_id"
        label="name"
        :disabled="currentLocationOn"
        @input="changeCountry"
      />
      <v-select
        v-model="city"
        :options="country ? sortArray(filteredCities) : []"
        class="city"
        placeholder="City"
        index="term_id"
        label="name"
        :disabled="!country || currentLocationOn"
        @input="changeCity"
      />
    </div>
    <div class="map-wrapper">
      <no-ssr>
        <MglMap
          :accessToken="mapboxAccessToken"
          :mapStyle.sync="mapStyle"
          :center="[12.5850105, 55.679133]"
          :zoom="3"
          :minZoom="2"
          ref="map"
          :fadeDuration="0"
          @load="onMapLoaded"
        > 
          <MglNavigationControl position="top-right" :showCompass="false" />
        </MglMap>
        <div class='sidebar'>
          <div ref="listings" id='listings' class='listings'>
            <transition name="fade" mode="out-in">
              <ul :key="type + '-' + country + '-' + city" class="stores map">
                <li v-show="filteredStores.length === 0" class="error">
                  <div class="status">No stores found</div>
                  <div class="message">
                    <div>Please contact us</div>
                    <a href="mailto:info@andtradition.com">info@andtradition.com</a>
                  </div>
                </li>
                <li v-for="store in filteredStores" :key="store.slug" :ref="'store-'+store.ID" class="store" :class="{ 'with-distance' : currentLocationOn }">
                  <div @click="storeClick(store)" class="store-button">
                    <div class="title">{{ store.post_title }}</div>
                    <div class="address">{{ store.address }}</div>
                    <div class="phone">{{ store.phone }}</div>
                    <div class="website"><a v-if="store.website" :href="store.website" target="_blank">Visit website</a></div>
                    <div class="distance"><a v-if="currentLocationOn" :href="getMaps(store)" target="_blank">Get directions ({{ store.distance }} km)</a></div>
                  </div>
                </li>
              </ul>
            </transition>
          </div>
        </div> 
      </no-ssr>
    </div>
  </div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css'
import smoothReflow from 'vue-smooth-reflow'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import ajax from '@/mixins/ajax'
export default {
  name: 'Storelocator',
  mixins: [ajax, smoothReflow],
  data() {
    return {
      locations: [],
      stores: [],
      type: null,
      country: null,
      city: null,
      types: [
        {
          label: 'Professional (B2B)',
          id: 'professional'
        },
        {
          label: 'Retail & Online (B2C)',
          id: 'private'
        }
      ],
      currentLocation: null,
      currentLocationOn: null,
      permissionDenied: null,
      selectedStoreID: null,
      map: null,
      mapboxAccessToken:
        'pk.eyJ1IjoiYXRkLXN0b3JlcyIsImEiOiJjbGNnNWZseDEza3JkM290ODk4eHB1bTRwIn0.DAJBS8D9Z8apN1S525-dTg',
      mapStyle: 'mapbox://styles/atd-stores/clcg6x49g002o14mre45urjx1'
    }
  },
  computed: {
    geoJson() {
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: this.filteredStores.map(this.itemToGeoJSONPoint)
        }
      }
    },
    filteredTypes() {
      let types = this.types

      if (this.country) {
        types = types.filter(type =>
          this.stores.some(
            store =>
              store.country === this.country.term_id &&
              store.type &&
              store.type.includes(type.id.toLowerCase())
          )
        )
      }

      return types
    },
    filteredLocations() {
      let locations = Object.values(this.locations)

      if (this.type) {
        locations = locations.filter(location =>
          this.stores.some(
            store =>
              store.country &&
              store.country === location.term_id &&
              store.type &&
              store.type.includes(this.type.id.toLowerCase())
          )
        )
      }

      return locations
    },
    filteredCities() {
      let cities = Object.values(this.country.children || {})

      if (this.type) {
        cities = cities.filter(city =>
          this.filteredStores.some(store => store.city === city.term_id)
        )
      }

      return cities
    },
    filteredStores() {
      let stores = this.stores

      if (this.type) {
        stores = stores.filter(
          store => store.type && store.type.includes(this.type.id.toLowerCase())
        )
      }
      if (this.country) {
        stores = stores.filter(store => store.country === this.country.term_id)
      }
      if (this.city) {
        stores = stores.filter(store => store.city === this.city.term_id)
      }
      if (this.currentLocation) {
        stores.forEach(store => {
          store.distance = this.getDistance(store)
        })
        stores = stores.filter(store => store.location)
        stores.sort(
          (a, b) => (Number(a.distance) > Number(b.distance) ? 1 : -1)
        )
        stores = stores.filter(store => store.distance < 30)
      }
      return stores
    },
    bounds() {
      return [
        [-122.66336, 37.492987],
        [-122.250481, 37.871651],
        [-30.250481, 37.871651]
      ]
    }
  },

  watch: {
    $route(to, from) {
      this.checkQuery()
    },
    geoJson(newValue) {
      if (this.$refs.map.map) {
        this.$refs.map.map.getSource('stores').setData(newValue.data)
        setTimeout(function() {
          const bounds = new mapboxgl.LngLatBounds()

          self.geoJson.data.features.forEach(function(feature) {
            bounds.extend(feature.geometry.coordinates)
          })
          self.$refs.map.map.fitBounds(bounds, { maxZoom: 8 })
        }, 200)
      }
      const self = this
    }
  },

  created: async function() {
    await this.getLocations().then(response => {
      this.locations = response
      this.checkQuery()
    })
  },

  mounted() {
    this.$smoothReflow({
      el: this.$refs.filters,
      transition: 'height .5s ease-in-out',
      transitionEvent: {
        selector: '.stores',
        propertyName: 'opacity'
      }
    })
  },

  methods: {
    onMapLoaded(event) {
      const map = event.map
      map.loadImage(require('~/assets/img/marker.png'), (error, image) => {
        if (error) throw error
        // Add the image to the map style.
        map.addImage('custom-marker', image)
        map.addSource('stores', {
          type: 'geojson',
          data: this.geoJson.data,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        })
        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'stores',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#D9C7AA',
              100,
              '#D9C7AA',
              200,
              '#D9C7AA'
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40
            ],
            'circle-stroke-color': '#D9C7AA',
            'circle-stroke-width': 5,
            'circle-stroke-opacity': 0.5
          }
        })
        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'stores',
          fadeDuration: 0,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
          }
        })
        map.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: 'stores',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'custom-marker',
            'icon-size': 0.08,
            'icon-anchor': 'bottom'
          }
        })
        // inspect a cluster on click
        map.on('click', 'clusters', e => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
          })
          const clusterId = features[0].properties.cluster_id
          map
            .getSource('stores')
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return
              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              })
            })
        })
        map.on('click', 'unclustered-point', e => {
          const store = e.features[0].properties
          store.location = JSON.parse(store.location)
          this.selectStore(store, true)
        })
        map.on('mouseenter', 'clusters', () => {
          map.getCanvas().style.cursor = 'pointer'
        })
        map.on('mouseleave', 'clusters', () => {
          map.getCanvas().style.cursor = ''
        })
      })
    },
    itemToGeoJSONPoint(item) {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.location.lng, item.location.lat]
        },
        properties: item
      }
    },
    markerClick(store) {
      this.selectStore(store, true)
    },
    storeClick(store) {
      this.selectStore(store, false)
    },
    selectStore(store, scrollTo) {
      if (
        this.$refs['store-' + this.selectedStoreID] &&
        this.$refs['store-' + this.selectedStoreID][0]
      ) {
        this.$refs['store-' + this.selectedStoreID][0].classList.remove(
          'selected'
        )
      }
      this.flyTo(store.location.lng, store.location.lat, 15)
      this.selectedStoreID = store.ID
      if (
        this.$refs['store-' + store.ID] &&
        this.$refs['store-' + store.ID][0]
      ) {
        this.$refs['store-' + store.ID][0].classList.add('selected')
        if (scrollTo) {
          this.$refs.listings.scrollTop = this.$refs[
            'store-' + store.ID
          ][0].offsetTop
        }
      }
    },
    flyTo(lng, lat, zoom) {
      this.$refs.map.map.flyTo({
        center: [lng, lat],
        speed: 3,
        zoom
      })
    },
    sortArray(arr) {
      arr.sort(function(a, b) {
        const textA = a.name.toUpperCase()
        const textB = b.name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      return arr
    },
    getLocations: async function() {
      const response = await this.get('/trouble/locations')
      return Object.values(response.data)
    },
    getLocation() {
      if (!this.currentLocation) {
        if (navigator.geolocation) {
          if (this.$nuxt && this.$nuxt.$loading) {
            this.$nuxt.$loading.start()
          }
          navigator.geolocation.getCurrentPosition(
            this.showPosition,
            this.positionError
          )
        } else {
          console.log('not supported!')
        }
      } else {
        this.currentLocationOn = null
        this.currentLocation = null
      }
    },
    positionError(error) {
      if (error.code === 1) {
        this.permissionDenied = true
      }
      if (this.$nuxt && this.$nuxt.$loading) {
        this.$nuxt.$loading.finish()
      }
    },
    showPosition(position) {
      this.country = null
      this.city = null
      this.currentLocation = position
      this.currentLocationOn = true
      this.permissionDenied = false
      if (this.$nuxt && this.$nuxt.$loading) {
        this.$nuxt.$loading.finish()
      }
      const self = this
      setTimeout(function() {
        self.flyTo(position.coords.longitude, position.coords.latitude, 12)
      }, 500)
    },
    changeType(val) {
      if (val) {
        this.$router.push({
          path: this.$route.path,
          query: Object.assign({}, this.$route.query, {
            type: val.id.toLowerCase()
          })
        })
      } else {
        const query = Object.assign({}, this.$route.query)
        if (query.type) {
          delete query.type
          this.type = null
        }
        this.$router.push({
          path: this.$route.path,
          query: query
        })
      }
    },
    changeCountry(val) {
      if (val) {
        const query = Object.assign({}, this.$route.query)
        if (query.city) {
          delete query.city
          this.city = null
        }
        this.$router.push({
          path: this.$route.path,
          query: Object.assign({}, query, {
            country: val.slug
          })
        })
      } else {
        const query = Object.assign({}, this.$route.query)
        if (query.country) {
          delete query.country
          this.country = null
        }
        if (query.city) {
          delete query.city
          this.city = null
        }
        this.$router.push({
          path: this.$route.path,
          query: query
        })
      }
    },
    changeCity(val) {
      if (val) {
        this.$router.push({
          path: this.$route.path,
          query: Object.assign({}, this.$route.query, { city: val.slug })
        })
      } else {
        const query = Object.assign({}, this.$route.query)
        if (query.city) {
          delete query.city
        }
        this.$router.push({
          path: this.$route.path,
          query: query
        })
      }
    },
    checkQuery() {
      if (this.$route.query.type) {
        const result = this.types.find(
          type => type.id.toUpperCase() === this.$route.query.type.toUpperCase()
        )
        if (result) {
          this.type = result
        }
      }
      if (this.$route.query.country) {
        const result = this.locations.find(
          location => location.slug === this.$route.query.country
        )
        if (result) {
          this.country = result
        }
      }
      if (this.$route.query.city) {
        const result = Object.values(this.country.children).find(
          location => location.slug === this.$route.query.city
        )
        if (result) {
          this.city = result
        }
      }
      this.getStores()
    },
    getStores: async function() {
      const params = {}

      if (this.stores.length > 0) return
      if (this.$nuxt && this.$nuxt.$loading) {
        this.$nuxt.$loading.start()
        const response = await this.get('/trouble/stores', params).then(
          this.$nuxt.$loading.finish()
        )
        this.stores = response.data
      }
    },
    getDistance(store) {
      if (store.location) {
        return this.distance(
          store.location.lat,
          store.location.lng,
          this.currentLocation.coords.latitude,
          this.currentLocation.coords.longitude,
          'K'
        )
      } else {
        return null
      }
    },
    getMaps(store) {
      return (
        'https://www.google.com/maps/dir/?api=1&destination=' +
        store.address +
        '&travelmode=driving'
      )
    },
    distance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 === lat2 && lon1 === lon2) {
        return 0
      } else {
        const radlat1 = (Math.PI * lat1) / 180
        const radlat2 = (Math.PI * lat2) / 180
        const theta = lon1 - lon2
        const radtheta = (Math.PI * theta) / 180
        let dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = (dist * 180) / Math.PI
        dist = dist * 60 * 1.1515
        if (unit === 'K') {
          dist = dist * 1.609344
        }
        if (unit === 'N') {
          dist = dist * 0.8684
        }
        return dist.toFixed(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.location-error {
  color: red;
}
.button {
  position: relative;
  padding-left: 35px;
  background-color: $white;
  color: $black;
}
.map-wrapper {
  @include grid-container-responsive();
  height: 80vh;
  margin-top: 20px;

  .sidebar {
    @include grid-item(4, 9);
    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }
  ::v-deep .mgl-map-wrapper {
    @include grid-item(8, 1);
    @include media($mobile) {
      @include grid-item(6, 1);
      height: 40vh;
    }
  }
  .listings {
    position: relative;
    height: 80vh;
    overflow: auto;
    @include media($mobile) {
      height: 40vh;
    }
  }
}
.marker-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 7px;
  top: 6px;
  background-image: url(#{$icons-path}marker.svg);
  background-size: 28px;

  .active & {
    animation: rotate 1s linear;
    animation-fill-mode: forwards;
  }

  @keyframes rotate {
    0% {
      transform: rotateY(0deg);
    }
    49% {
      background-image: url(#{$icons-path}marker.svg);
    }
    50% {
      background-image: url(#{$icons-path}marker-active.svg);
    }
    100% {
      transform: rotateY(180deg);
      background-image: url(#{$icons-path}marker-active.svg);
    }
  }
}
.filters {
  @include grid-container-responsive;
  .button-wrapper {
    @include grid-item(4, 1);
    @include media($medium) {
      @include grid-item(12, 1);
      margin-bottom: 30px;
      text-align: center;
    }
    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }

  .filter-label {
    @include grid-item(1, 8);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media($medium) {
      @include grid-item(4, 1);
      margin-bottom: 10px;
    }
    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }

  .type {
    @include grid-item(2, 9);
    @include media($medium) {
      @include grid-item(4, 5);
    }
    @include media($mobile) {
      @include grid-item(6, 1);
      margin-bottom: 10px;
    }
  }
  .country {
    @include grid-item(2, 11);
    @include media($medium) {
      @include grid-item(4, 9);
    }
    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }
  .city {
    display: none;
    @include grid-item(2, 11);
    @include media($medium) {
      @include grid-item(4, 9);
    }
    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }

  // select {
  //   height: 60px;
  //   @include fontsize(15);
  //   padding: 20px;
  // }
}
.v-select {
  background-color: $white;

  &.vs--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.vs--open {
    ::v-deep {
      .vs__open-indicator {
        transform: rotate(180deg) scale(0.7);
      }
    }
  }

  ::v-deep {
    .vs__open-indicator {
      transform: scale(0.7);
    }
    .vs__clear {
      transform: scale(0.8);
    }
    .vs__selected,
    .vs__dropdown-toggle {
      height: 40px;
      margin-top: 0;
      margin-bottom: 0;
    }
    ::-webkit-input-placeholder {
      opacity: 0.3;
    }

    :-ms-input-placeholder {
      opacity: 0.3;
    }

    ::placeholder {
      opacity: 0.3;
    }
    li {
      padding-left: 8px;
    }
  }
}

ul {
  .error {
    grid-template-columns: none;
    text-align: center;
    border-bottom: none;
    @include fontsize(15);

    .status {
      font-weight: 500;
    }
    .message {
      margin-top: 20px;
    }
    a {
      text-decoration: underline;
    }
  }

  li {
    border-bottom: 1px solid $line-color;
    @include fontsize(15);

    @include media($mobile) {
      padding: 10px 0;
    }

    .store-button {
      cursor: pointer;
    }

    &:last-child {
      border-bottom: none;
    }

    &:not(.labels) {
      .title {
        @include fontsize(20);
        font-weight: 500;
        margin-top: -5px;
      }
      .website {
        text-decoration: underline;
        opacity: 0.2;
        transition: opacity 100ms;

        &:hover {
          opacity: 1;
        }
      }
      .distance {
        text-decoration: underline;
      }
    }

    @include media($mobile) {
      @include grid-item(6);
    }

    &.labels {
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      border-bottom: 1px solid $black;
      padding-bottom: 15px;
      letter-spacing: 0.91px;

      @include media($mobile) {
        display: none;
      }
    }
  }

  &.map {
    li {
      padding: 20px 15px 17px 15px;
      &.selected {
        background-color: $white;
      }
    }
  }

  &.list {
    @include grid-container-responsive;
    @include fluid-prop(padding-top, 20px, 50px);
    li {
      padding: 30px 0 27px 0;
      @include grid-container-responsive(false);
      @include grid-item(12);
      .title {
        @include grid-item(4, 1);
        @include media($medium) {
          @include grid-item(3, 1);
        }
        @include media($mobile) {
          @include grid-item(6, 1);
        }
      }
      .address {
        @include grid-item(4, 5);
        @include media($medium) {
          @include grid-item(4, 4);
        }
        @include media($mobile) {
          @include grid-item(6, 1);
        }
      }
      .phone {
        @include grid-item(2, 9);
        @include media($medium) {
          @include grid-item(3, 8);
        }
        @include media($mobile) {
          @include grid-item(6, 1);
        }
      }
      .website {
        @include grid-item(1, 11);
        @include media($medium) {
          @include grid-item(1, 11);
        }
        @include media($mobile) {
          @include grid-item(6, 1);
        }
      }
      .distance {
        text-align: right;
        @include grid-item(1, 12);
        @include media($medium) {
          @include grid-item(1, 12);
        }
        @include media($mobile) {
          @include grid-item(1, 6);
          grid-row: 1;
        }
      }

      &.with-distance {
        .title,
        .address,
        .phone,
        .website {
          @include media($mobile) {
            @include grid-item(5, 1);
          }
        }
      }
    }
  }
}
</style>
