<template>
  <fragment>
    <Base :style="[styles, {'--button-color': data.button.color || '#5e5d5d'}]">
    <div class="wrapper">
      <div :style="layout" :class="{centered: data.button.center}">
        <a v-if="data.button.url.target === '_blank'" class="btn" target="_blank" :href="data.button.url.url">{{ data.button.url.title }}</a>
        <nuxt-link v-else :to="getUrl(data.button.url.url)" class="btn">{{ data.button.url.title }}</nuxt-link>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import utils from '@/mixins/utils'
export default {
  name: 'Button',

  mixins: [utils],

  components: {
    Base
  },
  extends: Base,

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    layout() {
      if (this.data.button.custom_layout) {
        return this.grid(this.data.button.columns)
      } else {
        return this.grid('3,10')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @include grid-container-responsive;
}
.centered {
  text-align: center;
}
.btn {
  display: inline-block;
  color: var(--button-color);
  padding: 10px 15px;
  border: 1px solid var(--button-color);
  transition: all 200ms;
  background-color: transparent;

  &:hover {
    background-color: var(--button-color);
    color: $white;
  }
}
</style>
