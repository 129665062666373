<template>
  <div :class="{open: open}" class="group">
    <div class="group-name" @click="toggle"><h3 v-html="data.name"></h3></div>
    <transition name="fade">
      <div class="text" key="text" v-show="open">
        <div class="description" v-html="data.description"></div>
        <div class="group-items">
          <router-link class="item" v-for="(item, index) in data.posts" :key="'group-'+index" :to="{ path: getUrl(typeof item.url === 'string' ? item.url : typeof item.post_name === 'string' ? item.post_name : '/'), query: { variant: item.color_code } }">
            <img v-if="item.image" :src="item.image[0]">
            <div v-if="!item.color_code">{{ item.post_title }}</div>
            <div v-else>{{ item.color_code }}</div>
            <div v-if="item.brand" :class="item.post_type !== 'material' ? 'brand' : null" v-html="item.brand.name"></div>
          </router-link>
          <div v-if="data.posts.length === 0">No items</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    openAsDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: this.openAsDefault
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    }
  }

}
</script>

<style lang="scss" scoped>
.accordion-item {
  border-bottom: 1px solid $line-color;
}
.group-name {
  @include fontsize(15);
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    @include fontsize(20);
    font-weight: 500;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 2px);
    font-size: 150%;
    transform: translateY(-50%);
    transition: 200ms ease-in-out;
    transform-origin: center;
    background-image: url("~/assets/img/arrow-right.png");
    background-size: 18px;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    opacity: 0.5;

    .open & {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
.group {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $line-color;
}
.brand {
  font-style: italic;
}
.description {
  max-width: 700px;
  padding-bottom: 30px;
  @include fontsize(20);
}
.group-items {
  @include fluid-prop(padding-top, 10px, 20px);
  @include fluid-prop(padding-bottom, 10px, 20px);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;

  @include media($medium) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include media($mobile) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.text {
  padding: 10px 0;
}
.toggle {
  @include fontsize(15);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
