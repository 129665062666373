<template>
  <fragment>
    <Base v-if="!imageInText" :style="[styles]">
    <div
      v-vpshow="animation"
      :class="{ wrapper: data.singleimage.size !== 'full' && !imageInText }"
      class="image-wrapper"
    >
      <Img
        :show-caption="data.singleimage.show_caption"
        :caption-align="data.singleimage.caption_align"
        :layout="layout"
        :columns="
          data.singleimage.custom_layout
            ? data.singleimage.columns
            : '3,10'
        "
        :data="data.singleimage.image"
        :enable="data.singleimage.zoom"
        :no-scale="data.singleimage.no_scale"
        :data-size="!imageInText ? data.singleimage.size : null"
        :data-position="data.singleimage.position"
        :data-caption-below="data.singleimage.caption_below"
      ></Img>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import Img from '@/components/Img'
import utils from '@/mixins/utils'

export default {
  name: 'Singleimage',

  components: {
    Img,
    Base
  },
  extends: Base,
  mixins: [utils],
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    layout() {
      if (this.data.singleimage.custom_layout) {
        return this.grid(this.data.singleimage.columns)
      } else if (
        this.$store.state.moduleOptions.layout_image &&
        this.$store.state.moduleOptions.layout_image.custom_layout
      ) {
        return this.grid(this.$store.state.moduleOptions.layout_image.columns)
      } else {
        return this.grid('3,10')
      }
    },
    animation() {
      if (this.data.singleimage.size === 'full') {
        return {
          animation: 'fadein'
        }
      } else {
        return null
      }
    },
    imageInText() {
      if (
        this.next &&
        (this.data.singleimage.size === 'small' || this.data.singleimage.wrap)
      ) {
        if (this.next.acf_fc_layout === 'Wysiwyg') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-wrapper {
  background-color: inherit;
}

.image {
  ::v-deep figure {
    @include grid-container();

    // @include media($medium) {
    //   @include grid-container(9);
    // }

    @include media($mobile) {
      @include grid-container(6);
    }
  }
}
</style>
