<template>
  <fragment>
    <Base>
    <div class="product-viewer" :class="{zoomed: zoom}">
      <div class="wrapper">
        <div class="panel">

          <template v-if="data.productviewer.variants.length > 1">
            <div class="label">{{ data.productviewer.variants_label || 'Variant' }}</div>
            <v-select
              v-model="variant"
              class="variant"
              :options="Object.values(data.productviewer.variants)"
              placeholder="Variant"
              label="title"
              :clearable="false"
              :searchable="false"
              @input="changeVariant"
            >
            </v-select>
          </template>

          <template v-if="data.productviewer.base && variant.base">
            <div class="label">{{ variant.base_label || data.productviewer.base_label || 'Base' }}</div>
            <v-select
              v-model="baseMaterial"
              class="base"
              :options="Object.values(data.productviewer.base)"
              placeholder="Base"
              index="slug"
              label="name"
              :clearable="false"
              :searchable="false"
              :style="{'--selected-image': baseMaterial ? 'url(' + baseMaterial.image[0] + ')' : null}"
              @input="changeBase"
            >
              <template slot="option" slot-scope="option">
                <img :src="getCDN(option.image[0])" />
                {{ option.name.replace('&amp;', '&') }}
              </template>
            </v-select>
          </template>

          <template v-if="data.productviewer.shell && variant.shell">
            <div class="label">{{ variant.shell_label || data.productviewer.shell_label || 'Shell' }}</div>
            <v-select
              v-model="shellMaterial"
              class="shell"
              :options="Object.values(data.productviewer.shell)"
              placeholder="Shell"
              index="slug"
              label="name"
              :clearable="false"
              :searchable="false"
              :style="{'--selected-image': shellMaterial ? 'url(' + shellMaterial.image[0] + ')' : null}"
              @input="changeBase"
            >
              <template slot="option" slot-scope="option">
                <img :src="getCDN(option.image[0])" />
                {{ option.name.replace('&amp;', '&') }}
              </template>
            </v-select>
          </template>

          <template v-if="data.productviewer.fittings">
            <div class="label">{{ variant.fittings_label || data.productviewer.fittings_label || 'Fittings' }}</div>
            <v-select
              v-model="fittingsMaterial"
              class="fittings"
              :options="Object.values(data.productviewer.fittings)"
              placeholder="Fittings"
              index="slug"
              label="name"
              :clearable="false"
              :searchable="false"
              :style="{'--selected-image': fittingsMaterial ? 'url(' + fittingsMaterial.image[0] + ')' : null}"
              @input="changeBase"
            >
              <template slot="option" slot-scope="option">
                <img :src="getCDN(option.image[0])" />
                {{ option.name.replace('&amp;', '&') }}
              </template>
            </v-select>
          </template>

          <template v-if="variant.upholstery">
          <div class="label">{{ variant.upholstery_label || data.productviewer.upholstery_label || 'Upholstery' }}</div>
          <div class="tabs">
            <div
              v-for="(tab, index) in tabLabels"
              :key="tab"
              class="tab"
              :class="activeTab === index ? 'active' : null"
              @click="() => {activeTab = index}"
            >
              {{ tab }}
            </div>
          </div>
          <div ref="options1" class="options">
            <Transition name="fade" mode="out-in">
              <div v-if="activeTab === 0" :key="0">
                <v-select
                  v-if="brands"
                  v-model="brand"
                  :searchable="false"
                  class="brand"
                  :options="sortArray(Object.values(brands))"
                  placeholder="Brand"
                  index="term_id"
                  label="name"
                  :clearable="false"
                  @input="changeBrand"
                />
                <v-select
                  v-if="brand"
                  v-model="brandChild"
                  :searchable="false"
                  class="brand-child"
                  :options="sortArray(selectedBrandChildren)"
                  placeholder="Product Line"
                  index="term_id"
                  label="name"
                  :clearable="false"
                />
                <Swatches :data="brandSwatches" @changeColor="changeUpholstery" />
              </div>
              <div v-if="activeTab === 1" :key="1">
                <v-select
                  v-if="colors"
                  v-model="color"
                  :searchable="false"
                  class="color"
                  :options="sortArray(Object.values(colors))"
                  placeholder="Color"
                  index="term_id"
                  label="name"
                  :clearable="false"
                />
                <Swatches :data="colorSwatches" @changeColor="changeUpholstery" />
              </div>
              <div v-if="activeTab === 2" :key="2">
                <v-select
                  v-if="qualities"
                  v-model="quality"
                  :searchable="false"
                  class="quality"
                  :options="sortArray(Object.values(qualities))"
                  placeholder="Quality"
                  index="term_id"
                  label="name"
                  :clearable="false"
                />
                <Swatches :data="qualitySwatches" @changeColor="changeUpholstery" />
              </div>
            </Transition>
          </div>
          </template>
          <template v-if="variant.secondary_upholstery">
            <div class="label secondary">
              {{ variant.secondary_upholstery_label || data.productviewer.secondary_upholstery_label || 'Upholstery' }}
              <label class="switch" v-if="variant.secondary_upholstery_optional">
                <input v-model="secondaryUpholsteryEnabled" type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
            <div v-if="secondaryUpholsteryEnabled" class="tabs">
              <div
                v-for="(tab, index) in tabLabels"
                :key="tab"
                class="tab"
                :class="activeTabSecondary === index ? 'active' : null"
                @click="() => {activeTabSecondary = index}"
              >
                {{ tab }}
              </div>
            </div>
            <div v-if="secondaryUpholsteryEnabled" ref="options2" class="options">
              <Transition name="fade" mode="out-in">
                <div v-if="activeTabSecondary === 0" :key="0">
                  <v-select
                    v-if="brands"
                    v-model="brandSecondary"
                    :searchable="false"
                    class="brand"
                    :options="sortArray(Object.values(brands))"
                    placeholder="Brand"
                    index="term_id"
                    label="name"
                    :clearable="false"
                    @input="changeBrandSecondary"
                  />
                  <v-select
                    v-if="brand"
                    v-model="brandChildSecondary"
                    :searchable="false"
                    class="brand-child"
                    :options="sortArray(selectedBrandChildren)"
                    placeholder="Product Line"
                    index="term_id"
                    label="name"
                    :clearable="false"
                  />
                  <Swatches :isPrimary="false" :data="brandSwatchesSecondary" @changeColor="changeUpholsterySecondary" />
                </div>
                <div v-if="activeTabSecondary === 1" :key="1">
                  <v-select
                    v-if="colors"
                    v-model="colorSecondary"
                    :searchable="false"
                    class="color"
                    :options="sortArray(Object.values(colors))"
                    placeholder="Color"
                    index="term_id"
                    label="name"
                    :clearable="false"
                  />
                  <Swatches :isPrimary="false" :data="colorSwatchesSecondary" @changeColor="changeUpholsterySecondary" />
                </div>
                <div v-if="activeTabSecondary === 2" :key="2">
                  <v-select
                    v-if="qualities"
                    v-model="qualitySecondary"
                    :searchable="false"
                    class="quality"
                    :options="sortArray(Object.values(qualities))"
                    placeholder="Quality"
                    index="term_id"
                    label="name"
                    :clearable="false"
                  />
                  <Swatches :isPrimary="false" :data="qualitySwatchesSecondary" @changeColor="changeUpholsterySecondary" />
                </div>
              </Transition>
            </div>
          </template>
          <ProductPDF 
            v-if="currentImage"
            :image="currentImage" 
            :upholstery="variant.upholstery ? upholstery : null"
            :upholsterySecondary="variant.secondary_upholstery ? upholsterySecondary : null"
            :baseMaterial="variant.base ? baseMaterial : null"
            :shellMaterial="variant.shell ? shellMaterial : null"
            :variant="variant"
            :fittingsMaterial="fittingsMaterial"
            ></ProductPDF>
        </div>
        <div class="label-wrapper">
          <!--a class="download" :href="currentImage" target="_blank" download>Download image</a-->
        </div>
        <div
          class="cylindoViewerWrapper"
          :id="'cylindo-' + _uid"
          v-waypoint="{ active: !isAnimated, callback: onWaypoint}"
        ></div>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'
import Base from './Base'
import Swatches from '@/components/Swatches'
import ProductPDF from '@/components/ProductPDF'
import utils from '@/mixins/utils'
export default {
  name: 'Productviewer',

  components: {
    Swatches,
    Base,
    ProductPDF
  },

  extends: Base,

  mixins: [smoothReflow, utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    parent: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      viewerInstance: null,
      isAnimated: false,
      zoom: false,
      activeTab: 0,
      activeTabSecondary: 0,
      tabLabels: ['Brand', 'Colour', 'Quality'],
      variant: this.data.productviewer.variants
        ? this.data.productviewer.variants[0]
        : null,
      brand: null,
      brandSecondary: null,
      brandChild: null,
      brandChildSecondary: null,
      color: null,
      colorSecondary: null,
      quality: null,
      qualitySecondary: null,
      showAll: false,
      currentImage: null,
      secondaryUpholsteryEnabled: false,
      upholstery: this.data.productviewer.materials
        ? this.data.productviewer.materials[0]
        : null,
      upholsterySecondary: this.data.productviewer.materials
        ? this.data.productviewer.materials[1]
        : null,
      baseMaterial: this.data.productviewer.base
        ? this.data.productviewer.base[0]
        : null,
      shellMaterial: this.data.productviewer.shell
        ? this.data.productviewer.shell[0]
        : null,
      fittingsMaterial: this.data.productviewer.fittings
        ? this.data.productviewer.fittings[0]
        : null,
      brands: this.uniqueArray(
        this.data.productviewer.materials.map(material => material.brand)
      ),
      brandChildren: this.uniqueArray(
        this.data.productviewer.materials.map(material => material.brand_child)
      ),
      colors: this.uniqueArray(
        this.data.productviewer.materials.map(material => material.color)
      ),
      qualities: this.uniqueArray(
        this.data.productviewer.materials.map(material => material.quality)
      )
    }
  },

  watch: {
    secondaryUpholsteryEnabled() {
      this.changeFeatures()
    }
  },

  computed: {
    selectedBrandChildren() {
      return [
        ...new Set(
          this.brandChildren.filter(
            material => material.parent === this.brand.term_id
          )
        )
      ]
    },
    brandSwatches() {
      if (this.brandChild) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material =>
                material.brand_child.term_id === this.brandChild.term_id
            )
          )
        ]
      } else if (this.brand) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material => material.brand.term_id === this.brand.term_id
            )
          )
        ]
      } else {
        return []
      }
    },
    brandSwatchesSecondary() {
      if (this.brandChildSecondary) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material =>
                material.brand_child.term_id ===
                this.brandChildSecondary.term_id
            )
          )
        ]
      } else if (this.brandSecondary) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material => material.brand.term_id === this.brandSecondary.term_id
            )
          )
        ]
      } else {
        return []
      }
    },
    colorSwatches() {
      if (this.color) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material => material.color.term_id === this.color.term_id
            )
          )
        ]
      }
      return this.data.productviewer.materials
    },
    colorSwatchesSecondary() {
      if (this.color) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material => material.color.term_id === this.colorSecondary.term_id
            )
          )
        ]
      }
      return this.data.productviewer.materials
    },
    qualitySwatches() {
      if (this.quality) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material => material.quality.term_id === this.quality.term_id
            )
          )
        ]
      }
      return this.data.productviewer.materials
    },
    qualitySwatchesSecondary() {
      if (this.qualitySecondary) {
        return [
          ...new Set(
            this.data.productviewer.materials.filter(
              material =>
                material.quality.term_id === this.qualitySecondary.term_id
            )
          )
        ]
      }
      return this.data.productviewer.materials
    },
    features() {
      const features = []

      if (this.data.productviewer.fixed_features) {
        this.data.productviewer.fixed_features.forEach(element => {
          features.push(encodeURI(element.name))
          features.push(encodeURI(element.value))
        })
      }
      if (this.variant.fixed_features) {
        this.variant.fixed_features.forEach(element => {
          features.push(encodeURI(element.name))
          features.push(encodeURI(element.value))
        })
      }
      if (this.baseMaterial && this.variant.base) {
        features.push(this.data.productviewer.base_id || 'BASE')
        features.push(encodeURI(this.baseMaterial.cylindo_id))
      }
      if (this.shellMaterial && this.variant.shell) {
        features.push(this.data.productviewer.shell_id || 'SHELL')
        features.push(encodeURI(this.shellMaterial.cylindo_id))
      }
      if (this.fittingsMaterial) {
        features.push(this.data.productviewer.fittings_id || 'FITTINGS')
        features.push(encodeURI(this.fittingsMaterial.cylindo_id))
      }
      if (this.upholstery && this.variant.upholstery) {
        features.push(this.data.productviewer.upholstery_id || 'UPHOLSTERY')
        features.push(encodeURI(this.upholstery.cylindo_id))
        if (this.variant.secondary_upholstery_same) {
          features.push(this.data.productviewer.secondary_upholstery_id)
          features.push(encodeURI(this.upholstery.cylindo_id))
        }
      }
      if (
        this.secondaryUpholsteryEnabled &&
        this.variant.secondary_upholstery
      ) {
        features.push(this.data.productviewer.secondary_upholstery_id)
        features.push(encodeURI(this.upholsterySecondary.cylindo_id))
      }
      if (
        !this.secondaryUpholsteryEnabled &&
        this.variant.secondary_upholstery
      ) {
        features.push(this.data.productviewer.secondary_upholstery_id)
        features.push(encodeURI(this.upholstery.cylindo_id))
      }

      return features
    }
  },

  mounted() {
    this.$smoothReflow({
      el: this.$refs.options,
      transition: 'height .5s ease-in-out',
      transitionEvent: {
        selector: 'li',
        propertyName: 'opacity'
      }
    })
    const self = this
    this.$nextTick(() => {
      this.updateDropdowns()
      const options = {
        accountID: 4976,
        productCode: this.variant.product_id,
        features: this.features,
        containerID: 'cylindo-' + this._uid,
        format: 'png',
        thumbs: false,
        startFrame: 30,
        backgroundColor: 'F6F6F6',
        zoomBackgroundColor: 'F6F6F6',
        size: 1000,
        fullscreen: false,
        progressBar: false,
        maxZoom: 'mixed'
      }
      if (process.client && window.cylindo) {
        window.cylindo.on('ready', function() {
          self.viewerInstance = window.cylindo.viewer.create(options)
          self.viewerInstance.on('instance:threesixty:end', self.updateImageUrl)
          self.viewerInstance.on(
            'instance:firstframe:ready',
            self.updateImageUrl
          )
          self.viewerInstance.on('instance:zoom:enter', self.zoomEnter)
          self.viewerInstance.on('instance:zoom:exit', self.zoomExit)
        })
      }
    })
  },

  methods: {
    updateImageUrl() {
      this.currentImage =
        this.viewerInstance.getCurrentImageUrl(2048) + '&encoding=png'
    },
    zoomEnter() {
      this.zoom = true
    },
    zoomExit() {
      this.zoom = false
    },
    sortArray(arr) {
      arr.sort(function(a, b) {
        if (a && a.name && b && b.name) {
          const textA = a.name.toUpperCase()
          const textB = b.name.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        }
      })
      return arr
    },
    uniqueArray(a) {
      const arr = [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
      arr.forEach(element => {
        element.name = element.name.replace('&amp;', '&')
      })
      return arr
    },
    onWaypoint() {
      if (this.viewerInstance && !this.isAnimated)
        this.viewerInstance.rotate(360, 1000)
      this.isAnimated = true
    },
    changeVariant() {
      if (
        this.variant.secondary_upholstery &&
        !this.variant.secondary_upholstery_optional
      ) {
        this.secondaryUpholsteryEnabled = true
      }
      if (this.viewerInstance) {
        this.viewerInstance.setProduct(this.variant.product_id)
      }
      this.changeFeatures()
    },
    changeBase() {
      this.changeFeatures()
    },
    changeUpholstery(item) {
      this.upholstery = item
      this.changeFeatures()
      this.updateDropdowns()
    },
    changeUpholsterySecondary(item) {
      this.upholsterySecondary = item
      this.changeFeatures()
      this.updateDropdowns()
    },
    changeFeatures() {
      if (this.viewerInstance) {
        this.viewerInstance.setFeatures(this.features)
      }
    },
    changeBrand() {
      this.brandChild = null
    },
    changeBrandSecondary() {
      this.brandChildSecondary = null
    },
    updateDropdowns() {
      this.brand = this.upholstery.brand
      this.brandChild = this.upholstery.brand_child
      this.quality = this.upholstery.quality
      this.color = this.upholstery.color
      this.brandSecondary = this.upholsterySecondary.brand
      this.brandChildSecondary = this.upholsterySecondary.brand_child
      this.qualitySecondary = this.upholsterySecondary.quality
      this.colorSecondary = this.upholsterySecondary.color
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.product-viewer {
  background: $white;
}
.wrapper {
  @include grid-container-responsive;
  position: relative;
}
.label-wrapper {
  @include grid-item(6, 7);
  position: relative;
  z-index: 2;
  pointer-events: none;
  @include media($mobile) {
    @include grid-item(6, 1);
  }
}
.panel {
  @include grid-item(3, 1);
  transition: all 300ms $easeOutExpo;
  min-height: 640px;
  padding: 50px 0;

  @include media($small) {
    min-height: 500px;
  }

  @include media($mobile) {
    @include grid-item(6, 1);
    order: 2;
    padding: 20px 0;
  }

  .zoomed & {
    transform: translateX(-20%);
    opacity: 0;
    @include media($mobile) {
      opacity: 1;
      transform: none;
    }
  }
}
.label {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  @include fontsize(20);

  &.secondary {
    @include fluid-prop(margin-top, 20px, 50px);
    display: flex;
    justify-content: space-between;
  }
}
.tabs {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .tab {
    display: inline-block;
    @include fontsize(15);
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      text-decoration: underline;
    }
  }
}
.download {
  @include font('label');
  position: absolute;
  right: 0;
  bottom: 50px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  pointer-events: all;
  transition: all 200ms;
  .zoomed & {
    opacity: 0;
    pointer-events: none;
  }
  @include media($mobile) {
    margin-top: 10px;
    position: relative;
    right: auto;
    bottom: auto;
    display: block;
  }
}
.cylindoViewerWrapper {
  height: 100%;
  max-height: 640px;
  position: absolute;
  width: 70%;
  max-width: 1130px;
  left: 30%;
  transition: all 300ms $easeOutExpo;
  padding: 50px 0;
  z-index: 1;

  .zoomed & {
    width: 100%;
    max-height: 100%;
    padding: 0;
    left: 0;
    max-width: none;
  }
  @include media($small) {
    max-height: 500px;
  }

  @include media($mobile) {
    position: relative;
    @include grid-item(6, 1);
    order: 1;
    left: 0;
    padding: 50px 0;
    min-height: 400px;
    width: 100%;
  }

  ::v-deep {
    .cylindo-no-feature {
      display: none !important;
    }
    .cylindo-drag-tooltip {
      @include font('label');
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.5);
      background: transparent;
      font-family: brandon-grotesque;
      bottom: 0;
      transition: all 200ms;
      .zoomed & {
        bottom: 15px;
      }

      &:before {
        content: '';
        width: 20px;
        height: 22px;
        background: url('~assets/img/hand.png');
        position: absolute;
        left: 50%;
        top: 0;
        background-size: 20px 22px;
        transform: translate(-50%, -30px);
        transition: all 200ms;

        .zoomed & {
          opacity: 0;
        }
      }

      .left,
      .right {
        position: absolute;
        top: 0;
        transition: all 200ms;
        opacity: 0.7;
        .zoomed & {
          opacity: 0;
        }
      }

      .left {
        left: 50%;
        transform: translate(-120%, -30px) scale(0.5);
      }
      .right {
        right: 50%;
        transform: translate(120%, -30px) scale(0.5);
      }
    }
  }
}
.v-select {
  margin-top: 10px;
  background-color: #fff;

  &.vs--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.vs--open {
    ::v-deep .vs__open-indicator {
      transform: rotate(180deg) scale(0.7);
    }
  }

  &.variant {
    @include fluid-prop(margin-bottom, 20px, 50px);
  }

  &.fittings,
  &.base,
  &.shell {
    @include fluid-prop(margin-bottom, 20px, 50px);
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    ::v-deep li {
      padding: 5px;
    }
    .vs__selected-options {
      padding: 0 !important;
    }
    .vs__selected {
      padding-left: 45px;
      margin-left: 0;
      background-position: 5px;
      background-size: 30px;
      background-repeat: no-repeat;
      background-image: var(--selected-image);
    }
  }

  ::v-deep {
    .vs__open-indicator {
      transform: scale(0.7);
    }
    .vs__clear {
      transform: scale(0.8);
    }
    .vs__selected,
    .vs__dropdown-toggle {
      height: 40px;
      margin-top: 0;
      margin-bottom: 0;
    }
    ::-webkit-input-placeholder {
      opacity: 0.3;
    }

    :-ms-input-placeholder {
      opacity: 0.3;
    }

    ::placeholder {
      opacity: 0.3;
    }
    li {
      padding-left: 8px;
      display: flex;
      align-items: center;
      background-color: #ffffff;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:focus + .slider {
    box-shadow: none;
  }

  input:checked + .slider:before {
    transform: translateX(30px);
    background-color: $black;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}
</style>
