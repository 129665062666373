<template>
  <div
    v-vpshow
    :data-size="data.size"
    :class="[
      { 'no-scale': data.no_scale }
    ]"
    :style="[videoStyle, layout, {paddingBottom: aspectRatio * 100 + '%'}]"
    class="video"
  >
    <no-ssr>
      <div ref="video">
        <!-- youtube iframe with progressive enhancement (extra queries after the url to optimize the embed) -->
        <vue-plyr v-if="provider() === 'youtube'">
          <div
            :ref="'player'"
            v-bind="playerOptions"
            :data-plyr-config="playerConfig"
            class="plyr__video-embed"
            @play="play"
            @ready="playerReady"
          >
            <iframe
              :src="
                playerSrc() +
                  '?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'
              "
              allowfullscreen
              allowtransparency
              allow="autoplay"
            ></iframe>
          </div>
        </vue-plyr>
        <div
          v-else-if="provider() === 'vimeo' && !data.loop && !vimeoLoaded && !isiOS"
          class="plyr vimeo-placeholder"
        >
          <img :src="vimeoThumbnail" />
          <button
            type="button"
            class="plyr__control plyr__control--overlaid"
            data-plyr="play"
            @click="loadVimeo(true)"
          >
            <svg role="presentation" focusable="false">
              <use xlink:href="#plyr-play"></use></svg><span class="plyr__sr-only">Play</span>
          </button>
        </div>
        <vue-plyr
          v-else-if="provider() === 'vimeo' && !data.loop && vimeoLoaded && !isiOS"
        >
          <div
            :ref="'player'"
            v-bind="playerOptions"
            :data-plyr-config="playerConfig"
            class="plyr__video-embed"
            @play="play"
            @ready="playerReady"
          >
            <iframe
              :src="
                playerSrc() +
                  '?amp;byline=false&amp;portrait=false&amp;autoplay=' +
                  playerOptions.autoplay +
                  '&amp;autopause=0&amp;loop=' +
                  data.loop +
                  '&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media&amp;gesture=media'
              "
              allowfullscreen
              allowtransparency
              allow="autoplay"
            ></iframe>
          </div>
        </vue-plyr>
        <div v-else-if="provider() === 'vimeo' && isiOS" class="embed-container">
          <iframe
            :src="playerSrc()"
            width="100%"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe>
        </div>
        <vue-plyr v-else-if="provider() === 'file' && !data.autoplay">
          <video
            :ref="'player'"
            v-bind="playerOptions"
            :data-plyr-config="playerConfig"
            :poster="playerPoster()"
            playsinline
            @play="play"
            @ready="playerReady"
            @loadedmetadata="loadedmetadata"
          >
            <source :src="playerSrc()" :type="playerType()" />
          </video>
        </vue-plyr>
        <video
            v-else
            muted
            autoplay
            loop
            playsinline
          >
            <source :src="playerSrc()" :type="playerType()" />
          </video>
      </div>
    </no-ssr>
  </div>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'Vid',

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    layout: {
      type: [Object, Boolean],
      default: false
    }
  },

  data() {
    return {
      vimeoThumbnail: '',
      vimeoLoaded: true,
      // vimeoLoaded: this.data.autoplay,
      videoPlay: false,
      player: null,
      playerOptions: {
        controls: this.data.controls ? 'controls' : false,
        autoplay: this.data.autoplay ? 'autoplay' : false,
        loop: this.data.autoplay ? 'loop' : false,
        muted: this.data.autoplay ? 'muted' : false,
        autobuffer: 'autobuffer',
        preload: 'auto'
      },
      sourceChanged: false,
      playing: false,
      playerConfig: !this.data.loop
        ? '{"disableContextMenu": false, "muted": ' +
          this.data.autoplay +
          ', "controls": ["play-large", "play", "current-time", "duration", "progress", "fullscreen", "captions"], "storage": false, "captions": {enabled: true}}'
        : '{"disableContextMenu": false, "muted": ' +
          this.data.autoplay +
          ', "controls":false, "clickToPlay": false, "fullscreen":{"enabled":false}, "storage": false, "autopause": false}',
      scrollpos: 0,
      lastpos: 0
    }
  },

  computed: {
    aspectRatio() {
      return (
        this.data.file.height /
        this.data.file.width
      )
    },
    videoStyle() {
      return this.$store.getters.videoStyle
    },

    isiOS() {
      const MobileDetect = require('mobile-detect')
      const md = new MobileDetect(window.navigator.userAgent)

      if (md.mobile() || md.tablet()) {
        return true
      } else {
        return false
      }
    }
  },

  destroyed() {
    // if (!this.data.loop) {
    //   this.$store.commit('videos/unRegisterPlayer', {
    //     data: this
    //   })
    // }
  },

  created() {
    if (!this.data.file && this.data.url.includes('vimeo')) {
      this.getVimeoThumbnail()
    }
    // if (!this.data.loop && !this.player) {
    //   this.$store.commit('videos/registerPlayer', {
    //     data: this
    //   })
    // }

    const MobileDetect = require('mobile-detect')
    const md = new MobileDetect(window.navigator.userAgent)
    if (md.mobile()) {
      this.vimeoLoaded = true
      this.videoPlay = false
    }
  },

  mounted() {
    // if (!this.data.loop && this.data.autoplay) {
    //   const controller = new this.$ScrollMagic.Controller()
    //   const _this = this
    //   new this.$ScrollMagic.Scene({
    //     triggerElement: this.$el,
    //     triggerHook: 'onEnter',
    //     reverse: false
    //   })
    //     .on('start', function() {
    //       if (_this.player) {
    //         _this.player.play()
    //       } else if (_this.$refs.player) {
    //         _this.$refs.player.play()
    //       } else {
    //         _this.videoPlay = true
    //         _this.vimeoLoaded = true
    //       }
    //     })
    //     .addTo(controller)
    //   new this.$ScrollMagic.Scene({
    //     triggerElement: this.$el,
    //     triggerHook: 'onLeave',
    //     reverse: false
    //   })
    //     .on('start', function() {
    //       if (_this.player) {
    //         // _this.player.pause()
    //       }
    //     })
    //     .addTo(controller)
    // }
  },

  methods: {
    loadVimeo(triggerPlay) {
      if (triggerPlay) {
        this.videoPlay = true
      }
      this.vimeoLoaded = true
    },
    playVimeo() {
      this.videoPlay = true
    },
    scrollPlay() {
      if (this.player) {
        let seek = this.player.duration * this.scrollpos
        if (seek > this.player.duration) {
          seek = this.player.duration
        }
        this.player.currentTime = seek
        this.lastpos = this.scrollpos
      }
    },
    loadedmetadata(event) {
      // "Scroll on play"
      if (this.data.effect === 'scroll') {
        const controller = new this.$ScrollMagic.Controller()
        new this.$ScrollMagic.Scene({
          triggerElement: this.$refs.player,
          triggerHook: 'onEnter',
          duration: window.innerHeight + this.$refs.player.clientHeight
        })
          .on('progress', e => {
            this.scrollpos = e.progress
            this.scrollPlay()
          })
          .addTo(controller)
      }

      // "Scroll on stay"
      if (this.data.effect === 'scroll_fixed') {
        const triggerHook =
          (window.innerHeight - this.$refs.video.clientHeight) /
          window.innerHeight
        const controller = new this.$ScrollMagic.Controller()
        new this.$ScrollMagic.Scene({
          triggerElement: this.$refs.video,
          triggerHook: triggerHook * 0.5,
          duration: this.player.duration * 50
        })
          .setPin(this.$refs.video)
          .on('progress', e => {
            this.scrollpos = e.progress
            this.scrollPlay()
          })
          .addTo(controller)
      }
    },
    playerReady(event) {
      // Register only for global control if video is not looping
      if (!this.data.loop && !this.player) {
        this.player = event.detail.plyr
        this.player.toggleControls(false)
      }

      if (this.data.poster) {
        event.detail.plyr.poster = this.playerPoster()
      }

      if (this.data.poster_video) {
        let poster = null
        if (this.provider === 'file') {
          const path =
            event.path || (event.composedPath && event.composedPath())
          poster = path[2].childNodes[1].children[1]
        } else {
          const path =
            event.path || (event.composedPath && event.composedPath())
          poster = path[0].childNodes[1].childNodes[1]
        }
        poster.classList.add('is-video')
        poster.innerHTML =
          '<div class="video-poster"><video src="' +
          this.data.poster_video.url +
          '" muted loop autoplay playsinline></video></div>'
      }

      if (this.videoPlay) {
        this.player.play()
      }
      this.$emit('ready', event.detail.plyr)
    },
    play(event) {
      if (!this.data.loop && !this.player.fullscreen.active) {
        // this.$store.commit('videos/setActivePlayer', {
        //   data: this
        // })
        // this.player.toggleCaptions(true)
      }
    },
    playerSrc() {
      if (this.data.file) {
        return this.data.file.url
      } else {
        if (this.data.url.includes('vimeo')) {
          let id = this.data.url.substring(
            this.data.url.indexOf('vimeo.com/') + 10
          )
          if (id.indexOf('/') > -1) {
            const temp = id.split('/')
            id = temp[0]
          }
          const url = 'https://player.vimeo.com/video/' + id
          // if (this.playerOptions.autoplay && this.playerOptions.loop) {
          //   url += '&autoplay=1&autopause=0&loop=1'
          // }
          return url
        }
        if (this.data.url.includes('youtube')) {
          return this.data.url.replace('watch?v=', 'embed/')
        }
      }
    },
    getVimeoThumbnail: async function() {
      const url = 'https://vimeo.com/api/oembed.json?url=' + this.data.url
      const response = await Axios.get(url)
      let thumb = response.data.thumbnail_url
      // Remove size param to get full size
      thumb = thumb.substring(0, thumb.indexOf('_'))
      this.vimeoThumbnail = thumb
    },
    provider() {
      if (this.data.file) {
        return 'file'
      } else {
        if (this.data.url.includes('youtube')) {
          return 'youtube'
        }
        if (this.data.url.includes('vimeo')) {
          return 'vimeo'
        }
      }
    },
    playerType() {
      if (this.data.file) {
        return this.data.file.mime_type
      } else {
        if (this.data.url.includes('youtube')) {
          return 'youtube/video'
        }
        if (this.data.url.includes('vimeo')) {
          return 'vimeo/video'
        }
      }
    },
    playerPoster() {
      if (this.data.poster) {
        return this.data.poster.sizes.fullwidth
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../node_modules/plyr/src/sass/plyr';
.vimeo-placeholder {
  position: relative;
  button {
    display: block;
  }
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video {
  position: relative;
  &[data-hide-controls='true'] {
    pointer-events: none !important;
    .moodboard & {
      pointer-events: auto !important;
    }
    ::v-deep {
      .mejs__overlay,
      .mejs__container .mejs__controls {
        display: none !important;
      }
    }
  }
  > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
  ::v-deep {
    .plyr {
      min-width: 0;
      width: 100%;
    }
    video {
      position: relative;
      width: 100%;
    }
    .plyr__control.plyr__tab-focus,
    .plyr__control:hover,
    .plyr__control[aria-expanded='true'] {
      background: $white;

      svg {
        color: $black;
      }
    }
    [data-plyr='volume'] {
      display: none;
    }
    .plyr__tooltip,
    .plyr__control {
      box-shadow: none !important;

      // &[data-plyr="captions"] {
      //   display: none;
      // }

      .plyr__volume {
        flex: 0;
      }

      &[data-plyr='settings'] {
        display: none;
      }

      &[data-plyr='play'] {
        .icon--not-pressed {
          background-color: $white;
          -webkit-mask-position: -3px;
          mask-position: -3px;
          -webkit-mask-image: url(#{$icons-path}play.svg);
          mask-image: url(#{$icons-path}play.svg);
        }
        .icon--pressed {
          background-color: $white;
          -webkit-mask-position: -3px;
          mask-position: -3px;
          -webkit-mask-image: url(#{$icons-path}pause.svg);
          mask-image: url(#{$icons-path}pause.svg);
        }
      }
      &[data-plyr='fullscreen'] {
        .icon--not-pressed {
          background-color: $white;
          -webkit-mask-position: -3px;
          mask-position: -3px;
          -webkit-mask-image: url(#{$icons-path}fullscreen.svg);
          mask-image: url(#{$icons-path}fullscreen.svg);
        }
        .icon--pressed {
          background-color: $white;
          -webkit-mask-position: -3px;
          mask-position: -3px;
          -webkit-mask-image: url(#{$icons-path}fullscreen.svg);
          mask-image: url(#{$icons-path}fullscreen.svg);
        }
      }
    }
    .video-poster {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100%;
      video {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    .plyr--video .plyr__progress__buffer {
      box-shadow: none;
      color: transparent;
    }
    // .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
    //   padding-bottom: 100%;
    // }

    .plyr--video .plyr__control svg {
      filter: none;
    }

    .plyr--video .plyr__time {
      text-shadow: none;
    }

    .plyr video,
    .plyr__video-embed iframe {
      width: calc(100% + 1px);
      height: calc(100% + 1px);

      @at-root .no-scale#{&} {
        // width: auto !important;
        // max-width: 100%;
      }
    }

    .plyr--video,
    .plyr__video-wrapper {
      background: transparent;
    }

    .plyr__video-wrapper {
      width: 100%;
    }

    .plyr__control--overlaid {
      background: $black;
      border-radius: 0;
      padding: 15px 25px;

      svg {
        background-color: $white;
        -webkit-mask-position: -3px;
        mask-position: -3px;
        -webkit-mask-image: url(#{$icons-path}play.svg);
        mask-image: url(#{$icons-path}play.svg);
      }
    }

    .plyr {
      font-weight: inherit;
    }

    .plyr.plyr--stopped {
      .plyr__controls {
        opacity: 0;
        // pointer-events: none;
      }
      .plyr__poster.is-video {
        opacity: 1;
        background-image: none !important;
      }
    }

    .plyr__poster {
      background-size: cover;
      // pointer-events: none;
    }
    .plyr__captions {
      @include fontsize(25);

      @include media($mobile) {
        @include fontsize(15);
      }
    }

    .plyr--full-ui input[type='range'] {
      color: #eee;
    }

    .plyr__time--current,
    .plyr__time--duration {
      @include fontsize(20);
      line-height: 30px !important;
      font-weight: inherit;
    }
    .plyr__time--duration {
      opacity: 0.5;
      &:before {
        display: none;
      }
    }
    .mejs__fullscreen-button > button {
      -webkit-mask-position: -3px;
      mask-position: -3px;
      -webkit-mask-image: url(#{$icons-path}fullscreen.svg);
      mask-image: url(#{$icons-path}fullscreen.svg);
      background-color: $white;
    }

    .mejs__time-handle {
      width: 4px;
      height: 20px;
      border: none;
      background-color: $white;
      margin-top: -9px;
      margin-left: -2px;
    }
  }
}
</style>
