<template>
  <fragment>
    <Base :style="[styles]">
    <div v-vpshow :class="[!data.layout.stack ? 'stack' : '', {'no-padding': data.layout.remove_center_gutter}]" class="layout">
      <div :ref="'container'" class="container" :class="{'no-gutter': data.layout.remove_center_gutter}">
        <div
          v-for="(layer, index) in data.layout.layers"
          :key="index"
          :ref="'layer-' + index"
          :class="[
            layer.align,
            layer.hide_on_mobile ? 'mobile-hide' : '',
            layer.hide_on_desktop ? 'desktop-hide' : '',
            {full: layer.width === '0,13'}
          ]"
          class="layer"
          :style="[layout(index), padding(index), { zIndex: 999 - index }]"
        >
          <Vid v-if="layer.acf_fc_layout === 'videolayer'" :data="layer"></Vid>
          <Img
            v-if="layer.acf_fc_layout === 'imagelayer'"
            :show-caption="layer.show_caption ? true : false"
            :enable="layer.zoom"
            :caption-align="layer.caption_align"
            :data="layer.image"
          />
          <div
            v-if="
              layer.acf_fc_layout === 'displaylextlayer' ||
                layer.acf_fc_layout === 'textlayer' ||
                layer.acf_fc_layout === 'quotelayer'
            "
            class="content"
            :style="[bodyStyle]"
          >
            <p v-html="content(layer)" />
          </div>
        </div>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import { TweenMax, Linear } from 'gsap'
import Img from '@/components/Img'
import Vid from '@/components/Vid'
import Base from '@/components/modules/Base'
import utils from '@/mixins/utils'
let ScrollMagic
if (typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic')
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap')
}
export default {
  name: 'Layout',

  components: {
    Base,
    Img,
    Vid
  },
  extends: Base,

  mixins: [utils],

  data() {
    return {
      end: false
    }
  },

  computed: {
    bodyStyle() {
      return this.$store.getters.bodyStyle
    }
  },
  mounted() {
    if (this.data.layout.layers) {
      const self = this
      const controller = new ScrollMagic.Controller()
      this.data.layout.layers.forEach(function(value, index) {
        if (
          value.parallax_speed !== 0 &&
          (self.$mq !== 'mobile' && self.$mq !== 'tiny')
        ) {
          const vh = window.innerHeight
          const eh = self.$refs['layer-' + index][0].getBoundingClientRect().top
          let offset = 0
          if (eh < vh) {
            offset = vh - eh
          }
          new ScrollMagic.Scene({
            triggerElement: self.$refs['layer-' + index],
            triggerHook: 'onEnter',
            duration: '200%',
            offset: offset
          })
            .setTween(
              TweenMax.fromTo(
                self.$refs['layer-' + index],
                1,
                {
                  y: offset > 0 ? '0%' : value.parallax_speed / 2 + '%',
                  ease: Linear.easeNone
                },
                {
                  y:
                    offset > 0
                      ? -value.parallax_speed + '%'
                      : -(value.parallax_speed / 2) + '%',
                  ease: Linear.easeNone
                }
              )
            )
            .addTo(controller)
        }
      })
    }

    // var self = this
    // const controller = new ScrollMagic.Controller()
    // let timeline = new TimelineMax({force3D: true})
    // var scene = new ScrollMagic.Scene({
    //   triggerElement: self.$el,
    //   triggerHook: 'onEnter',
    //   duration: '200%'
    // })
    // this.data.layout.layers.forEach(function (value, index) {
    //   if (value.parallax && (self.$mq !== 'mobile' && self.$mq !== 'tiny')) {
    //     const vh = window.innerHeight
    //     const eh = self.$refs['layer-' + index][0].getBoundingClientRect().top
    //     let offset = 0
    //     if (eh < vh) {
    //       offset = vh - eh
    //     }
    //     timeline.fromTo(self.$refs['layer-' + index], 1, {
    //       y: offset > 0 ? '0%' : (value.parallax_speed / 2) + '%',
    //       ease: Linear.easeNone
    //     }, {
    //       y: offset > 0 ? -value.parallax_speed + '%' : -(value.parallax_speed / 2) + '%',
    //       ease: Linear.easeNone
    //     }, 0)
    //   }
    // })
    // scene.setTween(timeline)
    // scene.addTo(controller)

    this.$refs.container.style.setProperty(
      '--underline-color',
      this.styles.color
    )
    const end = this.$refs.container.getElementsByClassName('end-icon')
    if (end.length > 0) {
      this.end = true
    }
  },

  methods: {
    layout(index) {
      if (this.data.layout.layers[index].width) {
        return this.grid(this.data.layout.layers[index].width)
      }
    },
    padding(index) {
      if (
        this.data.layout.layers[index].padding_min &&
        this.data.layout.layers[index].padding_max
      ) {
        const max = this.data.layout.layers[index].padding_max
        const min = this.data.layout.layers[index].padding_min
        let space = max
        if (this.$mq === 'medium') {
          space = max - (max - min) / 2
        }
        if (
          this.$mq === 'tiny' ||
          this.$mq === 'small' ||
          this.$mq === 'mobile'
        ) {
          space = min
        }
        if (this.data.layout.layers[index].align === 'top') {
          return {
            padding: space + 'px 0 0 0'
          }
        } else if (this.data.layout.layers[index].align === 'bottom') {
          return {
            padding: '0 0 ' + space + 'px 0'
          }
        } else {
          return {
            padding: space + 'px 0'
          }
        }
      }
    },
    align(index) {
      return { alignItems: this.data.layout.align_left }
    },
    content(layer) {
      let string = layer.text
      string = string.replace('[END]', '<span class="end-icon">&nbsp;</span>')
      return string
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  position: relative;

  &.no-padding {
    margin-bottom: calc(var(--module-spacing) * -1);
    @include media($mobile) {
      margin-bottom: 0;
    }
  }
}
.container {
  position: relative;

  &.no-gutter {
    grid-column-gap: 0;
  }
}
.layer {
  z-index: 1;
  position: relative;
  display: grid;
  grid-row-start: 1;

  &.mobile-hide {
    @include media($mobile) {
      display: none;
    }
  }
  &.desktop-hide {
    display: none;
    @include media($mobile) {
      display: block;
    }
  }
  &:not(.full){
    @include media($mobile) {
      .stack & {
        grid-column: col-start 1 / span 6 !important;
        grid-row-start: auto !important;
        margin-bottom: var(--module-spacing);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &.full {
    @include media($mobile) {
      .stack & {
        grid-column: full !important;
        grid-row-start: auto !important;
        margin-bottom: var(--module-spacing);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  

  &.front {
    z-index: 2;
  }

  &.top {
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
  }
}
.content {
  ::v-deep {
    a {
      text-decoration: underline;
    }
    .smallcaps {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 80%;
      line-height: inherit;
    }
    .dropcap {
      float: left;
      font-size: 500%;
      line-height: 80%;
      @include fluid-prop(padding-top, 8px, 8px);
      padding-right: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
    h2 {
      @include fontsize(50);
      margin-bottom: 20px;
    }
    h6 {
      @include font('label');
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
}
</style>
