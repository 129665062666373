<template>
  <div class="swatches">
    <ul>
      <li v-for="swatch in filteredData" :key="swatch.cylindo_id" :class="{active: (isPrimary && swatch === $parent.$parent.upholstery) || (!isPrimary && swatch === $parent.$parent.upholsterySecondary)}" @click="onClick(swatch)">
        <img :src="getCDN(swatch.image_small[0])" />
        <div class="tooltip">
          <img :src="getCDN(swatch.image[0])" />
          <b>{{ swatch.title }}</b>
          <p>{{ swatch.description }}</p>
        </div>
      </li>
    </ul>
    <div v-if="data.length > 24 && !$parent.$parent.showAll" class="show-more" @click="() => $parent.$parent.showAll = true">Show all</div>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  name: 'Swatches',

  mixins: [utils],

  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    isPrimary: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    filteredData() {
      if (!this.$parent.$parent.showAll) {
        return this.data.slice(0, 24)
      } else {
        return this.data
      }
    }
  },

  methods: {
    onClick(item) {
      this.$emit('changeColor', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.swatches {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.25%;
    margin-top: 15px;
    width: 102.5%;
    z-index: 50;
    position: relative;
    li {
      margin: 1% 1.25%;
      flex-basis: 10%;
      position: relative;
      cursor: pointer;

      > img {
        line-height: 0;
      }

      &:hover {
        .tooltip {
          transform: none;
          opacity: 1;
        }
      }
      &.active {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 1px;
          left: 0;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.show-more {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  padding-top: 2px;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.tooltip {
  opacity: 0;
  pointer-events: none;
  width: 200px;
  height: auto;
  position: absolute;
  padding: 8px;
  background-color: #fff;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.3);
  bottom: 46px;
  left: -10px;
  transition: all 200ms $easeOutQuart;
  transform: translateY(5px);

  p {
    margin-top: 10px;
    white-space: pre-wrap;
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #ffffff transparent transparent transparent;
  }
}
</style>
