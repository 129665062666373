<template>
  <div
    v-vpshow
    class="upholstery-top"
  >
    <div ref="container" class="wrapper">
      <div class="upholstery-title">
        <div v-if="data.pricegroup" class="price-group" v-html="data.pricegroup.name"></div>
        <h1 class="title">{{ title }}</h1>
        <div v-if="data.brand" class="brand" v-html="data.brand.name"></div>
      </div>
      <div class="upholstery-info">
        <div class="row">
          <div class="main-image">
            <img :src="currentVariant ? getCDN(variants.find((variant) => dasherize(variant.color_code) === currentVariant).image[0]) : null" />
          </div>
        </div>
      </div>
      <div class="details">
        <div v-if="data.og_description">
          <div class="label first">Description</div>
          <div class="description" v-html="data.og_description"></div>
        </div>
      
        <div v-if="data.certifications">
          <div class="label">Certifications</div>
          <div v-for="(certification, index) in data.certifications" :key="'certification'+index" :href="certification.link" target="_blank" class="certification">
            <div v-html="certification.description" class="description"></div>
          </div>
          <div class="certifications">
            <template v-for="(certification, index) in data.certifications">
              <a v-if="certification.image" :key="'certification'+index" :href="certification.link" target="_blank" class="certification">
                <Img
                  :data="certification.image"
                />
              </a>
            </template>
          </div>
        </div>

        <div v-if="data.details" :class="{open: showDetails}">
          <div class="label details-label" @click="toggleDetails">{{ data.details_label || 'Details'}}</div>
          <div class="description" v-html="data.details" v-show="showDetails"></div>
        </div>

        <div class="variant-container" v-if="variants && variants.length > 1">
          <div class="label">Variants</div>
          <div class="variants">
            <div v-for="(variant, index) in variants" :key="'variant-'+index" :class="{'active': currentVariant === dasherize(variant.color_code)}" class="variant" @click="selectVariant(variant.color_code)" @mouseenter="variantEnter(variant.color_code)" @mouseleave="variantLeave(variant.color_code)">
              <div class="thumb">
                <img v-if="variant.image" :src="getCDN(variant.image[0])" />
                <div v-else class="placeholder"/>
              </div>
              <div class="variant-title">{{ variant.color_code }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Img from '@/components/Img'
import utils from '@/mixins/utils'
import Accordion from '@/components/Accordion'
import bus from '@/components/bus'

export default {
  name: 'UpholsteryTop',

  components: {
    Img,
    Accordion
  },

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      currentVariant: null,
      hoverVariant: null,
      showExtendedInfo: false,
      showDetails: this.data.type === 'material'
    }
  },

  watch: {
    $route(to, from) {
      this.checkQuery()
    }
  },
  created() {
    this.checkQuery()
  },

  computed: {
    variants() {
      const variants = JSON.parse(JSON.stringify(this.data.variants))
      variants.forEach(variant => {
        if(!variant.color_code) {
          variant.color_code = variant.title
        }
      })
      return variants
    }
  },

  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    triggerResize() {
      if (window) {
        window.dispatchEvent(new Event('resize'))
      }
    },
    checkQuery() {
      if (this.$route.query.variant) {
        const self = this
        this.variants.forEach(function(value, index) {
          if (self.dasherize(value.color_code) === self.$route.query.variant) {
            self.currentVariant = self.dasherize(self.$route.query.variant)
            self.hoverVariant = self.dasherize(self.$route.query.variant)
          }
        })
        // VueScrollTo.scrollTo('#app', 1000)
      }
      if(!this.currentVariant) {
        this.currentVariant = this.dasherize(this.variants[0].color_code)
      }
    },
    selectVariant(variant) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          variant: this.dasherize(variant)
        }
      })
      bus.$emit('toggleLoading', false)
    },
    variantEnter(index) {
      this.hoverVariant = index
    },
    variantLeave(index) {
      this.hoverVariant = this.currentVariant
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @include grid-container-responsive;
}
.upholstery-top {
  // @include module-spacing(padding-bottom);
  margin-bottom: var(--module-spacing);
  @include fluid-prop(margin-top, 0px, 40px);
}
.upholstery-title {
  @include grid-item(12, 1);
  @include fluid-prop(margin-bottom, 20px, 40px);
  @include media($mobile) {
    @include grid-item(6, 1);
  }

  .price-group {
    @include fontsize(15);
    font-weight: 500;
    @include fluid-prop(margin-bottom, 10px, 20px);
  }

  .title {
    @include fontsize(36);
    font-family: adobe-garamond-pro;
  }
  .brand {
    @include fontsize(36);
    font-style: italic;
    font-family: adobe-garamond-pro;
  }

  @include media($mobile) {
    font-size: 24px;
    line-height: 34px;
  }
}
.main-image {
  line-height: 0;
}
.details-label {
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 2px);
    font-size: 150%;
    top: 7px;
    transform: translateY(-50%);
    transition: 200ms ease-in-out;
    transform-origin: center;
    background-image: url("~/assets/img/arrow-right.png");
    background-size: 18px;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    opacity: 0.5;

    .open & {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
.upholstery-info {
  @include grid-item(5, 1);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: $line-color;
    right: -$grid-gap;
    top: 0;
    @include media($mobile) {
      display: none;
    }
  }

  @include media($mobile) {
    @include grid-item(6, 1);
  }
}
.details {
  @include grid-item(7, 6);
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  ::v-deep {
    p {
      margin-bottom: 10px;
    }
  }
   @include media($mobile) {
    @include grid-item(6, 1);
    padding-left: 0;
  }
}
.description {
  max-width: 600px;
}
.label {
  @include fluid-prop(margin-top, 20px, 40px);
  @include fluid-prop(margin-bottom, 10px, 15px);
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid $line-color;

  &.first {
    @include fluid-prop(margin-top, 0px, 0px);
     @include media($mobile) {
      @include fluid-prop(margin-top, 20px, 40px);
    }
  }
  span {
    cursor: pointer;
    &:hover {
      color: $black;
    }
  }
}
.row {
  transition: top 500ms, opacity 0.4s;
  position: sticky;
  top: 90px;

  > *:first-child {
    margin-top: 0;
  }

  .header-hidden & {
    top: 20px;
  }
  .safari & {
    top: 0 !important;
    position: relative;
  }
  @include media($mobile) {
    overflow: hidden;
  }
}
.variant-container {
  @include media($mobile) {
    order: -1;
  }
}
.certifications {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.certification {
  flex: 0 0 10%;
  margin-right: 25px;
}
.variants {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-left: -10px;
  margin-right: -10px;

   @include media($mobile) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.variant {
  cursor: pointer;
  padding: 10px;
  &.active {
    background-color: $gray--light;
  }
}
.variant-title {
  @include fontsize(15);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter-to,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  position: absolute;
  width: 100%;
}

.fade-text-enter-active,
.fade-text-leave-active {
  transition: all 0.1s;
}
.fade-text-enter,
.fade-text-leave-to {
  opacity: 0;
}
</style>
