<template>
  <div class="image" :class="[{ 'no-scale': noScale }, {'with-hotspots': hotspots.length > 0}]">
    <figure>
      <div
        class="image-container"
        :style="[layout, {paddingBottom: !noScale ? aspectRatio * 100 + '%' : null}]"
      >
        <img
          v-if="data.sizes"
          v-lazy="lazy ? getCDN(getImage) : null"
          :src="lazy ? getCDN(getImage) : null"
          :data-src="getCDN(data.sizes['fullwidth'])"
          :alt="data.caption ? data.caption : 'Image'"
          :style="{paddingBottom: !noScale ? aspectRatio * 100 + '%' : null}"
          :class="[{'enable-photoswipe' : (enable === true)}, crop ? 'cropped' : null]"
          :data-full="(enable === true && data.sizes)? getCDN(data.sizes['fullwidth']) :''"
          :data-dimensions="data.sizes['fullwidth-width'] + 'x' + data.sizes['fullwidth-height']"
          class="tns-lazy-img"
        >
        <div v-if="hotspots.length > 0" slot="content" class="hotspots">
          <a
            v-for="(hotspot, index) in hotspots"
            :key="index"
            :data-url="getCDN(getUrl(hotspot.url))"
            :style="[
              { top: $store.state.isTouchDevice ? 0 : hotspot.hotspot.split(',')[1]},
              { left: $store.state.isTouchDevice ? 0 : hotspot.hotspot.split(',')[0]}
            ]"
            class="hotspot"
            v-on="{click, mouseenter, mouseleave}"
          >
            <span
              class="dot"
              :style="[
                { top: $store.state.isTouchDevice ? hotspot.hotspot.split(',')[1] : '-5px'},
                { left: $store.state.isTouchDevice ? hotspot.hotspot.split(',')[0] : '-5px' }
              ]"
              @click="clickDot"
            />
            <div v-click-outside="hide" class="hotspot-content" @click="clickInner">
              <div class="hotspot-inner">
                <div
                  :style="{ backgroundImage: 'url(' + hotspot.icon + ')' }"
                  class="hotspot-image"
                />
                <div class="title">
                  {{ hotspot.title }} <sup>{{ hotspot.superscript }}</sup>
                  <div>{{ hotspot.designer }}</div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <figcaption
        v-if="showCaption && (data.caption || data.credits)"
        :class="[captionAlign, (columns && columns.split(',')[1] === '13') ? 'full-right' : null, (columns && columns.split(',')[0] === '0') ? 'full-left' : null]"
        :style="[layout, {textAlign: captionAlign} ]"
      >
        <span>
          <span
            :style="imageCaptionStyle"
            class="caption"
            v-html="data.caption"
          />
          <span
            :style="imageCreditsStyle"
            class="credits"
            v-html="data.credits"
          />
        </span>
      </figcaption>
    </figure>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  name: 'Img',

  mixins: [utils],

  props: {
    data: {
      type: [Object, Boolean],
      default: () => {
        return false
      }
    },
    layout: {
      type: [Object, Boolean],
      default: () => {
        return false
      }
    },
    columns: {
      type: [String, Boolean],
      default: () => {
        return false
      }
    },
    crop: {
      type: [String],
      default: null
    },
    enable: {
      type: Boolean,
      default: false
    },
    noScale: {
      type: Boolean,
      default: false
    },
    showCaption: {
      type: Boolean,
      default: true
    },
    captionAlign: {
      type: String,
      default: 'left'
    },
    preview: {
      type: Boolean,
      default: true
    },
    hotspots: {
      type: [Array, Boolean],
      default: () => {
        return []
      }
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    getImage() {
      if (!this.data.sizes) {
        return ''
      }
      let url = ''
      switch (this.crop) {
        case '1to1':
          url = this.data.sizes['full-1-1']
          break
        case '3to2':
          url = this.data.sizes['full-3-2']
          break
        case '4to3':
          url = this.data.sizes['full-4-3']
          break
        case '3to4':
          url = this.data.sizes['full-3-4']
          break
        case '16to9':
          url = this.data.sizes['full-16-9']
          break
        default:
          url = this.data.sizes.fullwidth
      }
      return this.getCDN(url)
    },
    getPreloadImage() {
      switch (this.crop) {
        case '1to1':
          return this.data.sizes['preload-1-1']
        case '3to2':
          return this.data.sizes['preload-3-2']
        case '4to3':
          return this.data.sizes['preload-4-3']
        case '3to4':
          return this.data.sizes['preload-3-4']
        case '16to9':
          return this.data.sizes['preload-16-9']
        default:
          return this.data.sizes.preload
      }
    },
    aspectRatio() {
      if (!this.data.sizes) {
        return 1
      }
      switch (this.crop) {
        case '1to1':
          return (
            this.data.sizes['full-1-1-height'] /
            this.data.sizes['full-1-1-width']
          )
        case '3to2':
          return (
            this.data.sizes['full-3-2-height'] /
            this.data.sizes['full-3-2-width']
          )
        case '4to3':
          return (
            this.data.sizes['full-4-3-height'] /
            this.data.sizes['full-4-3-width']
          )
        case '3to4':
          return (
            this.data.sizes['full-3-4-height'] /
            this.data.sizes['full-3-4-width']
          )
        case '16to9':
          return (
            this.data.sizes['full-16-9-height'] /
            this.data.sizes['full-16-9-width']
          )
        default:
          return (
            this.data.sizes['fullwidth-height'] /
            this.data.sizes['fullwidth-width']
          )
      }
    },
    dimensions() {
      switch (this.crop) {
        case '1to1':
          return (
            this.data.sizes['full-1-1-width'] +
            'x' +
            this.data.sizes['full-1-1-height']
          )
        case '3to2':
          return (
            this.data.sizes['full-3-2-width'] +
            'x' +
            this.data.sizes['full-3-2-height']
          )
        case '4to3':
          return (
            this.data.sizes['full-4-3-width'] +
            'x' +
            this.data.sizes['full-4-3-height']
          )
        case '3to4':
          return (
            this.data.sizes['full-3-4-width'] +
            'x' +
            this.data.sizes['full-3-4-height']
          )
        case '16to9':
          return (
            this.data.sizes['full-16-9-width'] +
            'x' +
            this.data.sizes['full-16-9-height']
          )
        default:
          return (
            this.data.sizes['full-1-1-width'] +
            'x' +
            this.data.sizes['full-1-1-height']
          )
      }
    },
    imageCaptionStyle() {
      return this.$store.getters.imageCaptionStyle
    },
    imageCreditsStyle() {
      return this.$store.getters.imageCreditsStyle
    }
  },
  methods: {
    click(e) {
      if (!this.$store.state.isTouchDevice) {
        this.$router.push({
          path: e.currentTarget.dataset.url
        })
      }
    },
    clickInner(e) {
      if (this.$store.state.isTouchDevice) {
        this.$router.push({
          path: e.currentTarget.parentNode.dataset.url
        })
      }
    },
    clickDot(e) {
      if (this.$store.state.isTouchDevice) {
        if (!e.currentTarget.parentNode.classList.contains('hover')) {
          e.currentTarget.parentNode.classList.add('hover')
        }
      }
    },
    mouseenter(e) {
      if (!this.$store.state.isTouchDevice) {
        e.currentTarget.classList.add('hover')
      }
    },
    mouseleave(e) {
      if (!this.$store.state.isTouchDevice) {
        e.currentTarget.classList.remove('hover')
      }
    },
    hide(e, el) {
      if (this.$store.state.isTouchDevice) {
        setTimeout(() => {
          el.parentNode.classList.remove('hover')
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image {
  &.with-hotspots {
    overflow-x: hidden;
    .image-container {
      overflow: hidden;
    }
  }
}
.image-container {
  position: relative;
  text-align: center;
  overflow: hidden;
  .tns-slider & {
    padding-bottom: 0 !important;
  }
}
.hotspots {
  position: absolute;
  z-index: 3;
  transition: opacity 200ms;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .hotspot {
    position: absolute;

    .touch & {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    cursor: pointer;
    &.hover {
      .touch & {
        z-index: 50;
      }
      .hotspot-content {
        clip: rect(0px, 200px, 260px, 0px);
      }
    }
    .dot {
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: $secondary-color;
      top: -5px;
      left: -5px;
      .touch & {
        pointer-events: all;
      }

      &:after {
        content: '';
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: $secondary-color;
        top: -20px;
        left: -20px;
        transform: scale(0);
        animation: scaleUp 5s infinite $easeOutExpo;
      }
      @keyframes scaleUp {
        0% {
          transform: scale(0);
          opacity: 1;
        }
        100% {
          transform: scale(1);
          opacity: 0;
        }
      }
    }
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        .dot {
          &:after {
            animation-delay: (#{$i * 0.3s});
          }
        }
      }
    }
    .hotspot-content {
      position: absolute;
      background-color: $secondary-color;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 16px 0px;
      overflow: hidden;
      transform: translate(-50%, -50%);
      transition: all 500ms $easeOutExpo;
      height: 260px;
      width: 200px;
      clip: rect(130px, 100px, 130px, 100px);
      .touch & {
        pointer-events: all;
      }

      .touch & {
        top: 50%;
        left: 50%;
      }
      .title {
        line-height: normal;
        font-weight: 500;
        font-size: 14px;
        padding: 15px;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: $black;
      }
      .hotspot-inner {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 200px;
        height: 260px;
        .hotspot-image {
          background-color: $secondary-color;
          background-position: top;
          background-size: contain;
          background-repeat: no-repeat;
          background-blend-mode: multiply;
          height: 80%;
          width: 80%;
          top: 10%;
          left: 10%;
          position: absolute;
        }
      }
    }
  }
}
figure {
  width: 100%;
  line-height: 0;
  ::v-deep img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    @include lazyLoadZoom;
    .tns-slider &,
    .no-scale & {
      width: auto !important;
      max-width: 100%;
      margin: 0 auto;
      position: relative;
      padding-bottom: 0 !important;
    }
    .tns-slider & {
      width: calc(100vw - 40px) !important;
    }
  }
}
figcaption {
  @include fontsize(15);
  [data-hide-captions='true'] & {
    display: none;
  }
  .image[data-size='full'] & {
    color: $white !important;
  }
  .caption {
    margin-top: 15px;
    display: block;
  }
  .description {
    margin-top: 5px;
    opacity: 0.5;
    display: block;
  }
}
</style>
