var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('Base',{style:([_vm.styles])},[_c('section',{directives:[{name:"vpshow",rawName:"v-vpshow"}],staticClass:"wrapper"},[_c('div',{ref:'bodyText',staticClass:"body-text",attrs:{"data-features":_vm.data.wysiwyg.type_features,"data-layout":_vm.data.wysiwyg.layout,"data-position":!_vm.layout ? _vm.data.wysiwyg.position : null}},[_c('div',{staticClass:"content",style:([_vm.bodyStyle, _vm.layout])},[(_vm.prev)?[(_vm.prev.singleimage)?[(
                _vm.prev.singleimage.size === 'small' || _vm.prev.singleimage.wrap
              )?[_c('Img',{class:_vm.prev.singleimage.wrapped_position,style:([
                  _vm.wrapped,
                  _vm.prev.singleimage.wrap ? _vm.columns : false,
                  _vm.padding
                ]),attrs:{"data":_vm.prev.singleimage.image,"no-scale":_vm.prev.singleimage.no_scale,"enable":_vm.prev.singleimage.zoom,"data-size":_vm.prev.singleimage.size,"data-placement":_vm.prev.singleimage.caption_placement}})]:_vm._e()]:_vm._e(),_vm._v(" "),(_vm.prev.gallery_v2)?[(_vm.prev.gallery_v2.layout_clone.wrap)?_c('span',{staticClass:"embedded-module",class:_vm.prev.gallery_v2.layout_clone.wrapped_position,style:([_vm.wrapped, _vm.padding])},[_c('Slideshow',{attrs:{"data":_vm.prev.gallery_v2}})],1):_vm._e()]:(_vm.prev.video && _vm.prev.video.wrap)?[_c('Vid',{attrs:{"data":_vm.prev.video}})]:(_vm.prev.audio && _vm.prev.audio.wrap)?_c('span',{staticClass:"embedded-module",class:_vm.prev.audio.wrapped_position,style:([_vm.wrapped, _vm.padding])},[_c('Audio',{attrs:{"data":_vm.prev}})],1):(_vm.prev.quote && _vm.prev.quote.wrap)?_c('span',{staticClass:"embedded-module",class:_vm.prev.quote.wrapped_position,style:([_vm.wrapped, _vm.padding])},[_c('Quote',{attrs:{"data":_vm.prev}})],1):_vm._e()]:_vm._e(),_vm._v(" "),_c('p',{staticClass:"content-text",domProps:{"innerHTML":_vm._s(_vm.content)},on:{"click":function($event){return _vm.handleClicks($event, '.body-text')}}})],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }