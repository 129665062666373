<template>
  <fragment>
    <Base :style="[styles]">
    <div
      v-vpshow
      v-if="data.issuu.publications && data.issuu.publications"
      class="wrapper"
    >
    <div class="issuu-container">
      <div v-for="(issuu, index) in data.issuu.publications" :key="index">
        <div><iframe style="border:none;width:100%;height:300px;" allowfullscreen="true" allow="fullscreen" :src="'https://e.issuu.com/embed.html?d=' + issuu.id + '&u=andtradition&backgroundColor=transparent&hideIssuuLogo=true'"></iframe></div>
        <h3 class="title">{{issuu.title}}</h3>
      </div>
    </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import utils from '@/mixins/utils'

export default {
  name: 'Issuu',

  components: {
    Base
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container-responsive;
}
.issuu-container {
  @include grid-item(12, 1);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 30px;

  > div {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media($medium) {
    grid-template-columns: repeat(1, 1fr);
  }

  @include media($mobile) {
    @include grid-item(6, 1);
    grid-template-columns: repeat(1, 1fr);
    > div {
      width: 100%;
    }
  }
}
.title {
  @include fontsize(20);
  text-align: center;
}
</style>
