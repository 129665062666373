var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[(!_vm.data.gallery_v2.layout_clone.wrap)?_c('Base',{style:([_vm.styles])},[_c('div',{staticClass:"gallery",class:[
      _vm.data.gallery_v2.layout_clone.custom_layout &&
        _vm.data.gallery_v2.layout_clone.columns.split(',')[1] === '13'
        ? 'full-right'
        : null,
      _vm.data.gallery_v2.layout_clone.custom_layout &&
        _vm.data.gallery_v2.layout_clone.columns.split(',')[0] === '0'
        ? 'full-left'
        : null
    ],attrs:{"data-layout":_vm.data.gallery_v2.layout,"data-hide-captions":!_vm.data.gallery_v2.show_caption}},[(_vm.data.gallery_v2.layout === 'slideshow')?_c('div',{staticClass:"wrapper"},[_c('div',{style:([_vm.layout, { position: 'relative' }])},[_c('Slideshow',{attrs:{"data":_vm.data.gallery_v2}})],1)]):_vm._e(),_vm._v(" "),(_vm.data.gallery_v2.layout === 'full')?_c('div',[_c('Slideshow',{attrs:{"data":_vm.data.gallery_v2,"full":true,"loop":true}})],1):_vm._e(),_vm._v(" "),(_vm.data.gallery_v2.layout === 'blocks')?_c('div',{staticClass:"grid-wrapper"},[_c('div',{staticClass:"block-grid",class:_vm.data.gallery_v2.blocks_variant},_vm._l((_vm.data.gallery_v2.images),function(img,index){return _c('Img',{directives:[{name:"vpshow",rawName:"v-vpshow"}],key:index,class:[
            _vm.data.gallery_v2.layout === 'blocks' &&
              (!_vm.data.gallery_v2.blocks_variant ||
              _vm.data.gallery_v2.blocks_variant === 'auto')
              ? _vm.getSize(index, _vm.data.gallery_v2.images.length)
              : '',
              {'use-as-index': _vm.data.gallery_v2.use_as_index}
          ],attrs:{"enable":_vm.data.gallery_v2.zoom,"data":img,"crop":_vm.data.gallery_v2.image_crop,"caption-align":_vm.data.gallery_v2.caption_align},nativeOn:{"click":function($event){return _vm.onClick($event, index)}}})}),1)]):_vm._e(),_vm._v(" "),(_vm.data.gallery_v2.layout === 'chaos')?_c('div',{staticClass:"chaos-grid",class:_vm.data.gallery_v2.chaos_style,attrs:{"data-count":_vm.data.gallery_v2.images.length}},_vm._l((_vm.data.gallery_v2.images),function(img,index){return _c('Img',{directives:[{name:"vpshow",rawName:"v-vpshow"}],key:index,attrs:{"enable":_vm.data.gallery_v2.zoom,"crop":_vm.data.gallery_v2.image_crop,"caption-align":_vm.data.gallery_v2.caption_align,"data":img}})}),1):_vm._e(),_vm._v(" "),(_vm.data.gallery_v2.layout === 'moodboard')?_c('div',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({ active: true, callback: _vm.onWaypoint }),expression:"{ active: true, callback: onWaypoint }"}],staticClass:"moodboard",attrs:{"id":'moodboard_' + _vm._uid}},_vm._l((_vm.data.gallery_v2.images),function(img,index){return _c('Img',{key:index,ref:'moodboardImage',refInFor:true,style:(_vm.moodboardStyle(img, index)),attrs:{"enable":false,"crop":_vm.data.gallery_v2.image_crop,"caption-align":_vm.data.gallery_v2.caption_align,"data":img}})}),1):_vm._e()])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }