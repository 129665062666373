// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/icons/download.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button[data-v-0f5ce747]{background-color:#fff;color:#282828;margin-top:20px;padding-left:35px;position:relative}.pdf-icon[data-v-0f5ce747]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:16px;display:inline-block;height:16px;left:10px;position:absolute;top:12px;width:16px}.generating .pdf-icon[data-v-0f5ce747],.spinner[data-v-0f5ce747]{opacity:0}.spinner[data-v-0f5ce747]{height:18px;left:10px;position:absolute;top:10px;width:18px}.spinner svg[data-v-0f5ce747]{height:100%;width:100%}.generating .spinner[data-v-0f5ce747]{opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
