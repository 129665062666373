<template>
  <fragment>
    <Base :style="[styles]">
    <div class="wrapper">
      <div class="materiallist">
        <div class="label">{{ data.materiallist.label }}</div>
        <MaterialGroup v-for="(group, index) in data.materiallist.materialgroups" :openAsDefault="index === 0" :data="group" :key="'group-'+index">
        </MaterialGroup>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from '@/components/modules/Base'
import utils from '@/mixins/utils'
import MaterialGroup from '@/components/MaterialGroup'

export default {
  name: 'Materiallist',
  components: {
    Base,
    MaterialGroup
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container-responsive;
}
.materiallist {
  @include grid-item(12, 1);
  @include media($mobile) {
    @include grid-item(6, 1);
  }
}
.label {
  @include fluid-prop(margin-top, 20px, 40px);
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid $line-color;
}

</style>
