var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('Base',{style:([_vm.styles])},[_c('div',{directives:[{name:"vpshow",rawName:"v-vpshow"}],staticClass:"layout",class:[!_vm.data.layout.stack ? 'stack' : '', {'no-padding': _vm.data.layout.remove_center_gutter}]},[_c('div',{ref:'container',staticClass:"container",class:{'no-gutter': _vm.data.layout.remove_center_gutter}},_vm._l((_vm.data.layout.layers),function(layer,index){return _c('div',{key:index,ref:'layer-' + index,refInFor:true,staticClass:"layer",class:[
          layer.align,
          layer.hide_on_mobile ? 'mobile-hide' : '',
          layer.hide_on_desktop ? 'desktop-hide' : '',
          {full: layer.width === '0,13'}
        ],style:([_vm.layout(index), _vm.padding(index), { zIndex: 999 - index }])},[(layer.acf_fc_layout === 'videolayer')?_c('Vid',{attrs:{"data":layer}}):_vm._e(),_vm._v(" "),(layer.acf_fc_layout === 'imagelayer')?_c('Img',{attrs:{"show-caption":layer.show_caption ? true : false,"enable":layer.zoom,"caption-align":layer.caption_align,"data":layer.image}}):_vm._e(),_vm._v(" "),(
            layer.acf_fc_layout === 'displaylextlayer' ||
              layer.acf_fc_layout === 'textlayer' ||
              layer.acf_fc_layout === 'quotelayer'
          )?_c('div',{staticClass:"content",style:([_vm.bodyStyle])},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.content(layer))}})]):_vm._e()],1)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }