var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image",class:[{ 'no-scale': _vm.noScale }, {'with-hotspots': _vm.hotspots.length > 0}]},[_c('figure',[_c('div',{staticClass:"image-container",style:([_vm.layout, {paddingBottom: !_vm.noScale ? _vm.aspectRatio * 100 + '%' : null}])},[(_vm.data.sizes)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.lazy ? _vm.getCDN(_vm.getImage) : null),expression:"lazy ? getCDN(getImage) : null"}],staticClass:"tns-lazy-img",class:[{'enable-photoswipe' : (_vm.enable === true)}, _vm.crop ? 'cropped' : null],style:({paddingBottom: !_vm.noScale ? _vm.aspectRatio * 100 + '%' : null}),attrs:{"src":_vm.lazy ? _vm.getCDN(_vm.getImage) : null,"data-src":_vm.getCDN(_vm.data.sizes['fullwidth']),"alt":_vm.data.caption ? _vm.data.caption : 'Image',"data-full":(_vm.enable === true && _vm.data.sizes)? _vm.getCDN(_vm.data.sizes['fullwidth']) :'',"data-dimensions":_vm.data.sizes['fullwidth-width'] + 'x' + _vm.data.sizes['fullwidth-height']}}):_vm._e(),_vm._v(" "),(_vm.hotspots.length > 0)?_c('div',{staticClass:"hotspots",attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.hotspots),function(hotspot,index){return _c('a',_vm._g({key:index,staticClass:"hotspot",style:([
            { top: _vm.$store.state.isTouchDevice ? 0 : hotspot.hotspot.split(',')[1]},
            { left: _vm.$store.state.isTouchDevice ? 0 : hotspot.hotspot.split(',')[0]}
          ]),attrs:{"data-url":_vm.getCDN(_vm.getUrl(hotspot.url))}},{click: _vm.click, mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave}),[_c('span',{staticClass:"dot",style:([
              { top: _vm.$store.state.isTouchDevice ? hotspot.hotspot.split(',')[1] : '-5px'},
              { left: _vm.$store.state.isTouchDevice ? hotspot.hotspot.split(',')[0] : '-5px' }
            ]),on:{"click":_vm.clickDot}}),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"hotspot-content",on:{"click":_vm.clickInner}},[_c('div',{staticClass:"hotspot-inner"},[_c('div',{staticClass:"hotspot-image",style:({ backgroundImage: 'url(' + hotspot.icon + ')' })}),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n                "+_vm._s(hotspot.title)+" "),_c('sup',[_vm._v(_vm._s(hotspot.superscript))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(hotspot.designer))])])])])])}),0):_vm._e()]),_vm._v(" "),(_vm.showCaption && (_vm.data.caption || _vm.data.credits))?_c('figcaption',{class:[_vm.captionAlign, (_vm.columns && _vm.columns.split(',')[1] === '13') ? 'full-right' : null, (_vm.columns && _vm.columns.split(',')[0] === '0') ? 'full-left' : null],style:([_vm.layout, {textAlign: _vm.captionAlign} ])},[_c('span',[_c('span',{staticClass:"caption",style:(_vm.imageCaptionStyle),domProps:{"innerHTML":_vm._s(_vm.data.caption)}}),_vm._v(" "),_c('span',{staticClass:"credits",style:(_vm.imageCreditsStyle),domProps:{"innerHTML":_vm._s(_vm.data.credits)}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }