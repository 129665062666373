<template>
  <fragment>
    <Base :style="[styles]">
    <div class="wrapper" v-html="data.embed.link" />
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'

export default {
  name: 'Embed',

  components: {
    Base
  },
  extends: Base,

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  created() {
    if (this.data.embed.link.indexOf('//www.instagram.com/embed.js') > -1) {
      /* eslint-disable-next-line no-unused-vars */
      const promise = new Promise((resolve, reject) => {
        const instagram = document.createElement('script')
        instagram.async = true
        instagram.src = '//www.instagram.com/embed.js'
        document.head.appendChild(instagram)
      })
    }
    if (
      this.data.embed.link.indexOf('https://platform.twitter.com/widgets.js') >
      -1
    ) {
      /* eslint-disable-next-line no-unused-vars */
      const promise = new Promise((resolve, reject) => {
        const twitter = document.createElement('script')
        twitter.async = true
        twitter.src = 'https://platform.twitter.com/widgets.js'
        document.head.appendChild(twitter)
      })
    }
    if (this.data.embed.link.indexOf('connect.facebook.net') > -1) {
      /* eslint-disable-next-line no-unused-vars */
      const promise = new Promise((resolve, reject) => {
        const facebook = document.createElement('script')
        facebook.async = true
        facebook.src =
          'https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v3.1'
        document.head.appendChild(facebook)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container;

  ::v-deep {
    > * {
      @include grid-item(12, 1);
    }
  }
}
</style>
