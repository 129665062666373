<template>
  <div :class="{open: open}" class="accordion-item">
    <div class="headline" v-html="headline" @click="toggle"></div>
    <transition name="fade">
      <div class="text" key="text" v-show="open">
        <div v-html="text.replace('[DRAWING]', '')"></div>
        <img v-if="text.includes('[DRAWING]') && drawing" v-lazy="getCDN(drawing.sizes.fullwidth)" class="product-drawing" alt="Product Drawing" />
      </div>
    </transition>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  props: {
    headline: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    drawing: {
      type: [Object, Boolean],
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-item {
  border-bottom: 1px solid $line-color;
}
.headline {
  padding: 10px 0;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 2px);
    font-size: 150%;
    transform: translateY(-50%);
    transition: 200ms ease-in-out;
    transform-origin: center;
    background-image: url("~/assets/img/arrow-right.png");
    background-size: 18px;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    opacity: 0.5;

    .open & {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
.text {
  padding: 10px 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
