<template>
  <div
    v-vpshow
    class="material-top"
  >
    <div ref="container" class="wrapper">
      <div class="material-title">
        <div v-if="data.label" class="material-label" v-html="data.label"></div>
        <h1 class="title">{{ title }}</h1>
        <div v-if="data.brand" class="brand" v-html="data.brand.name"></div>
      </div>
      <div class="material-info">
        <div class="row">
          <div class="main-image">
            <img :src="getCDN(data.image[0])" />
          </div>
        </div>
      </div>
      <div class="details">
        <div v-if="data.og_description">
          <div class="label first">Details</div>
          <div class="description-label">Description</div>
          <div class="description" v-html="data.og_description"></div>
        </div>

       <AccordionItem v-for="(specification, index) in data.specifications" :headline="specification.label" :text="specification.text" :key="index"></AccordionItem>
      </div>
    </div>
  </div>
</template>

<script>
import Img from '@/components/Img'
import utils from '@/mixins/utils'
import bus from '@/components/bus'
import AccordionItem from '@/components/AccordionItem.vue'

export default {
  name: 'MaterialTop',

  components: {
    Img,
    AccordionItem
  },

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      showExtendedInfo: false,
      showDetails: false
    }
  },

  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    triggerResize() {
      if (window) {
        window.dispatchEvent(new Event('resize'))
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @include grid-container-responsive;
}
.material-top {
  // @include module-spacing(padding-bottom);
  margin-bottom: var(--module-spacing);
  @include fluid-prop(margin-top, 0px, 40px);
}
.material-title {
  @include grid-item(12, 1);
  @include fluid-prop(margin-bottom, 20px, 40px);
  @include media($mobile) {
    @include grid-item(6, 1);
  }

  .material-label {
    @include fontsize(15);
    font-weight: 500;
    @include fluid-prop(margin-bottom, 10px, 20px);
  }

  .title {
    @include fontsize(36);
    font-family: adobe-garamond-pro;
  }
  .brand {
    @include fontsize(36);
    font-style: italic;
    font-family: adobe-garamond-pro;
  }

  @include media($mobile) {
    font-size: 24px;
    line-height: 34px;
  }
}
.main-image {
  line-height: 0;
}
::v-deep .headline {
  @include fontsize(15);
  font-weight: 500;
}
.description-label {
  @include fontsize(15);
  font-weight: 500;
  @include fluid-prop(margin-bottom, 10px, 20px);
}
.details-label {
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 2px);
    font-size: 150%;
    top: 7px;
    transform: translateY(-50%);
    transition: 200ms ease-in-out;
    transform-origin: center;
    background-image: url("~/assets/img/arrow-right.png");
    background-size: 18px;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    opacity: 0.5;

    .open & {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
.material-info {
  @include grid-item(5, 1);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: $line-color;
    right: -$grid-gap;
    top: 0;
    @include media($mobile) {
      display: none;
    }
  }

  @include media($mobile) {
    @include grid-item(6, 1);
  }
}
.details {
  @include grid-item(7, 6);
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  ::v-deep {
    p {
      margin-bottom: 10px;
    }
  }
   @include media($mobile) {
    @include grid-item(6, 1);
    padding-left: 0;
  }
}
.description {
  max-width: 600px;
  @include fluid-prop(margin-bottom, 20px, 40px);
}
.label {
  @include fluid-prop(margin-top, 20px, 40px);
  @include fluid-prop(margin-bottom, 10px, 15px);
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid $line-color;

  &.first {
    @include fluid-prop(margin-top, 0px, 0px);
     @include media($mobile) {
      @include fluid-prop(margin-top, 20px, 40px);
    }
  }
  span {
    cursor: pointer;
    &:hover {
      color: $black;
    }
  }
}
.row {
  transition: top 500ms, opacity 0.4s;
  position: sticky;
  top: 90px;

  > *:first-child {
    margin-top: 0;
  }

  .header-hidden & {
    top: 20px;
  }
  .safari & {
    top: 0 !important;
    position: relative;
  }
  @include media($mobile) {
    overflow: hidden;
  }
}

.certifications {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.certification {
  flex: 0 0 10%;
  margin-right: 25px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter-to,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  position: absolute;
  width: 100%;
}

.fade-text-enter-active,
.fade-text-leave-active {
  transition: all 0.1s;
}
.fade-text-enter,
.fade-text-leave-to {
  opacity: 0;
}
</style>
