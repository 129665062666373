// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/arrow-right.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accordion-item[data-v-5fc6d616]{border-bottom:1px solid rgba(40,40,40,.2)}.headline[data-v-5fc6d616]{cursor:pointer;padding:10px 0;position:relative}.headline[data-v-5fc6d616]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:18px;content:\"\";font-size:150%;height:18px;opacity:.5;position:absolute;right:0;top:calc(50% - 2px);transform:translateY(-50%);transform-origin:center;transition:.2s ease-in-out;width:18px}.open .headline[data-v-5fc6d616]:after{transform:translateY(-50%) rotate(90deg)}.text[data-v-5fc6d616]{padding:10px 0}.fade-enter-active[data-v-5fc6d616],.fade-leave-active[data-v-5fc6d616]{transition:opacity .2s}.fade-enter[data-v-5fc6d616],.fade-leave-to[data-v-5fc6d616]{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
