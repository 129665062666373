<template>
  <fragment>
    <Base :style="[styles]">
    <div :class="[data.subnavigation.horizontal ? 'line' : 'list', data.subnavigation.size]" class="subnavigation">
      <div class="wrapper">
        <ul class="items" :style="[layout, {textAlign: data.subnavigation.align || 'center'}]">
          <li v-for="section in sections" :key="dasherize(section.section.text)">
            <a
              v-scroll-to="'#'+dasherize(section.section.text)"
              href="#"
            >{{ section.section.text }}</a>
          </li>
        </ul>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import utils from '@/mixins/utils'

export default {
  name: 'Subnavigation',

  components: {
    Base
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      sections: this.$parent.sections
    }
  },

  computed: {
    layout() {
      if (this.data.subnavigation.custom_layout) {
        return this.grid(this.data.subnavigation.columns)
      } else if (this.$store.state.moduleOptions.layout_subnavigation) {
        return this.grid(
          this.$store.state.moduleOptions.layout_subnavigation.columns
        )
      } else {
        return this.grid('3,10')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container-responsive;
}
.items {
  @include grid-item(12, 1);

  @include media($medium) {
    @include grid-item(9, 1);
  }
  @include media($mobile) {
    @include grid-item(6, 1);
  }
}
.subnavigation {
  &.medium {
    ::v-deep {
      a {
        @include fontsize(20);
      }
    }
  }
  &.line {
    @include fluid-prop(padding, 20px, 50px);
    ul {
      text-align: center;
    }
    li {
      display: inline-block;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      @include media($mobile) {
        margin-bottom: 10px;
        margin-right: 0;
        display: block;
      }
    }
  }
  &.list {
    @include fluid-prop(padding-top, 20px, 50px);
    @include fluid-prop(padding-bottom, 20px, 50px);
    ul {
      text-align: center;
      padding: 0;
    }
  }
}
</style>
