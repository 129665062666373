<template>
  <fragment>
    <Base v-if="!videoInText" :style="[styles]">
    <div
      :class="{ wrapper: data.video.size !== 'full_bleed' && !videoInText }"
      class="video-wrapper"
    >
      <Vid :layout="layout" :data="data.video"> </Vid>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import Vid from '@/components/Vid'
import utils from '@/mixins/utils'
export default {
  name: 'Video',

  components: {
    Base,
    Vid
  },
  extends: Base,

  mixins: [utils],

  props: {
    next: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    layout() {
      if (this.data.video.custom_layout) {
        return this.grid(this.data.video.columns)
      } else if (
        this.$store.state.moduleOptions.layout_video &&
        this.$store.state.moduleOptions.layout_headline.custom_layout
      ) {
        return this.grid(this.$store.state.moduleOptions.layout_video.columns)
      } else {
        return this.grid('3,10')
      }
    },
    videoInText() {
      if (this.next && this.data.video.wrap) {
        if (this.next.acf_fc_layout === 'Wysiwyg') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container-responsive;
}
.video-wrapper {
  overflow: hidden;
}
[data-size='small'] {
  @include grid-item(6, 4);

  @include media($medium) {
    @include grid-item(12);
  }
}
[data-size='medium'] {
  @include grid-item(8, 3);

  @include media($medium) {
    @include grid-item(12);
  }
}
[data-size='large'] {
  @include grid-item(12);
}

.video-player {
  width: 100%;
  height: auto;

  button {
    outline: none !important;
  }
}
</style>
