<template>
  <div class="button-wrapper">
    <a class="button" :class="{generating: generating}" @click="genPDF">
      <span class="pdf-icon"></span>
      <span class="spinner">
        <svg 
        width="38"
        height="38"
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" stroke="#000" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".1" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"/>
            </path>
          </g>
        </g>
      </svg>
    </span>Download selection as PDF</a>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf'
import BrandonRegular from '@/assets/fonts/brandon-regular.ttf'
import BrandonMedium from '@/assets/fonts/brandon-medium.ttf'
import BrandonBold from '@/assets/fonts/brandon-bold.ttf'
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  props: {
    image: {
      type: String,
      default: ''
    },
    upholstery: {
      type: Object,
      default: () => {
        return null
      }
    },
    upholsterySecondary: {
      type: Object,
      default: () => {
        return null
      }
    },
    baseMaterial: {
      type: Object,
      default: () => {
        return null
      }
    },
    shellMaterial: {
      type: Object,
      default: () => {
        return null
      }
    },
    fittingsMaterial: {
      type: Object,
      default: () => {
        return null
      }
    },
    variant: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    data() {
      return this.$parent.$parent.parent
    }
  },
  data() {
    return {
      generating: false
    }
  },
  methods: {
    genPDF() {
      this.generating = true
      const self = this

      setTimeout(function() {
        self.startGenerate()
      }, 500)
    },
    startGenerate() {
      const self = this
      /* eslint-disable */
      var margins = {
        top: 15,
        bottom: 20,
        left: 15,
        width: 155,
      };

      var contentStart = 160

      var pos = margins.top;

      function addSpace(amount) {
        pos += amount;
      }

      function addText(str, size, push, width) {
        var parser = new DOMParser();
        var dom = parser.parseFromString(
          "<!doctype html><body>" + str,
          "text/html"
        );
        var str = dom.body.textContent;
        doc.setFontSize(size);
        var wrapped = doc.splitTextToSize(str, width || margins.width);
        doc.lineHeightProportion = 2;
        if (!push) {
          doc.text(margins.left, pos, wrapped);
        } else {
          doc.text(push, pos, wrapped);
        }

        if (size <= 15) {
          addSpace(5.5 * wrapped.length);
        }
        if (size > 15) {
          addSpace(10 * wrapped.length);
        }
      
      }

      function addMaterial(material, title, small, offsetX, offsetY) {
        doc.line(margins.left + offsetX, offsetY, margins.left + (small ? 50 : 115) + offsetX, offsetY);

        addSpace(6)

        doc.setTextColor(128,128,128);
        doc.setFont('brandon', 'medium')
        addText(
          title,
          12,
          margins.left + offsetX
        )
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = self.getCDN(material.image[0])
        console.log(img.src)
        doc.addImage(img, "PNG", margins.left + offsetX, offsetY + 12, 16, 16)
        addSpace(3)
        doc.setFont('brandon', material.title ? 'bold' : 'normal')
        doc.setTextColor(0,0,0);
        addText(
          material.title || material.name,
          10,
          margins.left + offsetX + 20,
          small ? 30 : 90
        )
        if(material.description) {
          addSpace(2)
          doc.setFont('brandon', 'normal')
          addText(
            material.description,
            10,
            margins.left + offsetX + 20,
            small ? 30 : 90
          )
        }
        addSpace(15)
      }

      var doc = new jsPDF({ lineHeight: 1.4 });
      doc.addFileToVFS('brandon-regular.ttf', BrandonRegular);
      doc.addFont(BrandonRegular, 'brandon', 'normal');
      doc.addFileToVFS('brandon-medium.ttf', BrandonMedium);
      doc.addFont(BrandonMedium, 'brandon', 'medium');
      doc.addFileToVFS('brandon-bold.ttf', BrandonBold);
      doc.addFont(BrandonBold, 'brandon', 'bold');
      doc.setDrawColor(128,128,128);

      /*
      *
      * START OF PDF CONTENT
      *
      */
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = this.image.replace('http', 'https').replace(/frames\/(.*)\//, 'frames/30/');
      doc.addImage(img, "PNG", 45, 30, 120, 120)

      var logo = new Image();
      logo.crossOrigin = "Anonymous";
      logo.src = require('@/assets/img/logo.png') + '?r=' + Math.floor(Math.random()*100000);
      doc.addImage(logo, "PNG", 83, 265, 47, 7)

      doc.setFont('brandon', 'medium')
      addText(
        'CONFIGURATOR',
        10,
        90
      )
      addSpace(10)

      doc.text((doc.getStringUnitWidth(this.data.title) * 9) + margins.left, pos - 4, this.data.sub_script);

      addText(
        this.data.title,
        25,
      )
      addText(
        this.data.byline[0].post_title,
        25
      )
      addText(
        this.data.year,
        25
      )

      pos = contentStart
      doc.line(margins.left, pos, margins.left + 50, pos);

      addSpace(6)

      doc.setTextColor(128,128,128);
      addText(
        'Product',
        12
      )
      addSpace(2)
      doc.setFont('brandon', 'normal')
      doc.setTextColor(0,0,0);
      addText(
        this.variant.title,
        10,
        null,
        50
      )

      pos = contentStart
      if(this.upholstery) {
        if(this.variant.secondary_upholstery) {
          addMaterial(this.upholstery, this.variant.upholstery_label.length > 1 ? this.variant.upholstery_label : 'Upholstery', true, 65, pos)
          pos = contentStart
          addMaterial(this.upholsterySecondary, this.variant.secondary_upholstery_label.length > 1 ? this.variant.secondary_upholstery_label : 'Secondary Upholstery', true, 130, pos)
        } else {
          addMaterial(this.upholstery, this.variant.upholstery_label.length > 1 ? this.variant.upholstery_label : 'Upholstery', false, 65, pos)
        }
      } else {
        pos += 40
      }

      contentStart = pos
      var materialPosition = 1
      if(this.baseMaterial && this.shellMaterial && this.fittingsMaterial) {
        materialPosition = 0
      }

      if(this.baseMaterial) {
        addMaterial(this.baseMaterial, this.variant.base_label.length > 1 ? this.variant.base_label : 'Base', this.shellMaterial || this.fittingsMaterial ? true : false, materialPosition * 65, pos)
        pos = contentStart
        materialPosition++
      }
      if(this.shellMaterial) {
        addMaterial(this.shellMaterial, this.variant.shell_label.length > 1 ? this.variant.shell_label : 'Shell', this.baseMaterial || this.fittingsMaterial ? true : false, materialPosition * 65, pos)
        pos = contentStart
        materialPosition++
      }
      if(this.fittingsMaterial) {
        addMaterial(this.fittingsMaterial, 'Fittings', this.baseMaterial || this.shellMaterial ? true : false, materialPosition * 65, pos)
      }

 
      pos = 280
      doc.setFont('brandon', 'normal')
      addText(
        'Kronprinsessegade 4, DK–1306 Copenhagen K',
        10,
        72,
      )
      addText(
        '+45 3920 0233 | info@andtradition.com',
        10,
        76,
      )

      /*
      *
      * END OF PDF CONTENT
      *
      */

      // Save PDF to file
      doc.save(this.data.title + '-' + this.data.sub_script + '.pdf', {returnPromise:true}).then(() => {
        this.generating = false
      });
      /* eslint-enable */
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  margin-top: 20px;
  position: relative;
  padding-left: 35px;
  background-color: #fff;
  color: $black;
}
.pdf-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  top: 12px;
  background-image: url(#{$icons-path}download.svg);
  background-size: 16px;

  .generating & {
    opacity: 0;
  }
}
.spinner {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 18px;
  height: 18px;
  opacity: 0;

  svg {
    width: 100%;
    height: 100%;
  }

  .generating & {
    opacity: 1;
  }
}
</style>
