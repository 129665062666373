<template>
  <div class="Contacts">
    <div ref="filters" class="filters">
      <v-select
        v-if="locations"
        v-model="country"
        class="country"
        :options="sortArray(Object.values(locations))"
        placeholder="Select Country"
        index="term_id"
        label="name"
        :clearable="false"
        @input="changeCountry"
      />
    </div>
    <ul class="labels">
      <li v-show="contacts.length > 0" class="store labels">
        <div class="title">Country</div>
        <div class="name">Name</div>
        <div class="phone">Phone Number</div>
        <div class="website">Email</div>
      </li>
    </ul>
    <transition name="fade" mode="out-in">
      <ul :key="country ? country.term_id : 0" class="stores">
        <li v-for="contact in contacts" :key="contact.slug" class="store">
          <div class="title">{{ contact.country }}</div>
          <div class="name">{{ contact.post_title }}</div>
          <div class="phone">{{ contact.phone }}</div>
          <div class="website"><a v-if="contact.email" :href="'mailto:'+contact.email">Email</a></div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'
import ajax from '@/mixins/ajax'
export default {
  name: 'Contacts',
  mixins: [ajax, smoothReflow],
  data() {
    return {
      locations: [],
      contacts: [],
      country: null
    }
  },

  watch: {
    $route(to, from) {
      this.checkQuery()
    }
  },

  created: async function() {
    await this.getLocations().then(response => {
      this.locations = response
      this.checkQuery()
    })
  },

  mounted() {
    this.$smoothReflow({
      el: this.$refs.filters,
      transition: 'height .5s ease-in-out',
      transitionEvent: {
        selector: '.stores',
        propertyName: 'opacity'
      }
    })
  },

  methods: {
    sortArray(arr) {
      arr.sort(function(a, b) {
        const textA = a.name.toUpperCase()
        const textB = b.name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      return arr
    },
    getLocations: async function() {
      const response = await this.get('/trouble/countries')
      return Object.values(response.data)
    },
    changeCountry(val) {
      if (val) {
        const query = Object.assign({}, this.$route.query)
        this.$router.push({
          path: this.$route.path,
          query: Object.assign({}, query, {
            country: val.slug
          })
        })
      } else {
        const query = Object.assign({}, this.$route.query)
        if (query.country) {
          delete query.country
          this.country = null
        }
        this.$router.push({
          path: this.$route.path,
          query: query
        })
      }
    },
    checkQuery() {
      if (this.$route.query.type) {
        const result = this.types.find(
          type => type.toUpperCase() === this.$route.query.type.toUpperCase()
        )
        if (result) {
          this.type = result
        }
      }
      if (this.$route.query.country) {
        const result = this.locations.find(
          location => location.slug === this.$route.query.country
        )
        if (result) {
          this.country = result
        }
      }
      if (this.$route.query.city) {
        const result = Object.values(this.country.children).find(
          location => location.slug === this.$route.query.city
        )
        if (result) {
          this.city = result
        }
      }
      this.getContacts()
    },
    getContacts: async function() {
      const params = {}

      if (this.country) {
        params.country = this.country.term_id
      } else {
        this.contacts = []
        return
      }
      if (this.$nuxt && this.$nuxt.$loading) {
        this.$nuxt.$loading.start()
        const response = await this.get('/trouble/contacts', params).then(
          this.$nuxt.$loading.finish()
        )
        this.contacts = response.data
      }
    },
    distance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 === lat2 && lon1 === lon2) {
        return 0
      } else {
        const radlat1 = (Math.PI * lat1) / 180
        const radlat2 = (Math.PI * lat2) / 180
        const theta = lon1 - lon2
        const radtheta = (Math.PI * theta) / 180
        let dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = (dist * 180) / Math.PI
        dist = dist * 60 * 1.1515
        if (unit === 'K') {
          dist = dist * 1.609344
        }
        if (unit === 'N') {
          dist = dist * 0.8684
        }
        return dist.toFixed(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.filters {
  @include grid-container-responsive;

  .country {
    @include grid-item(4, 5);
    @include media($medium) {
      @include grid-item(4, 5);
    }
    @include media($mobile) {
      @include grid-item(6, 1);
      margin-bottom: 10px;
    }
  }

  // select {
  //   height: 60px;
  //   @include fontsize(15);
  //   padding: 20px;
  // }
}
.v-select {
  background-color: $white;

  &.vs--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.vs--open {
    ::v-deep {
      .vs__open-indicator {
        transform: rotate(180deg) scale(0.7);
      }
    }
  }

  ::v-deep {
    .vs__open-indicator {
      transform: scale(0.7);
    }
    .vs__clear {
      transform: scale(0.8);
    }
    .vs__selected,
    .vs__dropdown-toggle {
      height: 40px;
      margin-top: 0;
      margin-bottom: 0;
    }
    ::-webkit-input-placeholder {
      opacity: 0.3;
    }

    :-ms-input-placeholder {
      opacity: 0.3;
    }

    ::placeholder {
      opacity: 0.3;
    }
    li {
      padding-left: 8px;
    }
  }
}

ul {
  @include grid-container-responsive;

  &.labels {
    @include fluid-prop(padding-top, 20px, 50px);
  }

  li {
    @include grid-item(12);
    padding: 30px 0 27px 0;
    border-bottom: 1px solid $line-color;
    @include grid-container-responsive(false);
    @include fontsize(15);

    @include media($mobile) {
      padding: 10px 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .title {
      @include grid-item(4, 1);
      @include media($medium) {
        @include grid-item(3, 1);
      }
      @include media($mobile) {
        @include grid-item(6, 1);
      }
    }
    .name {
      @include grid-item(4, 5);
      @include media($medium) {
        @include grid-item(4, 4);
      }
      @include media($mobile) {
        @include grid-item(6, 1);
      }
    }
    .phone {
      @include grid-item(2, 9);
      @include media($medium) {
        @include grid-item(3, 8);
      }
      @include media($mobile) {
        @include grid-item(6, 1);
      }
    }
    .website {
      @include grid-item(2, 11);
      @include media($medium) {
        @include grid-item(2, 11);
      }
      @include media($mobile) {
        @include grid-item(6, 1);
      }
    }

    &:not(.labels) {
      .title {
        @include fontsize(20);
        font-weight: 500;
        margin-top: -5px;
      }
      .website {
        text-decoration: underline;
        opacity: 0.2;
        transition: opacity 100ms;

        &:hover {
          opacity: 1;
        }
      }
    }

    @include media($mobile) {
      @include grid-item(6);
    }

    &.labels {
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      border-bottom: 1px solid $black;
      padding-bottom: 15px;
      letter-spacing: 0.91px;

      @include media($mobile) {
        display: none;
      }
    }
  }
}
</style>
