<template>
  <div class="module" :class="$parent.data && $parent.data.contextual ? 'contextual' : ''">
    <slot></slot>
  </div>
</template>

<script>
import bus from '@/components/bus'

export default {
  name: 'Base',

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    prev: {
      type: Object,
      default: () => {
        return null
      }
    },
    next: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      settings: {},
      backgroundColor: '',
      textColor: '',
      fontFamily: '',
      fontWeight: '',
      inViewPort: false
    }
  },

  computed: {
    baseFontStyle() {
      if (this.settings.font) {
        return {
          fontFamily: this.settings.font
        }
      } else if (this.$store.state.styles.baseFontFamily) {
        return {
          fontFamily: this.$store.state.styles.baseFontFamily
        }
      } else {
        return {
          fontFamily: this.fontFamily
        }
      }
    },

    headlineStyle() {
      return this.$store.getters.headlineStyle
    },

    labelStyle() {
      return this.$store.getters.labelStyle
    },

    styles: function() {
      return {
        'background-color': this.backgroundColor,
        color: this.textColor,
        'font-family': this.fontFamily,
        'font-weight': this.fontWeight
      }
    }
  },

  created() {
    this.settings = this.data
    if (this.settings) {
      const key = this.settings[Object.keys(this.settings)[0]]
      this.settings = this.settings[key.toLowerCase()]

      if (
        this.settings &&
        this.settings.background_color &&
        this.data.acf_fc_layout !== 'Newsletter'
      ) {
        this.backgroundColor = this.settings.background_color
      } else {
        this.backgroundColor = this.$store.state.styles.backgroundColor
      }

      if (this.settings && this.settings.text_color) {
        this.textColor = this.settings.text_color
      } else {
        this.textColor = this.$store.state.styles.textColor
      }

      if (this.settings && this.settings.font) {
        this.fontFamily = this.settings.font
      }
    }
  },

  mounted() {
    this.$nextTick(function() {
      bus.$emit('moduleAdded')
    })
    if (this.settings) {
      if (this.data.acf_fc_layout === 'Newsletter') {
        if (this.settings.size === 'large') {
          this.$el.classList.add('fullwidth')
        }
      }

      if (this.data.acf_fc_layout === 'Cover') {
        this.$el.classList.add('fullwidth')
      }

      if (this.settings.full_bleed) {
        this.$el.classList.add('fullwidth')
      }

      if (this.settings.size) {
        if (
          this.settings.size === 'full' ||
          this.settings.size === 'full_bleed'
        ) {
          this.$el.classList.add('fullwidth')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.module:not(.modifier):not(.space):not(.fullwidth) {
  // @include fluid-prop(padding-bottom, 30px, 60px);
  padding-bottom: var(--module-spacing);

  &.contextual {
    @include fluid-prop(padding-bottom, 30px, 80px);
  }
}
.module.color-change + div:not(.fullwidth) {
  // @include fluid-prop(padding-top, 30px, 60px);
  padding-top: var(--module-spacing);

  &.contextual {
    @include fluid-prop(padding-top, 30px, 80px);
  }
}
.module.fullwidth + div:not(.modifier):not(.fullwidth):not(.space) {
  // @include fluid-prop(padding-top, 30px, 60px);
  padding-top: var(--module-spacing);

  &.contextual {
    @include fluid-prop(padding-top, 30px, 80px);
  }
}
.module {
  position: relative;
}
</style>
