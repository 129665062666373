<template>
  <component :is="'a'" v-bind="linkProps(to, target)">
    <slot />
  </component>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  mixins: [utils],

  props: {
    to: {
      type: [Boolean, String],
      default: ''
    },
    target: {
      type: String,
      default: ''
    }
  },
  methods: {
    linkProps(url, target) {
      if (target === '_blank') {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {
        is: 'router-link',
        to: this.getUrl(url)
      }
    }
  }
}
</script>
