<template>
  <fragment>
    <Base
      v-if="!textIsNext"
      :style="baseBackground"
    >
    <div ref="motion">
      <div
        v-waypoint="{ active: data.motion.effect === 'view', callback: onWaypoint}"
        class="motion"
        :class="{'wrapper' : !motionInText}"
      >
        <Vid
          v-if="data.motion.file"
          :data="videoData"
          :layout="layout"
          :style="styles"
          @ready="handleVideo"
          @mouseenter.native="mouseEnter"
          @mouseleave.native="mouseLeave"
        />
        <lottie
          v-if="!data.motion.file"
          :style="[layout, styles]"
          :options="defaultOptions"
          class="animation"
          @animCreated="handleAnimation"
          @mouseenter.native="mouseEnter"
          @mouseleave.native="mouseLeave"
        />
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>

import Base from './Base'
import Vid from '@/components/Vid'
import utils from '@/mixins/utils'
let ScrollMagic
if (typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic')
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap')
}

export default {
  name: 'Motion',

  components: {
    Base,
    Vid
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data() {
    return {
      defaultOptions: !this.data.motion.file
        ? {
            animationData: JSON.parse(this.data.motion.lottie_input),
            loop: this.data.motion.loop
          }
        : null,
      triggered: false,
      baseBackground: {
        'background-color': this.$store.state.styles.backgroundColor
      }
    }
  },

  computed: {
    layout() {
      if (this.data.motion.layout.custom_layout) {
        if (this.data.motion.effect === 'stay') {
          return this.grid(this.data.motion.layout_stick.columns)
        } else {
          return this.grid(this.data.motion.layout.columns)
        }
      } else if (
        this.$store.state.moduleOptions.layout_motion &&
        this.$store.state.moduleOptions.layout_motion.custom_layout
      ) {
        return this.grid(this.$store.state.moduleOptions.layout_motion.columns)
      } else {
        return this.grid('3,10')
      }
    },
    videoData() {
      const data = this.data.motion
      data.autoplay = false
      data.controls = false
      return data
    },
    textIsNext() {
      if (this.next && this.data.motion.layout.wrap) {
        if (this.next.acf_fc_layout === 'Wysiwyg') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    motionInText() {
      if (!this.next && this.data.motion.layout.wrap) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onWaypoint({ going, direction }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (going === this.$waypointMap.GOING_IN && !this.triggered) {
        if (this.anim) {
          this.anim.play()
        }
        if (this.player) {
          this.player.play()
        }
      }
    },
    scrollPlay() {
      if (this.anim) {
        const seek = (this.anim.totalFrames / 100) * this.scrollpos
        this.anim.goToAndStop(seek, true)
      }
      if (this.player) {
        let seek = this.player.duration * (this.scrollpos / 100)
        if (seek > this.player.duration) {
          seek = this.player.duration
        }
        this.player.currentTime = parseFloat(seek.toFixed(2))
      }
    },
    handleAnimation: function(anim) {
      this.anim = anim
      this.anim.setDirection(this.data.motion.direction)
      if (this.data.motion.effect === 'stay') {
        this.anim.pause()
        const triggerHook =
          (window.innerHeight - this.$refs.motion.clientHeight) /
          window.innerHeight
        const controller = new ScrollMagic.Controller()
        new ScrollMagic.Scene({
          triggerElement: this.$refs.motion,
          triggerHook: triggerHook * 0.5,
          duration: (this.anim.totalFrames * 20) / this.data.motion.scroll_speed
        })
          .setPin(this.$refs.motion)
          .on('progress', e => {
            this.scrollpos = e.progress * 100
            this.scrollPlay()
          })
          .addTo(controller)
      }
      if (this.data.motion.effect === 'play') {
        this.anim.pause()
        const controller = new ScrollMagic.Controller()
        new ScrollMagic.Scene({
          triggerElement: this.$refs.motion,
          triggerHook: 1,
          duration: window.innerHeight + this.$refs.motion.offsetHeight
        })
          .on('progress', e => {
            this.scrollpos = e.progress * 100
            this.scrollPlay()
          })
          .addTo(controller)
      }
      if (this.data.motion.effect === 'view') {
        this.anim.pause()
      }
      if (this.data.motion.effect === 'hover') {
        this.anim.pause()
      }
    },
    mouseEnter() {
      if (this.data.motion.effect === 'hover') {
        if (this.data.motion.file) {
          this.player.play()
        } else {
          this.anim.play()
        }
      }
    },
    mouseLeave() {
      if (this.data.motion.effect === 'hover') {
        if (this.data.motion.file) {
          this.player.pause()
        } else {
          this.anim.pause()
        }
      }
    },
    handleVideo(player) {
      this.player = player
      if (this.data.motion.effect === 'stay') {
        this.player.pause()
        const triggerHook =
          (window.innerHeight - this.$refs.motion.clientHeight) /
          window.innerHeight
        const controller = new ScrollMagic.Controller()
        new ScrollMagic.Scene({
          triggerElement: this.$refs.motion,
          triggerHook: triggerHook * 0.5,
          duration: 2000
        })
          .setPin(this.$refs.motion)
          .on('progress', e => {
            this.scrollpos = e.progress * 100
            this.scrollPlay()
          })
          .addTo(controller)
      }
      if (this.data.motion.effect === 'play') {
        this.player.pause()
        const controller = new ScrollMagic.Controller()
        new ScrollMagic.Scene({
          triggerElement: this.$refs.motion,
          triggerHook: 1,
          duration: window.innerHeight
        })
          .on('progress', e => {
            this.scrollpos = e.progress * 100
            this.scrollPlay()
          })
          .addTo(controller)
      }
      if (this.data.motion.effect === 'view') {
        this.player.pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @include grid-container-responsive;
}
.motion {
  .animation {
    width: 100%;
    height: auto !important;
  }
}
</style>
