<template>
  <fragment>
    <Base :style="[styles, baseBackground]">
    <div :class="{ wrapper: !fullWidth }">
      <div
        :ref="'promo'"
        v-vpshow
        v-bind="promoProps()"
        :style="[promoBackground, backgroundImage]"
        :class="[
          { background: data.promo_v2.background_color.length > 0 },
          { 'text-right': !textLeft },
          { 'full-width': fullWidth },
          { 'no-gutter': removeGutter },
          overlap
        ]"
        class="promo"
      >
        <div
          :class="[padding, data.promo_v2.text_align]"
          class="content"
          :style="textLayout"
          @click="handleClicks($event, '.promo .content')"
        >
          <div :style="[bodyStyle]" v-html="data.promo_v2.wysiwyg" />
          <template
            v-if="data.promo_v2.cta === 'button' && data.promo_v2.button_label"
          >
            <div :style="{ textAlign: data.promo_v2.btn_align }">
              <NuxtLink
                :to="getUrl(data.promo_v2.url.url)"
                :target="data.promo_v2.url.target"
                :style="buttonStyles"
                :class="[data.promo_v2.btn_style, data.promo_v2.btn_align]"
              >
                {{ data.promo_v2.button_label
                }}
              </NuxtLink>
            </div>
          </template>

          <template
            v-if="data.promo_v2.cta === 'input' && data.promo_v2.button_label"
          >
            <!-- Begin MailChimp Signup Form -->
            <div class="mc_embed_signup">
              <form
                id="mc-embedded-subscribe-form"
                :action="action"
                method="post"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_blank"
                novalidate
              >
                <div id="mc_embed_signup_scroll">
                  <div class="mc-field-group">
                    <input
                      id="mce-EMAIL"
                      type="email"
                      placeholder="Your email"
                      value=""
                      name="EMAIL"
                      class="font-body required email"
                    />
                    <div class="submit-button">
                      <input
                        id="mc-embedded-subscribe"
                        :value="buttonLabel"
                        type="submit"
                        name="subscribe"
                        class="font-body button"
                      /><span :style="iconStyle" class="icon" />
                    </div>
                  </div>

                  <div id="mce-responses" class="clear">
                    <div
                      id="mce-error-response"
                      class="response"
                      style="display:none"
                    />
                    <div
                      id="mce-success-response"
                      class="response"
                      style="display:none"
                    />
                  </div>
                  <div
                    style="position: absolute; left: -5000px;"
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_e76d60452f94212c14b0b1def_6a2b49ad81"
                      tabindex="-1"
                      value=""
                    />
                  </div>
                </div>
              </form>
              <div class="disclaimer" v-html="disclaimer" />
            </div>
            <!--End mc_embed_signup-->
          </template>

          <template v-if="(data.promo_v2.cta === 'email' && data.promo_v2.button_label)">
            <div>
              <a
                :style="buttonStyles"
                :href="'mailto:'+data.promo_v2.email"
                :class="[data.promo_v2.btn_style, data.promo_v2.btn_align]"
              >{{ data.promo_v2.button_label }}<span
                :style="iconStyle"
                class="icon"
              /></a>
            </div>
          </template>
        </div>
        <div
          :class="data.promo_v2.media_align"
          class="media"
          :style="[mediaLayout, height]"
        >
          <div
            v-if="data.promo_v2.select_media === 'img'"
            v-vpshow="{ animation: 'fadein' }"
            v-lazy:background-image="getCDN(backgroundImage)"
            :style="{paddingBottom: aspectRatio * 100 + '%'}"
            class="image"
          />
          <div v-if="data.promo_v2.select_media === 'vid'" class="promo-video" :style="height">
            <video :ref="'video'" autoplay muted loop playsinline :style="height">
              <source :src="data.promo_v2.file.url" :type="data.promo_v2.file.mime_type" />
            </video>
          </div>
        </div>
      </div>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import AppLink from '@/components/AppLink'
import utils from '@/mixins/utils'

export default {
  name: 'Promo',

  components: {
    Base,
    AppLink
  },
  extends: Base,

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      baseBackground: {
        'background-color': this.$store.state.styles.backgroundColor
      },
      textLeft:
        parseInt(this.data.promo_v2.text_layout ? this.data.promo_v2.text_layout.split(',')[0] : 1) <
        parseInt(this.data.promo_v2.media_layout ? this.data.promo_v2.media_layout.split(',')[0] : 0)
    }
  },

  computed: {
    removeGutter() {
      if (
        this.data.promo_v2.media_layout === '0,6' ||
        this.data.promo_v2.media_layout === '7,13'
      ) {
        return true
      } else {
        return false
      }
    },
    height() {
      if (this.data.promo_v2.height === 'auto') {
        return { minHeight: 'none' }
      } else if (this.data.promo_v2.height === 'browser') {
        const iOS =
          !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        if (iOS) {
          return { minHeight: '500px' }
        } else {
          return { height: '100vh' }
        }
      } else {
        return { height: this.data.promo_v2.custom }
      }
    },
    padding() {
      const classes = []
      if (this.data.promo_v2.text_padding) {
        if (this.data.promo_v2.text_align === 'top') {
          classes.push('padding-top')
        }
        if (this.data.promo_v2.text_align === 'bottom') {
          classes.push('padding-bottom')
        }
        if (this.data.promo_v2.text_layout && parseInt(this.data.promo_v2.text_layout.split(',')[0]) === 0) {
          classes.push('padding-left')
        }
        if (this.data.promo_v2.text_layout && parseInt(this.data.promo_v2.text_layout.split(',')[1]) === 13) {
          classes.push('padding-right')
        }
        if (
          !this.fullWidth &&
          this.data.promo_v2.text_layout &&
          parseInt(this.data.promo_v2.text_layout.split(',')[0]) === 1
        ) {
          classes.push('padding-left')
        }
        if (
          !this.fullWidth &&
          this.data.promo_v2.text_layout &&
          parseInt(this.data.promo_v2.text_layout.split(',')[1]) === 12
        ) {
          classes.push('padding-right')
        }
      }
      return classes
    },
    buttonStyles() {
      return {
        '--button-color': this.data.promo_v2.btn_text_color || '#000000',
        '--button-background-color': this.data.promo_v2.btn_bg_color || 'transparent',
        '--border-color': this.data.promo_v2.btn_text_color || '#000000',
      }
    },
    headlineFontWeight() {
      return {
        fontWeight: this.fontWeight
      }
    },
    iconStyle: function() {
      return {
        'background-color': this.styles.color
      }
    },
    bodyStyle() {
      return this.$store.getters.bodyStyle
    },
    headlineStyle() {
      return this.$store.getters.headlineStyle
    },
    promoBackground: function() {
      return {
        'background-color': this.data.promo_v2.background_color
      }
    },
    backgroundImage: function() {
      if (this.data.promo_v2.image) {
        return this.getCDN(this.data.promo_v2.image.sizes.fullwidth)
      } else {
        return false
      }
    },
    aspectRatio: function() {
      if (this.data.promo_v2.image) {
        return (
          this.data.promo_v2.image.sizes['fullwidth-height'] /
          this.data.promo_v2.image.sizes['fullwidth-width']
        )
      } else {
        return false
      }
    },
    buttonLabel: function() {
      if (this.data.promo_v2.button_label) {
        return this.data.promo_v2.button_label
      } else {
        return this.$store.state.siteOptions.promo
      }
    },
    disclaimer: function() {
      return this.$store.state.siteOptions.newsletter_disclaimer
    },
    action: function() {
      return this.$store.state.siteOptions.mailchimp_action
    },
    mediaLayout() {
      if (this.data.promo_v2.custom_layout) {
        return this.grid(
          this.data.promo_v2.media_layout,
          this.data.promo_v2.media_layout,
          this.overlap && this.overlap === 'overlap text-left'
            ? '3,6'
            : this.overlap && this.overlap === 'overlap text-right'
              ? '1,4'
              : this.data.promo_v2.media_layout === '0,13' 
                ? '0,7'
                : '1,6'
        )
      } else {
        return this.grid('1,6')
      }
    },
    textLayout() {
      if (this.data.promo_v2.custom_layout) {
        return this.grid(
          this.data.promo_v2.text_layout,
          this.data.promo_v2.text_layout,
          this.overlap && this.overlap === 'overlap text-left'
            ? '1,4'
            : this.overlap && this.overlap === 'overlap text-right'
              ? '3,6'
              : '1,6'
        )
      } else {
        return this.grid('7,12', '7,12', '1,6')
      }
    },
    fullWidth() {
      if (this.data.promo_v2.custom_layout) {
        const text = this.data.promo_v2.text_layout.split(',')
        const media = this.data.promo_v2.media_layout.split(',')
        if (
          parseInt(text[0]) === 0 ||
          parseInt(text[1]) === 13 ||
          parseInt(media[0]) === 0 ||
          parseInt(media[1]) === 13
        ) {
          return true
        }
      }
      return false
    },
    overlap() {
      if (this.data.promo_v2.custom_layout) {
        const text = this.data.promo_v2.text_layout.split(',')
        const media = this.data.promo_v2.media_layout.split(',')
        const textLeft = parseInt(text[0]) < parseInt(media[0])
        if (
          parseInt(text[0]) >= parseInt(media[0]) &&
          parseInt(text[1]) <= parseInt(media[1])
        ) {
          return 'overlap full'
        }
        if (textLeft) {
          if (parseInt(text[1] - 3) < parseInt(media[0])) {
            return null
          } else {
            return 'overlap text-left'
          }
        } else {
          if (parseInt(text[0]) < parseInt(media[1]) - 3) {
            return 'overlap text-right'
          } else {
            return null
          }
        }
      } else {
        return null
      }
    }
  },

  mounted() {
    if (this.$refs.promo && this.$refs.promo.$el) {
      this.$refs.promo.$el.style.setProperty(
        '--underline-color',
        this.data.promo_v2.btn_text_color || '#000000'
      )
    }
    if (this.$refs.promo && this.$refs.promo.style) {
      this.$refs.promo.style.setProperty(
        '--underline-color',
        this.data.promo_v2.btn_text_color || '#000000'
      )
    }
  },

  methods: {
    promoProps() {
      if (
        this.data.promo_v2.cta === 'button' &&
        this.data.promo_v2.button_label
      ) {
        if (this.data.promo_v2.url.target === '_blank') {
          return {
            is: 'a',
            href: this.data.promo_v2.url.url,
            target: '_blank',
            rel: 'noopener'
          }
        } else {
          return {
            is: 'router-link',
            to: this.getUrl(this.data.promo_v2.url.url)
          }
        }
      } else {
        return {
          is: 'div'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  @include grid-container-responsive();
}
.promo-video {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.promo {
  display: block;
  position: relative;
  --underline-color: $black;
  &:not(.full-width) {
    @include grid-container-responsive(false);
    @include grid-item(12, 1);
    @include media($mobile) {
      @include grid-item(6, 1);
    }
  }

  &.full-width {
    @include grid-container-responsive();
  }

  &.no-gutter {
    .media {
      margin-left: -15px;
      @include media($mobile) {
        margin-left: 0;
      }
    }

    &.text-right {
      .media {
        margin-left: 0;
        margin-right: -15px;
        @include media($mobile) {
          margin-right: 0;
        }
      }
    }
  }

  > * {
    grid-row-start: 1;

    @include media($mobile) {
      grid-row-start: auto;
      // grid-column: 1 col-start / span 6 !important;
    }
  }
  &.text-right {
    @include media($mobile) {
      .media {
        grid-row-start: 1;
      }
      .content {
        grid-row-start: 2;
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
  }
  &.overlap {
    > * {
      grid-row-start: 1 !important;
    }
  }
}
.content {
  z-index: 2;

  &.padding-top {
    @include fluid-prop(padding-top, 15px, 30px);
  }
  &.padding-bottom {
    @include fluid-prop(padding-bottom, 15px, 30px);
  }
  &.padding-left {
    @include fluid-prop(padding-left, 15px, 30px);
  }
  &.padding-right {
    @include fluid-prop(padding-right, 15px, 30px);
  }

  @include media($mobile) {
    padding: 0 !important;
    margin-bottom: 20px;
    .background &,
    .overlap & {
      margin-top: 0 !important;
      padding: 15px !important;
    }
  }

  ::v-deep {
    h2 {
      @include fontsize(60);
      @include fluid-prop(margin-top, 15px, 30px);
      @include fluid-prop(margin-bottom, 15px, 30px);
      break-after: avoid !important;
    }
    h3 {
      @include fontsize(30);
      @include fluid-prop(margin-top, 15px, 30px);
      @include fluid-prop(margin-bottom, 15px, 30px);
      font-weight: bold;
      break-after: avoid !important;
    }
    a {
      @include font('base');
      @include fluid-prop(margin-top, 20px, 40px);
    }

    h4 {
      @include fontsize(30);
      @include fluid-prop(margin-bottom, 15px, 30px);
    }

    h6 {
      @include font('label');
    }

    a:not(.button) {
      @include underline-link(true);
    }
  }
}
.media {
  z-index: 1;
}
.image {
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 500ms;

  &[lazy='loaded'] {
    opacity: 1;
  }
}
.button {
  border: 1px solid var(--border-color);
  &:hover {
    text-decoration: none;
  }
}
.content,
.media {
  &.top {
    align-self: flex-start;
  }
  &.center {
    align-self: center;
  }
  &.bottom {
    align-self: flex-end;
  }
}
</style>
