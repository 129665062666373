<template>
  <fragment>
    <Base v-if="!imageInText" :style="[styles]">
    <div
      v-vpshow="animation"
      :class="{'wrapper' : (data.showroom.size !== 'full' && !imageInText) }"
      class="image-wrapper has-hotspots"
    >
      <Img
        :layout="layout"
        :data="data.showroom.image"
        :hotspots="data.showroom.hotspots"
        :enable="data.showroom.zoom"
        :no-scale="data.showroom.no_scale"
        :data-size="!imageInText ? data.showroom.size : null"
        :data-position="data.showroom.position"
        :data-caption-below="data.showroom.caption_below"
      /></Img>
    </div>
    </Base>
  </fragment>
</template>

<script>
import Base from './Base'
import Img from '@/components/Img'
import utils from '@/mixins/utils'
export default {
  name: 'Showroom',
  components: {
    Img,
    Base
  },
  extends: Base,
  mixins: [utils],
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    layout() {
      if (this.data.showroom.custom_layout) {
        return this.grid(this.data.showroom.columns)
      } else if (
        this.$store.state.moduleOptions.layout_image &&
        this.$store.state.moduleOptions.layout_image.custom_layout
      ) {
        return this.grid(this.$store.state.moduleOptions.layout_image.columns)
      } else {
        return this.grid('3,10')
      }
    },
    animation() {
      if (this.data.showroom.size === 'full') {
        return {
          animation: 'fadein'
        }
      } else {
        return ''
      }
    },
    imageInText() {
      if (this.next && this.data.showroom.size === 'small') {
        if (this.next.acf_fc_layout === 'Wysiwyg') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-wrapper {
  background-color: inherit;
  position: relative;
}

.image {
  ::v-deep {
    figure {
      @include grid-container-responsive();
    }
  }
}
</style>
